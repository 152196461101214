import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BsQuestionCircle, BsSearch, BsX } from 'react-icons/bs';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { AiOutlineArrowDown } from 'react-icons/ai';

type Props = {};

const CaptacaoPopInit = ({ fecharPop }: any) => {
    // Estados para os filtros
    const [filtroModalidade, setFiltroModalidade] = useState<string[]>([]);
    const [filtroUf, setFiltroUf] = useState<string[]>([]);
    const [instrumentoConvocatorio, setInstrumentoConvocatorio] = useState<string[]>([]);
    const [filtroEsfera, setFiltroEsfera] = useState<string[]>([]);
    const [filtroMontado, setFiltroMontado] = useState<string>('');
    const [palavraChave, setPalavraChave] = useState<string>('');
    const [helpFiltro, setHelpFiltro] = useState<boolean>(false);

    enum Filtro {
        MODALIDADE = 'modalidade',
        UF = 'uf',
        INSTRUMENTO = 'instrumento',
        ESFERA = 'esfera',
        IDLE = 'idle',
    }
    const [filtroAtual, setFiltroAtual] = useState<Filtro>(Filtro.IDLE);

    // Função para nomear as modalidades
    const nomeModalidade = (data: string) => {
        switch (data) {
            case '6':
                return 'Pregão eletrônico';
            case '8':
                return 'Dispensa';
            case '9':
                return 'Dispensa de licitação';
            case '7':
                return 'Pregão presencial';
            case '12':
                return 'Credenciamento';
            case '4':
                return 'Concorrência eletrônica';
            case '5':
                return 'Concorrência presencial';
            default:
                return 'Selecione sua modalidade';
        }
    };

    const nomeEsfera = (data: string) => {
        switch (data) {
            case 'M':
                return 'Municipal';
            case 'E':
                return 'Estadual';
            case 'F':
                return 'Federal';
            case 'D':
                return 'Distrital';

            default:
                return 'Selecione as categorias';
        }
    };


    const nomeInstrumento = (data: string) => {
        switch (data) {
            case '1':
                return 'Edital';
            case '2':
                return 'Aviso de contratação direta';
            case '4':
                return 'Edital de chamamento público';
            default:
                return 'Selecione as categorias';
        }
    };

    // Funções para gerenciar a seleção dos filtros
    const modalidadeFiltro = (id: string) => {
        if (filtroModalidade.includes(id)) {
            setFiltroModalidade(filtroModalidade.filter((item) => item !== id));
        } else {
            setFiltroModalidade([...filtroModalidade, id]);
        }
    };


    const ufFiltro = (id: string) => {
        if (filtroUf.includes(id)) {
            setFiltroUf(filtroUf.filter((item) => item !== id));
        } else {
            setFiltroUf([...filtroUf, id]);
        }
    };

    const instrumentoConvFiltro = (id: string) => {
        if (instrumentoConvocatorio.includes(id)) {
            setInstrumentoConvocatorio(instrumentoConvocatorio.filter((item) => item !== id));
        } else {
            setInstrumentoConvocatorio([...instrumentoConvocatorio, id]);
        }
    };

    const handleEsferaFiltro = (id: string) => {
        if (filtroEsfera.includes(id)) {
            setFiltroEsfera(filtroEsfera.filter((item) => item !== id));
        } else {
            setFiltroEsfera([...filtroEsfera, id]);
        }
    };

    // Função de Logging para Depuração
    const alterarFiltro = (data: any) => {
        localStorage.setItem(`${empresaSelecionada}-filtro`, data)
        localStorage.removeItem('primeiroAcesso')
        fecharPop()
    };

    // Configuração da Mutação com react-query
    const { getCaptacaoAutomatica } = useReactQueryCaptacao();

    

    const empresaSelecionada = localStorage.getItem('empresaSelecionada');

    // Função para Limpar os Filtros
    const limparFiltro = (e: any) => {
        e.preventDefault();
        setFiltroModalidade([]);
        setFiltroUf([]);
        setInstrumentoConvocatorio([]);
        setFiltroEsfera([]);
        setPalavraChave('');
        setFiltroMontado('');
        localStorage.removeItem(`${empresaSelecionada}-filtro`);
    };

    // Função para Aplicar e Enviar os Filtros
    const handleFitrarProcesso = (save: boolean) => {
        if (save) {
            toast.success('Filtro salvo para busca automática!');
        }

        // Construção das strings de filtro com delimitador '%7C' no final
        let filtroUfString = '';
        if (filtroUf.length >= 1) {
            filtroUfString = filtroUf.join('%7C') + '%7C';
        }

        let filtroModalidadeString = '';
        if (filtroModalidade.length >= 1) {
            filtroModalidadeString = filtroModalidade.join('%7C') + '%7C';
        }

        let filtroInstConvString = '';
        if (instrumentoConvocatorio.length >= 1) {
            filtroInstConvString = instrumentoConvocatorio.join('%7C') + '%7C';
        }

        let filtroEsferaConvString = '';
        if (filtroEsfera.length >= 1) {
            filtroEsferaConvString = filtroEsfera.join('%7C') + '%7C';
        }

        // Construção da string final de filtros
        const filtroFinal = `${palavraChave}&ufs=${filtroUfString}&modalidades=${filtroModalidadeString}&tipos=${filtroInstConvString}&esferas=${filtroEsferaConvString}`;

        // Log para depuração
        alterarFiltro(filtroFinal);

        // Envio dos filtros para o backend via mutação
    };

    return (
        <div
            id="crud-modal"
            tabIndex={-1}
            className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full"
        >


            <div className="relative p-4 w-full max-w-4xl max-h-full">
                <div className="relative bg-white rounded-lg shadow-xl dark:bg-gray-700">
                    <div className="w-full flex flex-col h-full p-6">
                        {/* Cabeçalho do Modal */}
                        <div className="flex justify-between items-center mb-4">
                            {/* Seção Esquerda: Ícone de Ajuda */}
                            <div className="relative inline-block">
                                <BsQuestionCircle
                                    className="cursor-pointer text-gray-700 text-2xl"
                                    onClick={() => setHelpFiltro((current) => !current)}
                                />
                                {helpFiltro && (
                                    <div className="absolute top-full left-0 mt-2 w-64 z-50">
                                        <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-2 shadow-lg">
                                            <p className="text-sm font-semibold text-gray-900 dark:text-white">
                                                Essa página serve para você definir suas preferências da captação automática de processos.
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Seção Centro: Texto e Setas */}
                            <div className="flex items-center justify-center flex-grow text-center">
                                <AiOutlineArrowDown className="text-2xl mx-2" />
                                <h3 className="text-2xl font-medium">Configure seus filtros padrões</h3>
                                <AiOutlineArrowDown className="text-2xl mx-2" />
                            </div>

                            {/* Seção Direita: Ícone de Fechamento */}
                            <div className="relative">
                                <BsX
                                    className="cursor-pointer text-red-700 text-4xl"
                                    onClick={fecharPop}
                                />
                            </div>
                        </div>
                        <div className="bg-white dark:bg-gray-700 rounded-md flex flex-col gap-6 h-full w-full p-6">
                            {/* Campo de Palavra-chave */}
                            <div className="flex items-center bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#ff8027] focus:border-[#ff8027] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                                <BsSearch className="text-xl text-gray-500 dark:text-gray-400 mr-2" />
                                <input
                                    className="w-full bg-transparent border-none focus:outline-none"
                                    type="text"
                                    placeholder="Palavra-chave"
                                    value={palavraChave}
                                    onChange={(e) => setPalavraChave(e.target.value)}
                                />
                            </div>

                            {/* Modalidade de Contratação */}
                            <div className="relative shadow-sm hover:shadow-md transition-shadow duration-200 rounded-lg p-2 bg-white dark:bg-gray-700">
                                <label className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Modalidade da contratação
                                </label>
                                <div className="relative mt-2">
                                    <div
                                        className="inline-flex w-full text-lg cursor-pointer justify-between items-center text-gray-700 dark:text-gray-300 transition-opacity hover:opacity-90 p-2 rounded-lg bg-gray-100 dark:bg-gray-600 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-500"
                                        onClick={() =>
                                            filtroAtual === Filtro.MODALIDADE
                                                ? setFiltroAtual(Filtro.IDLE)
                                                : setFiltroAtual(Filtro.MODALIDADE)
                                        }
                                    >
                                        {filtroModalidade.length <= 0
                                            ? 'Selecione as modalidades'
                                            : filtroModalidade.length === 1
                                                ? nomeModalidade(filtroModalidade[0])
                                                : `${nomeModalidade(filtroModalidade[0])} + ${filtroModalidade.length - 1}`}
                                    </div>

                                    {filtroAtual === Filtro.MODALIDADE && (
                                        <div className="absolute top-full left-0 mt-2 w-full bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 transition-all duration-300">
                                            <div className="max-h-52 overflow-y-auto p-2">
                                                {['6', '8', '9', '7', '12', '4', '5'].map((id) => (
                                                    <div
                                                        key={id}
                                                        onClick={() => modalidadeFiltro(id)}
                                                        className="flex items-center cursor-pointer text-gray-700 dark:text-gray-300 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors mb-1"
                                                    >
                                                        {filtroModalidade.includes(id) ? (
                                                            <MdOutlineCheckBox className="mr-2" />
                                                        ) : (
                                                            <MdOutlineCheckBoxOutlineBlank className="mr-2" />
                                                        )}
                                                        {nomeModalidade(id)}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* UFs */}
                            <div className="relative shadow-sm hover:shadow-md transition-shadow duration-200 rounded-lg p-2 bg-white dark:bg-gray-700">
                                <label className="text-lg font-semibold text-gray-900 dark:text-white">UFs</label>
                                <div className="relative mt-2">
                                    <div
                                        className="inline-flex w-full text-lg cursor-pointer justify-between items-center text-gray-700 dark:text-gray-300 transition-opacity hover:opacity-90 p-2 rounded-lg bg-gray-100 dark:bg-gray-600 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-500"
                                        onClick={() =>
                                            filtroAtual === Filtro.UF ? setFiltroAtual(Filtro.IDLE) : setFiltroAtual(Filtro.UF)
                                        }
                                    >
                                        {filtroUf.length <= 0
                                            ? 'Selecione as UFs'
                                            : filtroUf.length === 1
                                                ? filtroUf[0]
                                                : `${filtroUf[0]} + ${filtroUf.length - 1}`}
                                    </div>

                                    {filtroAtual === Filtro.UF && (
                                        <div className="absolute top-full left-0 mt-2 w-full bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 transition-all duration-300">
                                            <div className="max-h-52 overflow-y-auto p-2">
                                                {[
                                                    'MG',
                                                    'SP',
                                                    'CE',
                                                    'BA',
                                                    'RS',
                                                    'GO',
                                                    'PR',
                                                    'PB',
                                                    'SC',
                                                    'PE',
                                                    'RJ',
                                                    'RN',
                                                    'PA',
                                                    'PI',
                                                    'MA',
                                                    'ES',
                                                    'MT',
                                                    'TO',
                                                    'AM',
                                                    'DF',
                                                    'SE',
                                                    'MF',
                                                    'AL',
                                                    'RO',
                                                    'RR',
                                                    'AP',
                                                    'AC',
                                                ].map((uf) => (
                                                    <div
                                                        key={uf}
                                                        onClick={() => ufFiltro(uf)}
                                                        className="flex items-center cursor-pointer text-gray-700 dark:text-gray-300 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors mb-1"
                                                    >
                                                        {filtroUf.includes(uf) ? (
                                                            <MdOutlineCheckBox className="mr-2" />
                                                        ) : (
                                                            <MdOutlineCheckBoxOutlineBlank className="mr-2" />
                                                        )}
                                                        {uf}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Instrumento Convocatório */}
                            <div className="relative shadow-sm hover:shadow-md transition-shadow duration-200 rounded-lg p-2 bg-white dark:bg-gray-700">
                                <label className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Instrumento Convocatório
                                </label>
                                <div className="relative mt-2">
                                    <div
                                        className="inline-flex w-full text-lg cursor-pointer justify-between items-center text-gray-700 dark:text-gray-300 transition-opacity hover:opacity-90 p-2 rounded-lg bg-gray-100 dark:bg-gray-600 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-500"
                                        onClick={() =>
                                            filtroAtual === Filtro.INSTRUMENTO
                                                ? setFiltroAtual(Filtro.IDLE)
                                                : setFiltroAtual(Filtro.INSTRUMENTO)
                                        }
                                    >
                                        {instrumentoConvocatorio.length <= 0
                                            ? 'Selecione as categorias'
                                            : instrumentoConvocatorio.length === 1
                                                ? nomeInstrumento(instrumentoConvocatorio[0])
                                                : `${nomeInstrumento(instrumentoConvocatorio[0])} + ${instrumentoConvocatorio.length - 1}`}

                                    </div>

                                    {filtroAtual === Filtro.INSTRUMENTO && (
                                        <div className="absolute top-full left-0 mt-2 w-full bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 transition-all duration-300">
                                            <div className="max-h-52 overflow-y-auto p-2">
                                                {[
                                                    { id: '1', name: 'Edital' },
                                                    { id: '2', name: 'Aviso de contratação direta' },
                                                    { id: '4', name: 'Edital de chamamento público' },
                                                ].map((item) => (
                                                    <div
                                                        key={item.id}
                                                        onClick={() => instrumentoConvFiltro(item.id)}
                                                        className="flex items-center cursor-pointer text-gray-700 dark:text-gray-300 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors mb-1"
                                                    >
                                                        {instrumentoConvocatorio.includes(item.id) ? (
                                                            <MdOutlineCheckBox className="mr-2" />
                                                        ) : (
                                                            <MdOutlineCheckBoxOutlineBlank className="mr-2" />
                                                        )}
                                                        {item.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Esfera */}
                            <div className="relative shadow-sm hover:shadow-md transition-shadow duration-200 rounded-lg p-2 bg-white dark:bg-gray-700">
                                <label className="text-lg font-semibold text-gray-900 dark:text-white">Esfera</label>
                                <div className="relative mt-2">
                                    <div

                                        className="inline-flex w-full text-lg cursor-pointer justify-between items-center text-gray-700 dark:text-gray-300 transition-opacity hover:opacity-90 p-2 rounded-lg bg-gray-100 dark:bg-gray-600 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-500"
                                        onClick={() =>
                                            filtroAtual === Filtro.ESFERA ? setFiltroAtual(Filtro.IDLE) : setFiltroAtual(Filtro.ESFERA)
                                        }
                                    >
                                        {filtroEsfera.length <= 0
                                            ? 'Selecione a esfera'
                                            : filtroEsfera.length === 1
                                                ? nomeEsfera(filtroEsfera[0])
                                                : `${nomeEsfera(filtroEsfera[0])} + ${filtroEsfera.length - 1}`}
                                    </div>

                                    {filtroAtual === Filtro.ESFERA && (
                                        <div className="absolute top-full left-0 mt-2 w-full bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 transition-all duration-300">
                                            <div className="max-h-52 overflow-y-auto p-2">
                                                {[
                                                    { id: 'M', name: 'Municipal' },
                                                    { id: 'E', name: 'Estadual' },
                                                    { id: 'F', name: 'Federal' },
                                                    { id: 'D', name: 'Distrital' },
                                                ].map((item) => (
                                                    <div
                                                        key={item.id}
                                                        onClick={() => handleEsferaFiltro(item.id)}
                                                        className="flex items-center cursor-pointer text-gray-700 dark:text-gray-300 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors mb-1"
                                                    >
                                                        {filtroEsfera.includes(item.id) ? (
                                                            <MdOutlineCheckBox className="mr-2" />
                                                        ) : (
                                                            <MdOutlineCheckBoxOutlineBlank className="mr-2" />
                                                        )}
                                                        {item.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Botões */}
                            <div className="flex justify-between items-center w-full p-2">
                                {/* Botão vermelho à esquerda */}
                                <button
                                    className="cursor-pointer text-white inline-flex items-center bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit"
                                    onClick={limparFiltro}
                                >
                                    Limpar
                                </button>

                                {/* Botão verde à direita com ícone de interrogação */}
                                <div className="flex items-center gap-2">
                                    <button
                                        className="cursor-pointer text-white inline-flex items-center bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center h-fit px-4 w-44"
                                        onClick={() => handleFitrarProcesso(true)}
                                    >
                                        Aplicar e salvar filtro
                                    </button>

                                    {/* Ícone de interrogação */}
                                    <div className="relative">
                                        <BsQuestionCircle
                                            className="mt-1 w-fit cursor-pointer"
                                            onClick={() => setHelpFiltro((current) => !current)}
                                        />

                                        {/* Tooltip de ajuda */}
                                        {helpFiltro && (
                                            <div className="absolute top-5 left-5 z-50 max-w-48 w-48">
                                                <div className="bg-gray-100 rounded-lg p-1">
                                                    <p className="text-[0.7rem] font-semibold">
                                                        Ao clicar neste botão, o filtro desta pesquisa será utilizado nas buscas automáticas
                                                        de editais.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CaptacaoPopInit;
