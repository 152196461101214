import { useLayoutEffect, useState } from 'react';
import { BsEye, BsEyeSlash, BsX } from 'react-icons/bs';
import { GrFormCheckmark } from 'react-icons/gr';
import { checkCpfCnpj } from '../../Utils/checkCpfCnpj';
import { formatCpfCnpj } from '../../Utils/formatCpfCnpj';
import { IMaskInput } from 'react-imask';
import { NivelHierarquicoRegistro, RegistroEmpresa } from '../../Utils/types/cadastroTypes';
import { getCnpjData } from '../../Utils/cnpjAutocomplete';
import { cnpj } from 'cpf-cnpj-validator';
import TermosRegistro from './TermosRegistro';
import { toast } from 'react-toastify';
import { useGetAcessos } from '../../Utils/ReactQuery/getAcessos';
import { useMutation, useQuery } from '@tanstack/react-query';
import AlertaCadastroExistente from './AlertaCadastroExistente';



const EmpresaFormEtapaUm = ({ fechar, registrar }: any) => {


    const [cpfCnpj, setCpfCnpj] = useState<string>('');
    const [cpfCnpjVerify, setCpfCnpjVerify] = useState<boolean | any>(true);
    const [passWordError, setPasswordError] = useState<boolean>(false);
    const [passwordErrorrMesg, setPasswordErrorMsg] = useState<string>('');
    const [checkPassword, setCheckPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [repetirSenha, setRepetirSenha] = useState<string>('');
    const [nivelHierarquico, setNivelHierarquico] = useState<NivelHierarquicoRegistro | null>(null);

    const [cnpjEmpresa, setCnpjEmpresa] = useState<string>('');

    const [razaoSocial, setRazaoSocial] = useState<string>('');
    const [nomeFantasia, setNomeFantasia] = useState<string>('');
    const [naturezaJuridica, setNaturezaJuridica] = useState<string>('');
    const [mei, setMEI] = useState<Boolean>(false);
    const [portedaEmpresa, setPortedaEmpresa] = useState<string>('');
    const [atividadeEconômicaP, setAtividadeEconômicaP] = useState<any>([]);
    const [atividadeEconômicaS, setAtividadeEconômicaS] = useState<any>([]);
    const [capitalSocial, setCapitalSocial] = useState<string>('');
    const [dataAbertura, setDataAbertura] = useState<string>('');
    const [endereçoEmpresa, setEndereçoEmpresa] = useState<string>('');
    const [complementoEmpresa, setComplementoEmpresa] = useState<string>();
    const [cepEmpresa, setCepEmpresa] = useState<string>('');
    const [foneEmpresa, setFoneEmpresa] = useState<string>('');
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

    const [condiçõesLegais, setCondiçõesLegais] = useState<boolean>(false);
    const [politicaPrivacidade, setPoliticaPrivacidade] = useState<boolean>(false);
    const [termoAceito, setTermoAceito] = useState<boolean>(false)

    const [cadastroExistente, setCadastroExistente] = useState<boolean>(false);
    const [empresaData, setEmpresaData] = useState<any>({});
    const [verificaçãoCadastro, setVerificaçãoCadasto] = useState<boolean>(false);


    const fecharCadastro = ()=>{
        setCadastroExistente(false);
        setEmpresaData('')
    }


    const checkPass = (e: any) => {
        e.preventDefault();
        if (checkPassword.length > 0 && checkPassword.length < 8) {
            setPasswordError(true);
            setPasswordErrorMsg('A senha deve ter mais de 8 dígitos');
            return;
        }

        if (repetirSenha.length >= 1 && repetirSenha !== checkPassword) {
            setPasswordError(true);
            setPasswordErrorMsg('As senhas digitadas não coincidem!');
            return;
        }
        setPasswordError(false);
    };

      // Verificar o se usuario já possui acesso
      const {verificarCadastro} = useGetAcessos();
      const {data: verCadData, isLoading, refetch}= useQuery({
          queryFn: ()=> verificarCadastro(cpfCnpj),
          queryKey: ['rqVerificarCadastro'],

      });
      
      useLayoutEffect(()=>{

        if(verCadData && verCadData.status === 4){
            setVerificaçãoCadasto(true)
        }
        if(verCadData && verCadData.status === 1){
            setVerificaçãoCadasto(false)
        } 

      },[verCadData])

      const verificarCpfCnpj = ()=>{

        setCpfCnpjVerify(checkCpfCnpj(cpfCnpj))
        
        if(checkCpfCnpj(cpfCnpj)){
            refetch();

            if(verCadData.status === 4){
                setVerificaçãoCadasto(true)
            }
            if(verCadData.status === 1){
                setVerificaçãoCadasto(false)
            } 
        }
    }


      const verificarCaddastroMation = useMutation({
        mutationFn: verificarCadastro,
        onMutate: async(data)=>{
        },
        onSuccess: async(response)=>{
            if(response.status === 4){
                setCadastroExistente(true)
            }
            if(response.status === 1){
                setCadastroExistente(false)
            }
        },
        onError: async(error)=>{
            console.error(error.message)
        }
      })

      // Verificar o se usuario já possui acesso

    const inputField = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500';
    const labelField = 'block mb-2 text-sm font-medium text-gray-900 dark:text-white';
    const button = 'cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit';
    const closeButton = 'bg-red-500 cursor-pointer text-white inline-flex items-center bg-bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:bg-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit';

    const handleEtapa = (e: any) => {
        e.preventDefault();

        if (!termoAceito) {
            toast.error("É necessario aceitar o termo de concentimento para registrar-se no portal!", { autoClose: 3000 });
            return
        }

        const formData = new FormData(e.currentTarget);

        searchCnpj(cnpjEmpresa);

        const dadosEtapa = {
            Nome: formData.get('Nome'),
            Email: formData.get('Email'),
            Senha: formData.get('Senha'),
            CpfCnpj: formatCpfCnpj(formData.get('CpfCnpj') as string),
            CnpjEmpresa: formatCpfCnpj(formData.get('CnpjEmpresa') as string),
            Fone: formData.get('Fone'),
            NivelHierarquico: indexNivelHierrarquico(formData.get('NivelHierarquico') as NivelHierarquicoRegistro),
            CargoFuncao: formData.get('CargoFuncao'),
            RazãoSocial: formData.get('RazãoSocial'),
            Acesso: formData.get('Acesso'),
            NaturezaJuridica: naturezaJuridica,
            CapitalSocial: capitalSocial,
            RazaoSocial: razaoSocial,
            NomeFantasia: nomeFantasia,
            DataAbertura: dataAbertura,
            EndereçoEmpresa: endereçoEmpresa,
            ComplementoEmpresa: complementoEmpresa,
            CepEmpresa: cepEmpresa,
            FoneEmpresa: foneEmpresa,
            PortedaEmpresa: portedaEmpresa,
            MEI: mei,
            AtividadeEconômicaP: atividadeEconômicaP,
            AtividadeEconômicaS: atividadeEconômicaS,
            Afiliação: cnpjEmpresa,
            Cadastro: "Empresa"


        }

        setEmpresaData(dadosEtapa);
        verificarCaddastroMation.mutate(cpfCnpj)

        if(verCadData && verCadData.status !== 4 ){
           registrar(dadosEtapa) 
        }

        
    };


    const searchCnpj = async (cnpj: string) => {

        if (cnpj.length < 14) {
            return
        }

        try {
            const res = await getCnpjData(cnpj as string);

            setNaturezaJuridica(res.natureza_juridica);
            setCapitalSocial(res.capital_social);
            setRazaoSocial(res.razao_social);
            setNomeFantasia(res.nome_fantasia);
            setDataAbertura(res.data_inicio_atividade);
            setEndereçoEmpresa(`${res.logradouro}, ${res.municipio}, ${res.uf}`);
            setComplementoEmpresa(res.complemento);
            setCepEmpresa(res.cep);
            setFoneEmpresa(res.ddd_telefone_1);
            setPortedaEmpresa(res.porte)
            setMEI(res.opcao_pelo_mei)
            setAtividadeEconômicaP({
                codigo: res.cnae_fiscal,
                descricao: res.cnae_fiscal_descricao
            });
            setAtividadeEconômicaS(res.cnaes_secundarios)

        } catch (error) {
            console.error('Erro');
            return;
        }
    }

    const indexNivelHierrarquico = (hierarquia: NivelHierarquicoRegistro) => {
        switch (true) {
            case hierarquia === NivelHierarquicoRegistro.ASSESSOR:
                return { tipo: 'Assessor', nivel: 1 }
            case hierarquia === NivelHierarquicoRegistro.PRESIDENTE:
                return { tipo: 'Presidente', nivel: 2 }
            case hierarquia === NivelHierarquicoRegistro.VICE_PRESIDENTE:
                return { tipo: 'Vice-Presidente', nivel: 3 }
            case hierarquia === NivelHierarquicoRegistro.DIRETOR:
                return { tipo: 'Diretor', nivel: 4 };
            case hierarquia === NivelHierarquicoRegistro.GERENTE:
                return { tipo: 'Gerente', nivel: 5 };
            case hierarquia === NivelHierarquicoRegistro.SUPERVISOR:
                return { tipo: 'Supervisor', nivel: 6 };
            case hierarquia === NivelHierarquicoRegistro.COODERNADOR:
                return { tipo: 'Coodernador', nivel: 7 };
            case hierarquia === NivelHierarquicoRegistro.TÉCNICO:
                return { tipo: 'Técnico', nivel: 8 };
            case hierarquia === NivelHierarquicoRegistro.ANALISTA:
                return { tipo: 'Analista', nivel: 9 };
            case hierarquia === NivelHierarquicoRegistro.ASSISTENTE:
                return { tipo: 'Assistente', nivel: 10 };
            case hierarquia === NivelHierarquicoRegistro.AUXILIAR:
                return { tipo: 'Auxiliar', nivel: 11 };
            case hierarquia === NivelHierarquicoRegistro.ESTAGIÁRIO:
                return { tipo: 'Estagiário', nivel: 12 };
            case hierarquia === NivelHierarquicoRegistro.TRAINEE:
                return { tipo: 'Trainee', nivel: 13 };
            case hierarquia === NivelHierarquicoRegistro.JOVEM_APRENDIZ:
                return { tipo: 'Jovem Aprendiz', nivel: 14 }

        }
    }

    const fecharTermoModal = () => {
        setPoliticaPrivacidade(false);
        setCondiçõesLegais(false);
    }


    return (
        <div id="defaultModal" tabIndex={-1} aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">

            {
                cadastroExistente && 
                <AlertaCadastroExistente data ={empresaData} fechar={fecharCadastro}/>
            }

            {
                politicaPrivacidade && <TermosRegistro showTermsModal={false} showPrivacyModal={true} fechar={fecharTermoModal} />
            }

            {
                condiçõesLegais && <TermosRegistro showTermsModal={true} showPrivacyModal={false} fechar={fecharTermoModal} />
            }


            <div className="relative p-4 w-full max-w-5xl h-full md:h-auto">
                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                    <div className="flex flex-row w-full justify-between">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Cadastrar empresa ou assessoria</h3>
                    </div>

                    <form onSubmit={handleEtapa} className="mt-2">
                        <label className={`${labelField} uppercase`}>Dados do usuário</label>



                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                           
                            <div>
                                <label htmlFor="floating_cpfcnpj" className={`${labelField}`}>
                                    CPF/CNPJ<a className='text-red-500'> *</a>
                                </label>
                                <IMaskInput
                                    mask={[
                                        {
                                            mask: '000.000.000-00',
                                            maxLength: 11, // CPF tem 11 dígitos
                                        },
                                        {
                                            mask: '00.000.000/0000-00',
                                            maxLength: 14, // CNPJ tem 14 dígitos
                                        },
                                    ]}
                                    type="text"
                                    name="CpfCnpj"
                                    id="floating_cpfcnpj"
                                    className={`${inputField}`}
                                    placeholder=""
                                    required
                                    value={cpfCnpj}
                                    onAccept={(value) => setCpfCnpj(value)}
                               
                                    onBlur={verificarCpfCnpj}
                                    
                                />

                                {cpfCnpj && cpfCnpj.length > 2 && !cpfCnpjVerify && (
                                    <p className='text-red-500'>*CPF/CNPJ Inválido!</p>
                                )}

                            </div>



                            {
                            
                            verificaçãoCadastro 
                            
                            ?
                            <p className='text-red-500 pt-4'> Usuário ja possuí cadastro, utilize a senha atual para registrar uma nova empresa! </p>
                            :
                            <>
                                <div>
                                    <label className={`${labelField}`}>Nome<span className="text-red-500"> *</span></label>
                                    <input type="text" name="Nome" className={`${inputField}`} />
                                </div>

                                <div>
                                    <label className={`${labelField}`}>E-mail<span className="text-red-500"> *</span></label>
                                    <input type="email" name="Email" className={`${inputField}`} />
                                </div>
                            </>
                            }
                            


                        </div>
                        <div className='grid gap-4 mb-4 sm:grid-cols-2'>
                            <div>
                                <label className={`${labelField}`}>Senha<span className='text-red-500'> *</span></label>
                                <div className="relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="Senha"
                                        className={`${inputField} pr-10`}
                                        placeholder=" "
                                        required
                                        onBlur={checkPass}
                                        onChange={(e) => setCheckPassword(e.target.value)}
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                        onClick={() => setShowPassword((current) => !current)}
                                    >
                                        {showPassword ? (
                                            <BsEyeSlash className="w-4 h-4" />
                                        ) : (
                                            <BsEye className="w-4 h-4" />
                                        )}
                                    </div>
                                </div>
                                {passWordError && <p className='text-red-500 '>A senha deve conter mais de 8 caracteres</p>}
                            </div>

                            <div>
                                <label className={`${labelField}`}>Confirmar senha<span className='text-red-500'> *</span></label>
                                <div className="relative">
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        name="repetirSenha"
                                        className={`${inputField} pr-10`}
                                        placeholder=" "
                                        required
                                        onBlur={checkPass}
                                        onChange={(e) => setRepetirSenha(e.target.value)}
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                        onClick={() => setShowConfirmPassword((current) => !current)}
                                    >
                                        {showConfirmPassword ? (
                                            <BsEyeSlash className="w-4 h-4" />
                                        ) : (
                                            <BsEye className="w-4 h-4" />
                                        )}
                                    </div>
                                </div>
                                {passWordError && <p className='text-red-500 '>{passwordErrorrMesg}</p>}
                            </div>
                        </div>

                        <label className={`${labelField} uppercase border-t border-opacity-90`}>Dados da empresa</label>

                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                            <div>
                                <label className={`${labelField}`}>Acesso<span className='text-red-500'> *</span></label>
                                <select className={`${inputField}`} required name='Acesso' >
                                    <option value={"Empresa"} className={`${inputField}`}>Empresa</option>
                                    <option value={"Assessoria"} className={`${inputField}`}>Assessoria</option>
                                </select>
                            </div>
                            <div>
                                <label className={`${labelField}`}> CNPJ <span className='text-red-500'>*</span></label>
                                <IMaskInput
                                    onBlur={(e) => searchCnpj(cnpjEmpresa)}
                                    mask="00.000.000/0000-00"
                                    type="text" name="CnpjEmpresa" className={`${inputField}`} required placeholder="" onAccept={(value, mask) => setCnpjEmpresa(value)} />
                                {
                                    cnpjEmpresa && cnpjEmpresa.length > 2 && !cnpj.isValid(cnpjEmpresa as string) && <p className='text-red-500'> CNPJ inválido.</p>
                                }
                            </div>
                        </div>



                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                            <div className='mb-4'>
                                <label className={`${labelField}`}>Razão social <span className='text-red-500'> *</span></label>
                                <input type="text" name="RazãoSocial" className={`${inputField}`} value={razaoSocial} onChange={(e) => setRazaoSocial(e.target.value)} />
                            </div>

                            <div>
                                <label className={`${labelField}`}>Fone<span className="text-red-500"> *</span></label>
                                <IMaskInput
                                    mask={[
                                        { mask: '(00) 0000-0000' },
                                        { mask: '(00) 00000-0000' }
                                    ]}
                                    dispatch={(appended, dynamicMasked) => {
                                        const number = (dynamicMasked.value + appended).replace(/\D/g, '');

                                        return dynamicMasked.compiledMasks.find((m) => {
                                            if (number.length <= 10) {
                                                return m.mask === '(00) 0000-0000';
                                            } else {
                                                return m.mask === '(00) 00000-0000';
                                            }
                                        });
                                    }}
                                    type="text"
                                    name="Fone"
                                    className={`${inputField}`}
                                    required
                                    value={foneEmpresa}
                                    onAccept={(value) => setFoneEmpresa(value)}
                                />
                            </div>

                            <div>
                                <label className={`${labelField}`} >Nível hierárquico<span className="text-red-500"> *</span></label>
                                <select required={true} name="NivelHierarquico" defaultValue={''} className={`${inputField}`} onChange={(e) => setNivelHierarquico(e.target.value as NivelHierarquicoRegistro)}>
                                    <option value={''} disabled className={`${inputField}`}>Selecionar</option>
                                    <option value={NivelHierarquicoRegistro.PRESIDENTE}>Presidente</option>
                                    <option value={NivelHierarquicoRegistro.VICE_PRESIDENTE}>Vice-Presidente</option>
                                    <option value={NivelHierarquicoRegistro.DIRETOR}>Diretor</option>
                                    <option value={NivelHierarquicoRegistro.GERENTE}>Gerente</option>
                                    <option value={NivelHierarquicoRegistro.SUPERVISOR}>Supervisor</option>
                                    <option value={NivelHierarquicoRegistro.COODERNADOR}>Coodernador</option>
                                    <option value={NivelHierarquicoRegistro.TÉCNICO}>Técnico</option>
                                    <option value={NivelHierarquicoRegistro.ANALISTA}>Analista</option>
                                    <option value={NivelHierarquicoRegistro.ASSISTENTE}>Assistente</option>
                                    <option value={NivelHierarquicoRegistro.AUXILIAR}>Auxiliar</option>
                                    <option value={NivelHierarquicoRegistro.ESTAGIÁRIO}>Estagiário</option>
                                    <option value={NivelHierarquicoRegistro.TRAINEE}>Trainee</option>
                                    <option value={NivelHierarquicoRegistro.JOVEM_APRENDIZ}>Jovem Aprendiz</option>
                                    <option value={NivelHierarquicoRegistro.ASSESSOR}>Assessor</option>
                                </select>
                            </div>
                            <div>
                                <label className={`${labelField}`}>Cargo/Função<span className="text-red-500"> *</span></label>
                                <select required={true} name="CargoFuncao" className={`${inputField}`} >
                                    {
                                        nivelHierarquico === NivelHierarquicoRegistro.PRESIDENTE ?
                                            <option className={inputField} value={RegistroEmpresa.PRESIDENTE}
                                                selected={nivelHierarquico === NivelHierarquicoRegistro.PRESIDENTE ? true : false}>Presidente</option>
                                            :
                                            nivelHierarquico === NivelHierarquicoRegistro.VICE_PRESIDENTE ?
                                                <option value={RegistroEmpresa.VICEPRESIDENTE}
                                                    selected={nivelHierarquico === NivelHierarquicoRegistro.VICE_PRESIDENTE ? true : false}>Vice-Presidente</option>
                                                :
                                                <>
                                                    <option className={inputField} value={RegistroEmpresa.GERAL}>Geral</option>
                                                    <option className={inputField} value={RegistroEmpresa.ADMINISTRATIVO}>Administrativo</option>
                                                    <option className={inputField} value={RegistroEmpresa.ATENDIMENTO}>Atendimento</option>
                                                    <option className={inputField} value={RegistroEmpresa.FINANCEIRO}>Financeiro</option>
                                                    <option className={inputField} value={RegistroEmpresa.CONTAS_Á_RECEBER}>Contas á Receber</option>
                                                    <option className={inputField} value={RegistroEmpresa.CONTAR_Á_PAGAR}>Contar á Pagar</option>
                                                    <option className={inputField} value={RegistroEmpresa.CONCILIAÇÃO_BANCÁRIA}>Conciliação Bancária</option>
                                                    <option className={inputField} value={RegistroEmpresa.COBRANÇA}>Cobrança</option>
                                                    <option className={inputField} value={RegistroEmpresa.FATURAMENTO}>Faturamento</option>
                                                    <option className={inputField} value={RegistroEmpresa.COMERCIAL}>Comercial</option>
                                                    <option className={inputField} value={RegistroEmpresa.COMPRAS}>Compras</option>
                                                    <option className={inputField} value={RegistroEmpresa.VENDAS}>Vendas</option>
                                                    <option className={inputField} value={RegistroEmpresa.MARKETING}>Marketing</option>
                                                    <option className={inputField} value={RegistroEmpresa.PROPAGANDA}>Propaganda</option>
                                                    <option className={inputField} value={RegistroEmpresa.ALMOXARIFADO}>Almoxarifado</option>
                                                    <option className={inputField} value={RegistroEmpresa.LICITAÇÕES_E_CONTRATOS}>Licitações e Contratos</option>
                                                    <option className={inputField} value={RegistroEmpresa.LICITAÇÕES}>Licitações</option>
                                                    <option className={inputField} value={RegistroEmpresa.CONTRATOS}>Contratos</option>
                                                    <option className={inputField} value={RegistroEmpresa.CAPTAÇÃO}>Captação</option>
                                                    <option className={inputField} value={RegistroEmpresa.PROPOSTA}>Proposta</option>
                                                    <option className={inputField} value={RegistroEmpresa.HABILITAÇÃO}>Habilitação</option>
                                                    <option className={inputField} value={RegistroEmpresa.REGISTRO}>Registro Empresa</option>
                                                    <option className={inputField} value={RegistroEmpresa.RECURSOS_ADMINISTRATIVO}>Recursos Administrativo</option>
                                                    <option className={inputField} value={RegistroEmpresa.OPERADOR}>Operador</option>
                                                    <option className={inputField} value={RegistroEmpresa.JURÍDICO_E_COMPLIANCE}>Jurídico e Compliance</option>
                                                    <option className={inputField} value={RegistroEmpresa.JURÍDICO}>Jurídico</option>
                                                    <option className={inputField} value={RegistroEmpresa.COMPLIANCE}>Compliance</option>
                                                    <option className={inputField} value={RegistroEmpresa.CONTÁBIL}>Contábil</option>
                                                    <option className={inputField} value={RegistroEmpresa.FISCAL}>Fiscal</option>
                                                    <option className={inputField} value={RegistroEmpresa.AUDITORIA}>Auditoria</option>
                                                    <option className={inputField} value={RegistroEmpresa.RECEITAS}>Receitas</option>
                                                    <option className={inputField} value={RegistroEmpresa.REPRESENTAÇÃO}>Representação</option>
                                                    <option className={inputField} value={RegistroEmpresa.COMERCIAL}>Comercial</option>
                                                    <option className={inputField} value={RegistroEmpresa.SEGURO}>Seguro</option>
                                                    <option className={inputField} value={RegistroEmpresa.CONTROLLER}>Controller</option>
                                                    <option className={inputField} value={RegistroEmpresa.MANUTENÇÃO}>Manutenção</option>
                                                    <option className={inputField} value={RegistroEmpresa.PRODUÇÃO}>Produção</option>
                                                    <option className={inputField} value={RegistroEmpresa.SINISTRO}>Sinistro</option>
                                                    <option className={inputField} value={RegistroEmpresa.QUALIDADE}>Qualidade</option>
                                                    <option className={inputField} value={RegistroEmpresa.SEGURANÇA}>Segurança</option>
                                                    <option className={inputField} value={RegistroEmpresa.DESENVOLVIMENTO}>Desenvolvimento</option>
                                                    <option className={inputField} value={RegistroEmpresa.PESQUISA}>Pesquisa</option>
                                                    <option className={inputField} value={RegistroEmpresa.TECNOLOGIA_DA_INFORMAÇÃO}>Tecnologia da Informação</option>
                                                    <option className={inputField} value={RegistroEmpresa.RECURSOS_HUMANOS}>Recursos Humanos</option>
                                                    <option className={inputField} value={RegistroEmpresa.RECRUTAMENTO}>Recrutamento</option>
                                                    <option className={inputField} value={RegistroEmpresa.TREINAMENTO}>Treinamento</option>
                                                    <option className={inputField} value={RegistroEmpresa.OPERAÇÕES}>Operações</option>
                                                    <option className={inputField} value={RegistroEmpresa.LOGÍSTICA}>Logística</option>
                                                    <option className={inputField} value={RegistroEmpresa.GESTÃO_DE_PESSOAS}>Gestão de Pessoas</option>
                                                    <option className={inputField} value={RegistroEmpresa.CREDENCIAMENTO}>Credenciamento</option>
                                                    <option className={inputField} value={RegistroEmpresa.EXPEDIÇÃO}>Expedição</option>
                                                    <option className={inputField} value={RegistroEmpresa.CONSELHO}>Conselho</option>
                                                    <option className={inputField} value={RegistroEmpresa.ORÇAMENTISTA}>Orçamentista</option>
                                                    <option className={inputField} value={RegistroEmpresa.RELAÇÕES}>Relações</option>
                                                    <option className={inputField} value={RegistroEmpresa.CUSTOS}>Custos</option>
                                                    <option className={inputField} value={RegistroEmpresa.GESTÃO}>Gestão</option>
                                                    <option className={inputField} value={RegistroEmpresa.PROJETOS}>Projetos</option>
                                                    <option className={inputField} value={RegistroEmpresa.ESTOQUE}>Estoque</option>
                                                    <option className={inputField} value={RegistroEmpresa.ENTREGA}>Entrega</option>
                                                    <option className={inputField} value={RegistroEmpresa.CREDITO}>Credito</option>
                                                    <option className={inputField} value={RegistroEmpresa.PATRIMÔNIO}>Patrimônio</option>
                                                    <option className={inputField} value={RegistroEmpresa.TESOURARIA}>Tesouraria</option>
                                                    <option className={inputField} value={RegistroEmpresa.INOVAÇÃO}>Inovação</option>
                                                    <option className={inputField} value={RegistroEmpresa.ARQUIVOS}>Arquivos</option>
                                                    <option className={inputField} value={RegistroEmpresa.PRODUTOS}>Produtos</option>
                                                    <option className={inputField} value={RegistroEmpresa.TÉCNICO}>Técnico</option>
                                                    <option className={inputField} value={RegistroEmpresa.PROTOCOLO}>Protocolo</option>
                                                    <option className={inputField} value={RegistroEmpresa.IMPRENSA}>Imprensa</option>
                                                    <option className={inputField} value={RegistroEmpresa.AGENDAMENTO}>Agendamento</option>
                                                </>

                                    }
                                </select>
                            </div>
                        </div>

                        <p className="text-red-500 mb-4">*O login será realizado mediante ao CPF do usuário</p>
                        <div className="flex items-center mb-4">
                            <input
                                type="checkbox"
                                name="terms"
                                className="mr-2"
                                onChange={(e) => {
                                    e.target.checked ? setTermoAceito(true) : setTermoAceito(false)
                                }}
                            />

                            <label >Li e aceito as Condições do <span className='underline text-blue-600 cursor-pointer opacity-80 transition-opacity hover:opacity-100'>Contrato</span> e as <span className='underline text-blue-600 cursor-pointer opacity-80 transition-opacity hover:opacity-100' onClick={() => setPoliticaPrivacidade(current => !current)}>Politica de Privacidade</span> e as <span className='underline text-blue-600 cursor-pointer opacity-80 transition-opacity hover:opacity-100' onClick={() => setCondiçõesLegais(current => !current)}>Condições Legais</span> do Portal GLC</label>

                        </div>
                        <div className="flex flex-row w-full justify-between">
                            <button type="submit" className={`${button}`}>
                                Registrar<GrFormCheckmark className="text-2xl ml-2" />
                            </button>
                            <label className={`${closeButton}`} onClick={fechar}>
                                Cancelar<BsX className="text-2xl ml-2" />
                            </label>
                        </div>
                    </form>
                </div>
            </div>


        </div>
    );
};

export default EmpresaFormEtapaUm;
