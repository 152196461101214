import { useEffect, useState } from 'react';
import { BsDownload, BsFilePlus, BsTrash } from 'react-icons/bs';
import { FaEye } from 'react-icons/fa';

import { formatarData } from '../../Utils/formatarData';

import moment from 'moment';
import ApagarPastaArquivoProcesso from './ApagarPastaArquivoProcesso';
import NovoArquivoProcesso from './NovoArquivoProcesso';
import ArquivoModal from '../ArquivosComponents.tsx/ArquivoModal';
import { toast } from 'react-toastify';
import { converterValor } from '../../Utils/ConverterValor';

const AbrirPastaProcesso = ({ pasta, processo, arquivosPasta }: any) => {
  const [arquivoSelecionado, setArquivoSelecionado] = useState<any>();
  const [apagarArquivo, setApagarArquivo] = useState<boolean>(false);
  const [arquivoAberto, setArquivoAberto] = useState<boolean>(false);
  const [novoArquivo, setNovoArquivo] = useState<boolean>(false);
  const [arquivos, setArquivos] = useState<any>([]);

  useEffect(() => {
    if (arquivosPasta) {
      const arquivosFind = arquivosPasta.filter((i: any) => {
        return i.pastaid === pasta._id;
      });
      setArquivos(arquivosFind);
    }
  }, [arquivosPasta, pasta._id]);

  const checkStatus = (dataVencimento: string, status: string) => {
    const dataAtual = moment();

    if (!dataVencimento || dataVencimento.length <= 1) {
      return <span className="text-green-500"> Vigente </span>;
    }

    const dataFinal = moment(dataVencimento);
    const dif = dataFinal.diff(dataAtual, 'days');

    switch (true) {
      case status === 'Deferido':
        return <span className="text-green-500"> Deferido </span>;
      case status === 'Indeferido':
        return <span className="text-red-500"> Indeferido </span>;
      case dif > 0 && (status === 'nenhum' || !status):
        return <span className="text-green-500"> Vigente </span>;
      case dif <= 0 && (status === 'nenhum' || !status):
        return <span className="text-red-500"> Vencido </span>;
      case status === 'entregue':
        return <span className="text-green-500"> Entregue </span>;
      case status === 'distratado':
        return <span className="text-red-500"> Distratado </span>;
      case status === 'encerrado':
        return <span className="text-red-500"> Encerrado </span>;
      default:
        return <span className="text-gray-500"> --- </span>;
    }
  };

  const handleApagarArquivo = (arquivo: any) => {
    setArquivoSelecionado(arquivo);
    setApagarArquivo(true);
  };

  const fecharApagar = () => {
    setApagarArquivo(false);
    setArquivoSelecionado('');
  };

  const fecharArquivo = () => {
    setArquivoAberto(false);
    setArquivoSelecionado('');
  };

  const hanldeAbrirArquivo = (e: any, arquivo: any) => {
    e.preventDefault();
    setArquivoSelecionado(arquivo);
    setArquivoAberto(true);
  };

  const dowloadFile = (e: any, fileUrl: string, fileName: string) => {
    e.preventDefault();

    const nomeValido = `${fileName.replaceAll(/\s/g, '_')}.pdf`;

    try {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${nomeValido}`;

          document.body.appendChild(link);

          link.click();

          link.parentNode?.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
    } catch (error) {
      console.error(error);
      toast.error('Erro no download!');
      return;
    }
  };

  const findLinkArquivo = (arquivo: any) => {
    switch (true) {
      case arquivo.oldDescartmed === true && arquivo.arquivoPath !== undefined:
        return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/${arquivo.arquivoPath.replaceAll(
          /\\/g,
          '/'
        )}`;
      case arquivo.LinkAws !== undefined:
        return arquivo.LinkAws as string;
      case arquivo.arquivoPath !== undefined:
        return `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivo.arquivoPath.replaceAll(
          /\\/g,
          '/'
        )}`;
      default:
        return '#';
    }
  };

  return (
    
        <div className="p-4 bg-white rounded-xl shadow-2xl">
          {apagarArquivo && (
            <ApagarPastaArquivoProcesso
              fechar={fecharApagar}
              arquivo={arquivoSelecionado}
              tipo={'arquivo'}
            />
          )}
          {novoArquivo && (
            <NovoArquivoProcesso
              processo={processo}
              pasta={pasta}
              editar={false}
              fechar={() => setNovoArquivo(false)}
            />
          )}
          {arquivoAberto && (
            <ArquivoModal
              arquivo={arquivoSelecionado}
              fechar={fecharArquivo}
              processo={processo}
              noArqivo={false}
            />
          )}
    
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={() => setNovoArquivo(true)}
              className="flex items-center gap-2 text-green-500 hover:text-green-700"
            >
              <BsFilePlus className="text-lg" /> Novo arquivo
            </button>
          </div>
    
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-700 dark:text-gray-400">
              <thead className="bg-gray-200 dark:bg-gray-700">
                <tr>
                  <th className="px-4 py-2">Descrição</th>
                  <th className="px-4 py-2">Opções</th>
                  <th className="px-4 py-2">Categoria</th>
                  <th className="px-4 py-2">Emissão</th>
                  <th className="px-4 py-2">Vencimento</th>
                  <th className="px-4 py-2">Valor</th>
                  <th className="px-4 py-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {arquivos &&
                  arquivos.map((arquivo: any) => (
                    <tr
                      key={arquivo._id}
                      className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <td className="px-4 py-2">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={findLinkArquivo(arquivo)}
                          className="text-blue-500 hover:underline"
                        >
                          {arquivo.nomeDoArquivo.replaceAll('_', ' ')}
                        </a>
                      </td>
    
                      <td className="px-4 py-2">
                        <div className="flex items-center gap-2">
                          <BsDownload
                            className="text-gray-600 hover:text-gray-800 cursor-pointer"
                            title="Baixar arquivo"
                            onClick={(e) =>
                              dowloadFile(
                                e,
                                arquivo.LinkAws ? arquivo.LinkAws : findLinkArquivo(arquivo),
                                arquivo.nomeDoArquivo
                              )
                            }
                          />
                          <FaEye
                            className="text-gray-600 hover:text-gray-800 cursor-pointer"
                            title="Abrir arquivo"
                            onClick={(e) => hanldeAbrirArquivo(e, arquivo)}
                          />
                          <BsTrash
                            className="text-gray-600 hover:text-gray-800 cursor-pointer"
                            title="Apagar"
                            onClick={() => handleApagarArquivo(arquivo)}
                          />
                        </div>
                      </td>
    
                      <td className="px-4 py-2">{arquivo.categoria}</td>
                      <td className="px-4 py-2">{formatarData(arquivo.dataInicio)}</td>
                      <td className="px-4 py-2">{formatarData(arquivo.dataVencimento)}</td>
                      <td className="px-4 py-2">{converterValor(arquivo.valor)}</td>
                      <td className="px-4 py-2">
                        {checkStatus(arquivo.dataVencimento, arquivo.status)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
  );
};

export default AbrirPastaProcesso;
