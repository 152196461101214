import React, { useEffect, useRef, useState } from 'react'
import { BsArrowLeft, BsArrowRight, BsCheck, BsPlusCircleDotted, BsX } from 'react-icons/bs'
import { usePortaisUtils } from '../../Utils/ReactQuery/getPortais'
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { SituaçãoInterna } from '../../Utils/types/PortalTypes';
import LoadingSmall from '../LoadingSmall';



const NovoProcessoEtapaDois = ({etapaDoisData, loading, cancelar, voltar, editar, processo, alterarParteEditada, dadosPncp}: any) => {


    const {getPortais} =  usePortaisUtils();
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');
    const [objetos, setObjetos] = useState<any>(['']);
    const [inserirObjeto, setInserirObeto] = useState<boolean>(false);
    const [objetoTemp, setObjetoTemp] = useState<any>('')
    const [portais, setPortais] = useState<any>();
    const [empresaInfo, setEmpresaInfo] = useState<string>('');


    const [modalidade, setModalidade] = useState<string>('');
    const [numero, setNumero] = useState<any>('');
    const [vte, setVte] = useState<number | string>('');
    const [tipo, setTipo] = useState<string>('');
    const [objetoAutomatico, setObjetoAutomaico] = useState<string>('');
    const [processoAutomatico, setProcessoAutomatico] = useState<string>('')


    //---------------------------------------------------

    const resgatarValorTotal =async()=>{
        
       try {
        const res = await fetch(dadosPncp.LinkItens);
        const response = await res.json();

        const valorTotal = response.flatMap((i: any)=>{
            if(!isNaN(i.valorTotal)){
                return i.valorTotal
            }
            
        }).reduce((sum: any, i: any)=>{
            return sum + i
        }).toFixed(2)


        if(!valorTotal || valorTotal === 0){
            setVte('Sigiloso!');
            return;
        }

        setVte(`R$: ${valorTotal}`)
        return
        
       } catch (error) {
        return 
       }
        
    }

    const numeroDoProcesso =()=>{
        const numeroDoProcesso = `${dadosPncp.item.numero_sequencial.toString()}/${dadosPncp.item.ano.toString()}`
        setProcessoAutomatico(numeroDoProcesso);
        return;
    }

    const findNumero = ()=>{
        const numeroApenas = dadosPncp.item.title.replaceAll(/[^\d.-/]/g, '');
        setNumero(numeroApenas)
    }

    //------------------------------------------

        
    const {data: portaisData, isLoading: portaisIsLoading} = useQuery({
        queryFn: ()=> getPortais({empresaid: empresaSelecionada as string}),
        queryKey: ["rqPortais", empresaSelecionada]
    })


    useEffect(()=>{
        if(portaisData){
            setPortais(portaisData.portais)
            setEmpresaInfo(portaisData.empresa)
            setObjetos(portaisData.empresa.Objetos)
        }

        if(dadosPncp){
            // setObjetos([objetos, dadosPncp.item.description])
            resgatarValorTotal();
            numeroDoProcesso();
            setTipo(dadosPncp.item.tipo_nome);
            setModalidade(dadosPncp.item.modalidade_licitacao_nome);
            setObjetoAutomaico(dadosPncp.item.description);
            // setNumero(dadosPncp.item.title);
            findNumero()
            
        }
  
    },[portaisData, dadosPncp])


    //-------------------------------------------
    
    
    
    
    //-------------------------------------------


    const handleEtapaDois =(e: any)=>{
        e.preventDefault();
        const formData = new FormData(e.currentTarget)
        const data ={
            modalidade: formData.get("modalidade"),
            processo : formData.get("processo"),
            objeto : formData.get("objeto"),
            LocalsessaoPublica : formData.get("LocalsessaoPublica"),
            numero   : formData.get("numero"),
            tipo : formData.get("tipo"),
            srp : formData.get("srp"),
            valorestimado : formData.get("valorestimado"),
            situacaoInterna : formData.get("situacaoInterna"),
            exclusividade:   formData.get("exclusividade"),
        }

        etapaDoisData(e, data)
    }

        const objRef = useRef<HTMLElement | any>(null)

        const handleCadastrarObjeto =(e: any)=>{
            e.preventDefault();

            if(objetos.includes(objetoTemp)){
                toast.warn('Objeto já cadastrado, selecione para definir objeto do processo!')
                return;
            }

            setObjetos([...objetos, objetoTemp]);
            objRef.current.value = objetoTemp;

        }


        //Tailwind ----------------------
        const inputField = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
        const labelField ="block mb-2 text-sm font-medium text-gray-900 dark:text-white";
    
        const button = "cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit"
        const closeButton = "bg-red-500 cursor-pointer text-white inline-flex items-center bg-bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:bg-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit"

        const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
        const optionInput ="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
        
        //Tailwind ----------------------


        const checkPortal = (portalid: string)=>{
            const portalEncontrado =portais &&  portais.find((i: any)=> {return i._id === portalid});

            if(portalEncontrado){
                return portalEncontrado.NomePortal
            }else
            return false
        }



        // //~~MANUTENÇÃO~~//
        // useEffect(()=>{
        //     console.log('Dados do pncp na etapa dois: ', dadosPncp)
        // },[dadosPncp])
        // //~~MANUTENÇÃO~~//

  return (
    <div>
        <div id="defaultModal" tabIndex={-1} aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full max-h-screen">
       
       <div className="relative p-4 w-fit max-w-2xl h-full md:h-auto">
           
           <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 ">
               <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                {!editar && <label onClick={voltar}><BsArrowLeft className='text-3xl cursor-pointer'/> Voltar </label>}



                    <div>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                           {editar ? `Editar processo`:`Registrar processo`}
                        </h3>
                        <label> Informações do proceso</label>
                    </div>

                    {
                    editar && 
                    <div className='flex flex-row gap-2'>                 
                        <label onClick={()=> alterarParteEditada('back')}>
                        <BsArrowLeft className='text-3xl cursor-pointer'/> Voltar 
                        </label>

                        <label onClick={()=> alterarParteEditada('forward')}>
                        <BsArrowRight className='text-3xl cursor-pointer'/> Passar 
                        </label>

                    </div>
                    }

               </div>

               <form onSubmit={handleEtapaDois}>
                   <div className="grid gap-4 mb-4 sm:grid-cols-2">
                        <div>
                            <label htmlFor="modalidade" className={`${labelField}`}>Modalidade</label>

                            
                            <select name="modalidade" id="modalidade" className={`${selectInput}`}>
                            
                                <option value="nenhum"> Nenhum </option>

                                {
                                    modalidade &&
                                    <option className={`${optionInput}`} selected ={modalidade ? true : false } value={modalidade}>(Automático) {modalidade}</option>
                                }
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Pregão presencial"    ? true : false } value="Pregão presencial">(Padrão) Pregão Presencial</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Pregão eletrônico"    ? true : false } value="Pregão eletrônico">(Padrão) Pregão Eletrônico</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Concorrência"         ? true : false } value="Concorrência">     (Padrão) Concorrência</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Compra direta"        ? true : false } value="Compra direta">    (Padrão) Compra Direta</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Dispensa de licitação" ? true : false } value="Dispensa de licitação"  >(Padrão) Dispensa de Licitação</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Diálogo competitivo"  ? true : false } value="Diálogo competitivo">     (Padrão) Diálogo Competitivo</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Convite"              ? true : false } value="Convite">                 (Padrão) Convite</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Chamamento público"   ? true : false } value="Chamamento público">      (Padrão) Chamamento Público</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Tomada de preços"     ? true : false } value="Tomada de preços"        >(Padrão) Tomada de Preços</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Inexigibilidade"      ? true : false } value="Inexigibilidade"         >(Padrão) Inexigibilidade</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Pedido de cotação"    ? true : false } value="Pedido de cotação"       >(Padrão) Pedido de Cotação</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Concessão"            ? true : false } value="Concessão"               >(Padrão) Concessão</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Adesão"               ? true : false } value="Adesão"                  >(Padrão) Adesão</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Leilão"               ? true : false } value="Leilão"                  >(Padrão) Leilão</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Concurso"             ? true : false } value="Concurso"                >(Padrão) Concurso</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Orçamento"            ? true : false } value="Orçamento"               >(Padrão) Orçamento</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Empresa privada"      ? true : false } value="Empresa privada"         >(Padrão) Empresa Privada</option>
                                <option className={`${optionInput}`} selected ={editar && processo.modalidade === "Outros"? true : false } value="Outros">Outros</option>  

                             

                            </select>
                        </div>
                        
                        
                        <div>
                            <label htmlFor="processo" className={`${labelField}`}>processo *</label>
                            <input type="text" name="processo" id="processo" className={`${inputField}`} placeholder={editar && processo.tipo ? processo.processo : 'Processo'} required={editar ? false : true}  value={processoAutomatico} onChange={(e)=> setProcessoAutomatico(e.target.value)}/>
                        </div>
                    
                        <div>
                            <label htmlFor="objeto" className={`${labelField}`}>Objetos</label>
                            <select name="objeto" id="objeto" className={`${selectInput}`} ref={objRef}>


                                {
                                    objetoAutomatico &&
                                    <option className={`${optionInput}`} selected ={objetoAutomatico ? true : false }value={objetoAutomatico}>(Automático) {objetoAutomatico}</option>
                                }

                                <option className={`${optionInput}`} selected ={editar && processo.objeto === "Locação veículos"? true : false }value="Locação veículos">(Padrão) Locação veículos</option>
                                <option className={`${optionInput}`} selected ={editar && processo.objeto === "Combustíveis"? true : false }value="Combustíveis"               >(Padrão) Combustíveis              </option>
                                <option className={`${optionInput}`} selected ={editar && processo.objeto === "peças e Serviços Automotivo"? true : false } value="peças e Serviços Automotivo">(Padrão) peças e Serviços Automotivo </option>
                                <option className={`${optionInput}`} selected ={editar && processo.objeto === "Peças Automotivo"? true : false }            value="Peças Automotivo"           >(Padrão) Peças Automotivo           </option>
                                <option className={`${optionInput}`} selected ={editar && processo.objeto === "Manutenção Automotivo"? true : false }       value="Manutenção Automotivo"      >(Padrão) Manutenção Automotivo     </option>
                                <option className={`${optionInput}`} selected ={editar && processo.objeto === "Gestão Frotas"? true : false }               value="Gestão Frotas"              >(Padrão) Gestão Frotas              </option>
                                <option className={`${optionInput}`} selected ={editar && processo.objeto === "Gestão Alimentação"? true : false }          value="Gestão Alimentação"         >(Padrão) Gestão Alimentação         </option>
                                <option className={`${optionInput}`} selected ={editar && processo.objeto === "Gestão Energia Solar"? true : false }        value="Gestão Energia Solar"       >(Padrão) Gestão Energia Solar       </option>
                                <option className={`${optionInput}`} selected ={editar && processo.objeto === "Rastreador Automotivo"? true : false }       value="Rastreador Automotivo"      >(Padrão) Rastreador Automotivo      </option>
                                <option className={`${optionInput}`} selected ={editar && processo.objeto === "Outros"? true : false } value="Outros">(Padrão) Orçamento</option>

                                {
                                    objetos && objetos.map((objeto: any, index: number)=>(
                                        objeto !== "" && <option key={index} className={`${optionInput}`} selected ={objeto === objetoTemp ? true:false } value={objeto}>(Empresa) {objeto}</option>  

                                    ))
                                } 
                 
                            </select>

                            <div className='flex flex-col mt-2'>
                                <label className='flex flex-row gap-4 cursor-pointer opacity-80 hover:opacity-100 w-fit' onClick={()=> setInserirObeto(current => !current)}>
                                     Cadastrar objeto
                                </label>
                                {
                                    inserirObjeto &&
                                    <div className='flex flex-row items-center'>
                                        <BsPlusCircleDotted className='mt-2 text-xl cursor-pointer hover:scale-110 transition-transform ' onClick={handleCadastrarObjeto}/> 
                                        <input type="text" className='inputLine'onChange={(e)=> setObjetoTemp(e.target.value)}/>
                                    </div>
                                }

                            </div>



                        </div>

                    
                        <div>
                            <label className={`${labelField}`}> Tipo *</label>
                            <input className={`${inputField}`} type="text" name="tipo" id="Tipo" placeholder={editar && processo.tipo ? processo.tipo : 'Tipo'} value={tipo} onChange={(e)=> setTipo(e.target.value)} />
                        </div>

         
                        <div>
                            <label htmlFor="LocalsessaoPublica" className={`${labelField}`}>Local sessão pública *</label>
                            <input type="text" name="LocalsessaoPublica" id="LocalsessaoPublica" className={`${inputField}`} placeholder={editar ? processo.LocalsessaoPublica : "Local da sessão pública"} required={editar ? false : true}/>
                        </div>
                   </div>

                   <div className='grid sm:grid-cols-4 grid-cols-2 gap-4'>
                        
                        <div>
                            <label className={`${labelField}`}> Nº * </label>
                            <input className={`${inputField}`} type="test" name="numero" id="numero" placeholder={editar && processo.numero ?  processo.numero : 'numero'} value={numero} onChange={(e)=> setNumero(e.target.value)}/>
                        </div>

                      

                        <div>
                            <label htmlFor="srp" className={`${labelField}`}>S.R.P</label>

                            <select name="srp" id="srp" className={`${selectInput}`}>
                                <option className={`${optionInput}`} value="sim" selected={editar && processo.srp === 'sim' ?true :false} >Sim</option>
                                <option className={`${optionInput}`} value="não"  selected={editar && processo.srp === 'não' ?true :false} >Não</option>
                            </select>
                        </div>

                        <div>
                            <label className={`${labelField}`}> V.T.E </label>
                            <input name='valorestimado' className={`${inputField}`} type="text" id="valorestimado" placeholder={editar && processo.valorestimado ?  processo.valorestimado : 'V.T.E'} value={vte} onChange={(e)=> setVte(e.target.value)}/>
                        </div>

                        <div>
                            <label htmlFor="situacaoInterna" className={`${labelField}`}>Situação</label>

                            <select name="situacaoInterna" id="situacaoInterna" className={`${selectInput}`} >
                                <option className={`${optionInput}`} value={SituaçãoInterna.REGISTRAR} selected={editar && processo.situacaoInterna=== SituaçãoInterna.REGISTRAR ? true: false}>Análise</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.DESAPROVADO} selected={editar && processo.situacaoInterna=== SituaçãoInterna.DESAPROVADO ? true: false}>Desaprovado</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.APROVADO} selected={editar && processo.situacaoInterna=== SituaçãoInterna.APROVADO ? true: false}>Aprovado</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.FAZENDO} selected={editar && processo.situacaoInterna=== SituaçãoInterna.FAZENDO ? true: false}>Fazendo</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.FEITO} selected={editar && processo.situacaoInterna=== SituaçãoInterna.FEITO ? true: false}>Feito</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.REVOGADA} selected={editar && processo.situacaoInterna=== SituaçãoInterna.REVOGADA ? true: false}>Revogada</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.ORÇAMENTO} selected={editar && processo.situacaoInterna=== SituaçãoInterna.ORÇAMENTO ? true: false}>Orçamento</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.SESSÕES_DO_DIA} selected={editar && processo.situacaoInterna=== SituaçãoInterna.SESSÕES_DO_DIA ? true: false}>Sessões do Dia</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.ACOMPANHAMENTO} selected={editar && processo.situacaoInterna=== SituaçãoInterna.ACOMPANHAMENTO ? true: false}>Acompanhamanto</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.GANHA} selected={editar && processo.situacaoInterna=== SituaçãoInterna.GANHA ? true: false}>Ganha</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.ENTREGANDO} selected={editar && processo.situacaoInterna=== SituaçãoInterna.ENTREGANDO ? true: false}>Entregando</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.OCORRÊNCIA} selected={editar && processo.situacaoInterna=== SituaçãoInterna.OCORRÊNCIA ? true: false}>Ocorrência</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.EXECUÇÃO} selected={editar && processo.situacaoInterna=== SituaçãoInterna.EXECUÇÃO ? true: false}>Execução</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.ENCERRADO} selected={editar && processo.situacaoInterna=== SituaçãoInterna.ENCERRADO ? true: false}>Encerrado</option>
                                <option className={`${optionInput}`} value={SituaçãoInterna.PERDIDA} selected={editar && processo.situacaoInterna=== SituaçãoInterna.PERDIDA ? true: false}>Perdida</option>
                            </select>
                        </div>

                        <div>
                        <label htmlFor="exclusividde" className={`${labelField}`}>Exclusividade </label>
                        <select name="exclusividade" id="exclusividade" className={`${selectInput}`}>
                            <option className={`${optionInput}`} value="sim" selected ={editar && processo.valorestimado === "Sim" ? true : false}>Sim</option>
                            <option className={`${optionInput}`} value="Não" selected ={editar && processo.valorestimado === "Não" ? true : false}>Não</option>
                        </select>
                        </div>

                    </div>

                   <div className='flex flex-row w-full justify-between mt-4'>

                    <label className={`${closeButton}`} onClick={cancelar}> Cancelar <BsX className='text-2xl ml-2'/></label>
                    {loading ? <button disabled = {loading}><LoadingSmall message = {"Aguarde"}/></button> : <button type='submit' className={`${button}`}> Salvar informações <BsArrowRight className='text-2xl ml-2'/></button>}
                       
                   </div>
               </form>
           </div>
       </div>
   </div>

    </div>
  )
}

export default NovoProcessoEtapaDois