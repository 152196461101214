import { NivelHierarquicoRegistro, RegistroEmpresa } from "./cadastroTypes"

export interface userType {
    Name: String,
    Email: String
}

export interface inicialData {
    sessionId: String,
}

export enum UserUpdateStatus {
    IDLE = '',
    LOADING = 'loading',
    ERROR = 'erro',
    SUCCESS = 'sucesso'
}

export enum InputUpdateStatus {
    IDLE = '',
    LOADING = 'loading',
    ERROR = 'erro',
    SUCCESS = 'sucesso'
}

export type typeUserUpdate = {
    tokenid: string,
    data: {
        Nome?: string,
        Email?: string,
        Senha?: string,
        SenhaAntiga?: string,
        CpfCnpj?: string,
        Cargo?: string,
        Departamento?: string,
        NivelHierarquico?: NivelHierarquicoRegistro,
        CargoFuncao?: RegistroEmpresa,
        SuperiorImediato?: string
    }
}


export enum NivelHierarquico {
    PRESIDENTE = "Presidente",
    VICEPRESIDENTE = "VicePresidente",
    DIRETOR = "Diretor",
    GERENTE = "Gerente",
    SUPERVISOR = "Supervisor",
    COODERNADOR = "Coodernador",
    TECNICO = "Tecnico",
    ANALISTA = "Analista",
    ASSISTENTE = "Assistente",
    AUXILIAR = "Auxiliar",
    SECRETARIO = "Secretario",
    ESTAGIARIO = "Estagiário",
    TRAINEE = "Trainee",
    JOVEMAPRENDIZ = "JovemAprendiz"
}

export type TArquivosUsuario={
    _id?: string | number,
    NomeDoArquivo: string, 
    Descrição?:string,
    LinkAws?: string,
    Userid:  string | number,
    createdAt?: string,
    updatedAt?: string,
    Size?:  string | number,
    empresaid: string,

    dataInicio:     string, 
    dataVencimento: string, 
    categoria:      string, 
    valor:          string, 
    arquivoPath?:    string, 
    processoId?:     string,
    file?: HTMLFormElement | any,
    status: string
  
  }
  export type TPastasUsuario = TArquivosUsuario[];