import React from 'react'
import EmpresaSolicitaçõesAssessorias from './EmpresaSolicitaõesAssessorias'
import EmpresaGerenciarAssessorias from './EmpresaGerenciarAssessorias'
import { AdminTab } from '../../Utils/types/adminTypes'
import { Link } from 'react-router-dom'



const EmpresaAssessorias = ({ activeTab, solicitações, empresaId }: any) => {
    return (
        <div>
            <div className='grid justify-start grid-cols-[250px_auto]'>

                <div className='w-full'>
                    <div className='fixed'>
                        <label htmlFor="">Assessorias / {activeTab === AdminTab.ASSESSORIASOLICITACOES ? 'Solicitações': 'Gerenciamento'}</label>
                        <div className='flex flex-col pt-5 gap-1'>
                            <Link to={'/portal/admin?area=assessoriasolicitacoes&secao=assessorias'} className={activeTab === AdminTab.ASSESSORIASOLICITACOES ? 'opacity-80 hover:opacity-100 cursor-pointer font-semibold': 'opacity-80 hover:opacity-100 cursor-pointer'} >Solicitações</Link>
                            <Link to={'/portal/admin?area=assessoriagerenciamento&secao=assessorias'} className={activeTab !== AdminTab.ASSESSORIASOLICITACOES ? 'opacity-80 hover:opacity-100 cursor-pointer font-semibold': 'opacity-80 hover:opacity-100 cursor-pointer'}>Gerenciar assessorias</Link>
                        </div>
                    </div>
                </div>

                <div>
                    {
                        activeTab === AdminTab.ASSESSORIASOLICITACOES &&
                        <EmpresaSolicitaçõesAssessorias solicitações={solicitações} empresaId={empresaId} />
                    }

                    {
                        activeTab === AdminTab.ASSESSORIAGERENCIAMENTO &&
                        <EmpresaGerenciarAssessorias />
                    }
                                        
                </div>

            </div>
        </div>
    )
}

export default EmpresaAssessorias