import React, { useEffect, useState } from 'react'
import { DisplayMiddle } from '../../Utils/types/PortalTypes'
import { BsChat, BsChatFill, BsFilter, BsPerson, BsPersonFill, BsStar, BsStarFill } from 'react-icons/bs';
import { CategoriasPadrão, TCategoriaPastas } from '../../Utils/DevUtils/CategoriasFake';

const FiltroGlobal = ({ filtroGlobal, changeFiltroGlobal, alterarFiltroImportante, importante }: any) => {



    return (
        <div className='w-full flex flex-row h-fit bg-white dark:bg-gray-800 p-1 -mt-2 pr-4'>
            
            <div className='flex flex-row w-28  mr-[30px] gap-2 pl-3 '>
                <label className={` mt-1 cursor-pointer opacity-75 hover:opacity-100`} onClick={alterarFiltroImportante}>
                
                    {
                        importante ?
                        <BsStarFill className='text-xl'/>
                        :<BsStar className='text-xl'/>
                    }
                
                </label>
                
                <label className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' onClick={() => changeFiltroGlobal(DisplayMiddle.CONTATO)}>
                    { 
                    filtroGlobal === DisplayMiddle.CONTATO ? 
                    <BsPersonFill className='text-xl'/>
                    :
                    <BsPerson className='text-xl'/> 
                    }
                </label>
                
                <label className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center'  onClick={() => changeFiltroGlobal(DisplayMiddle.CHAT)}>
                    {
                        filtroGlobal === DisplayMiddle.CHAT ? 
                        <BsChatFill className='text-xl'/>
                        :
                        <BsChat className='text-xl'/> 
                    } 
                    
                </label>
                
            </div>
            
            <label className={filtroGlobal === DisplayMiddle.RESUMO ? `selectedTab max-w-36` : `idleTab max-w-36`} onClick={() => changeFiltroGlobal(DisplayMiddle.RESUMO)}>Resumo </label>

            <label className={filtroGlobal !== DisplayMiddle.RESUMO && filtroGlobal !== DisplayMiddle.CONTATO && filtroGlobal !== DisplayMiddle.CHAT  ? 'selectedTab' : 'idleTab'} >
                

              {filtroGlobal === DisplayMiddle.RESUMO ?  <span onClick={()=> changeFiltroGlobal(DisplayMiddle.DOCUMENTOS)}> Documentos </span> : <span className='capitalize'>{filtroGlobal}</span>} 

                <select  className='tabSelect' onChange={(e) => changeFiltroGlobal(e.target.value)}> 
                    <option value={DisplayMiddle.DOCUMENTOS as string} className='optionInput'>{DisplayMiddle.DOCUMENTOS}</option>
                    {
                        CategoriasPadrão.sort((a, b) => a.label.localeCompare(b.label)).map((i: TCategoriaPastas)=>(
                            <option className='optionInput' value={i.value} onClick={(e) => changeFiltroGlobal(i.value)}>{i.label}</option>
                        ))
                    }
                </select>
                
            
            </label>

            
         
        </div>
    );
}

export default FiltroGlobal;
