import React, { useLayoutEffect, useState } from 'react'
import { BsBuilding, BsClipboard, BsFile, BsFolder, BsPencil, BsPerson } from 'react-icons/bs'
import { FaClipboard, FaExternalLinkAlt, FaRegClipboard } from 'react-icons/fa'
import { TArmazenamento, fakeArmazenamentoData } from '../../Utils/DevUtils/ArmazenamentoFake'
import { Link } from 'react-router-dom'
import Chart, { Props } from "react-apexcharts";
import PortaisCenter from '../PortaisComponents/PortaisCenter'
import { CountFileSize, CountFileSizeNumber, countFolderSize } from '../../Utils/CountFileSize'
import { useUserContext } from '../../Context/useUserContext'
import { useQuery } from '@tanstack/react-query'
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import { toast } from 'react-toastify'


const ContaConfig = () => {

  const token = localStorage.getItem('token');
  const empresaSelecionada = localStorage.getItem('empresaSelecionada');
  const {getArmazenamentoEmpresa} = useReactQueryEmpresas()

  const {data: armazenamentoData, isLoading} = useQuery({
    queryFn: ()=> getArmazenamentoEmpresa(token as string, empresaSelecionada as string),
    queryKey: ['rqArmazenamentoEmpresa'],
    staleTime: Infinity,
    refetchOnMount: true,
    refetchOnWindowFocus: false
  })

  const [userContext] = useUserContext();

  const [totalPlano, setTotalPlano] = useState<number>(0);
  const [total           , setTotal           ] = useState<number>(0);
  const [pastasEmpresa   , setPastasEmpresa   ] = useState<number>(0);
  const [pastasUsuario   , setPastasUsuario   ] = useState<number>(0);
  const [pastasPortal    , setPastasPortal    ] = useState<number>(0);
  const [pastasProcessos , setPastasProcessos ] = useState<number>(0);
  const [empty, setEmpty] = useState<number>(0);

  const [email, setEmail] = useState<string>('');
  const [fone,setFone] = useState<string>('')
  const [notificaçãoPromocional, setNotificaçãoPromocional] = useState<boolean>(false);
  const [notificaçãoInterna, setNotificaçãoInterna] = useState<Boolean>(false);
  const [notificaçãoCaptação, setNotificaçãoCaptação] = useState<Boolean>(false);

  const [editar, setEditar] = useState<boolean>(false)

  const totalEmGb =()=>{

    
      const empty = armazenamentoData.totalPlano - armazenamentoData.armazenamentoTotal
      setTotalPlano(armazenamentoData.totalPlano);
      setTotal(armazenamentoData.armazenamentoTotal);
      setPastasEmpresa(armazenamentoData.arquivosEmpresaSize);
      setPastasUsuario(armazenamentoData.arquivosDoUsuarioSize);
      setPastasProcessos(armazenamentoData.totalProcessos);
      setPastasPortal(armazenamentoData.arquivosPortaisSize);
      setEmpty(empty)

      if(armazenamentoData.planoDescrição === 'visitante' ){
        toast.warn('Complete o seu cadastro asssinando um plano para começar a utiliizar o armazenamento a nuvem.')
      }
  }

  useLayoutEffect(()=>{
    if(armazenamentoData){
    totalEmGb();
    }
  },[armazenamentoData])

  const fakeDataDonut: Props ={
    options: {
      colors: ['#b50505', '#ffff0a','#005df2','#0a9e05','#d9d9d9'],
      legend:{
        show: true,
        showForSingleSeries: false,
        position: 'bottom'
      },
      labels: ['Pastas dos processos', 'Pastas da empresa', 'Pastas dos portais', 'Pastas dos usuarios', 'Espaço livre' ],
      dataLabels: {
        enabled: true,
        offsetX: 0,
        dropShadow: { enabled: true }
      }

    },
    series: [pastasProcessos/1000000000, pastasEmpresa/1000000000, pastasPortal/1000000000, pastasUsuario/1000000000, empty/1000000000],
  }

  // Tailwind -----------------------------------------------------------
  const inputField = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500';
  const labelField = 'block mb-2 text-sm font-medium text-gray-900 dark:text-white';
  const button = 'cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit';
  const closeButton = 'bg-red-500 cursor-pointer text-white inline-flex items-center bg-bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:bg-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit';
  // Tailwind -----------------------------------------------------------


  return (
    <div className='w-full min-h-screen pb-20 bg-white rounded-xl p-5 mb-5 shadow-xl'>

      <div>
        <h3 className='text-3xl font-semibold'> Configurações do Plano</h3>
        <small className='text-md'> Armazenamento, notificações e planos</small>
      </div>

      <div className='w-full flex flex-row justify-between'>

        <div className='my-4 flex flex-col gap-2 w-fit h-fit rounded-lg shadow-lg p-5'>
          <label className='text-xl font-semibold'> Armazenamento</label>
          <small> Gerencie o seu armazenamento na núvem</small>
          <h3 className=' font-semibold mt-2'>Plano atual: <span className='uppercase'>{armazenamentoData && armazenamentoData.planoDescrição}</span></h3>
          <p>Total em armazenamento: {CountFileSizeNumber(totalPlano)}</p>
          <p>Utilizado: {CountFileSizeNumber(total)} / {CountFileSizeNumber(totalPlano)} </p>
          <p>Total de armazenamento livre: {CountFileSizeNumber(empty)} </p>
          <Link to={'/'} className='font-semibold cursor-pointer opacity-85 hover:opacity-100 inline-flex'><FaExternalLinkAlt className='mr-2'/> Consultar planos disponíveis</Link>


          
        </div>

        <div className='mr-10'> 

          {
            armazenamentoData && armazenamentoData.planoDescrição !== 'visitante' ?
            <Chart
            type='donut'
            series={fakeDataDonut.series}
            options={fakeDataDonut.options}
            width={400}
          />
          :
          <h3> Indisponível!</h3>
          }

        </div>
      </div>

      <div className='w-full flex flex-col justify-center items-center'>


        <div className='max-w-7xl grid grid-cols-4 gap-5 justify-center items-center my-5'>

          <div className='shadow-lg hover:scale-110 cursor-pointer transition-transform w-52 min-h-44 rounded-lg p-4 bg-gradient-to-r from-sky-100 to-lime-200'>
              <div className='flex flex-col justify-center items-center h-full'>
                <FaRegClipboard className='text-5xl mb-4'/>
                <h3> Arquivos dos processos</h3>
                <p> {CountFileSizeNumber(pastasProcessos)}, total em pastas</p>
              </div>
          </div>
          
          <div className='shadow-lg hover:scale-110 cursor-pointer transition-transform w-52 min-h-44 rounded-lg p-4 bg-gradient-to-r from-sky-100 to-yellow-100'>
              <div className='flex flex-col justify-center items-center h-full'>
                <BsBuilding className='text-5xl mb-4'/>
                <h3> Pastas da empresa</h3>
                <p> {CountFileSizeNumber(pastasEmpresa)}, total em pastas</p>
              </div>
          </div>
          
          <div className='shadow-lg hover:scale-110 cursor-pointer transition-transform w-52 min-h-44 rounded-lg p-4 bg-gradient-to-r from-sky-100 to-yellow-100'>
              <div className='flex flex-col justify-center items-center h-full'>
                <BsFolder className='text-5xl mb-4'/>
                <h3> Portais da empresa </h3>
                <p> {CountFileSizeNumber(pastasPortal)}, total em pastas</p>
              </div>
          </div>

          <div className='shadow-lg hover:scale-110 cursor-pointer transition-transform w-52 min-h-44 rounded-lg p-4 bg-gradient-to-r from-lime-100 to-gray-100'>
              <div className='flex flex-col justify-center items-center h-full'>
                <BsPerson className='text-5xl mb-4'/>
                <h3> Pasta dos colaboradores</h3>
                <p> {CountFileSizeNumber(pastasUsuario)}, total em pastas</p>
              </div>
          </div>

        </div>

    


      </div>

      <h3 className='text-xl font-semibold mt-10'> Central de notificações </h3>
      <label htmlFor="" className='inline-flex my-2 cursor-pointer opacity-85 hover:opacity-100' onClick={()=> setEditar(current => !current)}><BsPencil className='mr-2'/> Editar informações </label>
      
      <form className='grid sm:grid-cols-2 grid-cols-1'>

        <div className='flex flex-col gap-2 max-w-sm'>
          <div className='flex flex-col'>
            <label htmlFor="" className={`font-semibold`}>Email de contato</label>
            <small> Email geral para notificações do GLC</small>
            <input readOnly={editar ? false : true} type="email" name="" id="" className={`${inputField}`} placeholder={userContext.User.Email} value={editar ? email : userContext.User.Email} onChange={(e)=> setEmail(e.target.value)}/>
          </div>
          
          <div className='flex flex-col'>
            <label htmlFor="" className={`font-semibold`}>Número de contato </label>
            <small> Contato para suport, visível somente para a equipe de suporte.</small>
            <input readOnly={editar ? false : true} type="text" name="" id="" className={`${inputField}`} placeholder={userContext.User.Fone} value={fone} onChange={(e)=> setFone(e.target.value)}/>
          </div>
        
        </div>

        <div className='flex flex-col'>


          <div className='max-w-sm flex flex-col gap-1'>
            <label htmlFor="" className='font-semibold'>Emails promocionais</label>
            <small> Novos planos, novidades do portal, etc.</small>
            <select disabled ={editar ? false:true} name="" id="" className={`${inputField}`} onChange={(e)=> setNotificaçãoPromocional(Boolean(e.target.value))}>
              <option value={1} className={`${inputField}`}>Sim</option>
              <option value={0} className={`${inputField}`}> Não</option>
            </select>
          </div>
          
          <div className='max-w-sm flex flex-col gap-1'>
            <label htmlFor="" className='font-semibold'>Emails internos</label>
            <small> Novos planos, novidades do portal, etc.</small>
            <select disabled ={editar ? false:true} name="" id="" className={`${inputField}`} onChange={(e)=> setNotificaçãoInterna(Boolean(e.target.value))}>
              <option value={1} className={`${inputField}`}>Sim</option>
              <option value={0} className={`${inputField}`}> Não</option>
            </select>
          </div>
       
          <div className='max-w-sm flex flex-col gap-1'>
            <label htmlFor="" className='font-semibold'>Emails sobre licitações encontradas</label>
            <small> Novos planos, novidades do portal, etc.</small>
            <select disabled ={editar ? false:true} name="" id="" className={`${inputField}`} onChange={(e)=> setNotificaçãoCaptação(Boolean(e.currentTarget.value))}>
              <option value={1} className={`${inputField}`}>Sim</option>
              <option value={0} className={`${inputField}`}> Não</option>
            </select>
          </div>
        </div>

       {
        editar &&
        <div className='flex flex-row gap-2' >
          <button className={`${button} max-w-fit`}> Alterar informaões de contato</button>
          <label className={`${closeButton} max-w-fit`} onClick={()=> setEditar(false)}> Cancelar</label>
        </div>
        }  
      </form>

    </div>
  )
}

export default ContaConfig