import React, { useLayoutEffect, useState } from 'react';
import SideNavWrapper from '../Components/PortalComponents/SideNavWrapper';
import BannerNetwork from '../Components/NetWorkComponents/BannerNetwork';
import Organograma from '../Components/NetWorkComponents/Organograma';
import CaptacaoPopInit from '../Components/CaptacaoAutomatica/CaptacaoPopInit';


const BemVindo = () => {

    const [popFechado, setpopFechado] = useState<boolean>(false)
    
    useLayoutEffect(()=>{
        const primeiroAcesso = localStorage.getItem('primeiroAcesso');

        if(primeiroAcesso=== 'true') {
            setpopFechado(true)
        }
    },[])

    const fecharPop = () => {
        setpopFechado(false)
    }

    return (
        <div className="flex ">
            <SideNavWrapper />
            <div className="w-full ">
                <BannerNetwork />
                    {popFechado && <CaptacaoPopInit fecharPop={fecharPop} />} 
                <Organograma />
                
            </div>
        </div>
    );
};

export default BemVindo;