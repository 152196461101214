import React, { useState } from 'react'
import { BsArrowRight, BsExclamationCircle, BsX } from 'react-icons/bs'
import { TRegistarEmpresa } from '../../Utils/types/cadastroTypes'
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import LoadingPage from '../LoadingPage'


type Props = {
  data:TRegistarEmpresa,
  fechar: any
}

const AlertaCadastroExistente = ({ data, fechar }: Props) => {


  const [loading, setLoading] = useState<Boolean>(false);
  console.log('Empresa data: ', data)

  const {criarNovoAcesso} = useReactQueryUsers();
  const novaEmpresaMutation = useMutation({
    mutationFn: criarNovoAcesso,
    onMutate: (config) => {
      setLoading(true);
      // console.log('New empresa mutation iniciada!');
    },
    onSuccess: async (response) => {
      const res = response;

      if (response.status === 1) {
        toast.success(response.msg);
       
        // localStorage.setItem('token', response.token);
        // localStorage.setItem('empresaSelecionada', response.createdEmpresa._id);
        // localStorage.setItem('empresaPadrão', response.createdEmpresa._id);
        window.location.href = '/';
        setLoading(false);
        
        // setSucesso(true)
      }
      if (response.status === 3) {
        setLoading(false);
        toast.error(response.msg);
      }

    },
    onError: async (error) => {
      setLoading(false);
      console.error('Erro: ', error);
      toast.error(error.message);
      ;
    },
    onSettled: async (data) => {
      setLoading(false);
      // console.log('Mutação concluída!: ');
    }
  })


  const handleNovaEmpresa = (e: any)=>{
    e.preventDefault();

    if(data){
      novaEmpresaMutation.mutate({action: 'novo', data: data, CpfCnpj: data.CpfCnpj,Cnpj: data.CnpjEmpresa})
    }

  }


  return (
    <div className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">

    {
      loading && <LoadingPage/>
    }

      <div className="relative p-4 w-full max-w-xl h-full md:h-auto ">
        <div className="relative opacity-100 p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 flex flex-col justify-center items-center gap-5">
          <h3> Este usuário já possuí conta!</h3>
          <svg className="mx-auto mb-4 text-green-500 w-12 h-12 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>

          <p> É possvel registar outra empresa <span className='font-semibold'>({data.RazãoSocial})</span> no portal, o login e senha permanecem os mesmos, bastando escolher em qual empresa desea realzar o acesso!</p>

          <p>  A criação de uma nova empresa esta sujeita a cbranças de acordo com o plano contratado e utilização de recurssos da empresa acessada. </p>

          <div className='flex flex-row w-full justify-center items-center gap-4'>

            <label className="cursor-pointer text-white inline-flex items-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 h-fit" onClick={fechar}> Cancelar <BsX className='text-2xl ml-2' /></label>

            <label className="cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit" onClick={handleNovaEmpresa}> Cadastrar nova emresa <BsArrowRight className='text-2xl ml-2' /></label>
            
          </div>


        </div>
      </div>
    </div>
  )
}

export default AlertaCadastroExistente