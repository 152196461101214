import React from 'react'
import { BsArrowRight, BsX } from 'react-icons/bs'
import { useAdminUtils } from '../../Utils/ReactQuery/getAdminUtils'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { error } from 'console'
import { toast } from 'react-toastify'

const ConfirmarInativação = ({ fechar, usuario, ação }: any) => {


    const token = localStorage.getItem('token');
    const queryClient = useQueryClient();
    const { configUsuario } = useAdminUtils();

    const configUsuárioMutaton = useMutation({
        mutationFn: configUsuario,
        onMutate: async (data) => {
            console.log('Mutação iniciada!');
        },
        onSuccess: async (response) => {

            queryClient.invalidateQueries({ queryKey: ["rqEmpresasAdm"] })
            queryClient.invalidateQueries({ queryKey: ["rqListaUsuarios"] })
            queryClient.fetchQuery({ queryKey: ["rqEmpresasAdm"] })
            queryClient.fetchQuery({ queryKey: ["rqListaUsuarios"] })
            queryClient.refetchQueries({ queryKey: ["rqEmpresasAdm"] })
            queryClient.refetchQueries({ queryKey: ["rqListaUsuarios"] })


            //----------------------------------------
            if (response.status === 1) {
                toast.success(response.msg);
                fechar();
            };

            if(response.status === 3){
                toast.error(response.msg);
                fechar()
            }

            //----------------------------------------

        },
        onError: async (error) => {
            console.error(error.message);
            toast.error(error.message);

        }

    })

    const empresaPadrão = localStorage.getItem('empresaPadrão');


    const statusUsuario = (e: any) => {
        e.preventDefault();

        if(ação === 'Ativar'){
            configUsuárioMutaton.mutate({
                token: token as string,
                data: {
                    action: 'Atividade',
                    empresaid: empresaPadrão as string,
                    useridAlterado: usuario._id,
                    Atividade: 'Ativar'
                }
            })
        }

        if(ação === 'Inativar'){
            configUsuárioMutaton.mutate({
                token: token as string,
                data: {
                    action: 'Atividade',
                    empresaid: empresaPadrão as string,
                    useridAlterado: usuario._id,
                    Atividade: 'Inativar'
                }
            })
        }

    }


    return (
        <div className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">

            <div className="relative p-4 w-full max-w-xl h-full md:h-auto ">
                <div className="relative opacity-100 p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 flex flex-col justify-center items-center gap-5">
                    <h3> Deseja <span className='lowercase'>{ação}</span> {usuario && usuario.Nome}!</h3>
                    <svg className="mx-auto mb-4 text-green-500 w-12 h-12 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>


                    {
                        ação === 'Inativar' ?
                       <p> O usuário perderá acesso a esta empresa, caso este seja o único cadastro, o usuário tera de solicitar acesso novamente.</p>
                        :  <p> Caso o usuáro ainda estea ativo, todas as suas permissões nesta empresa serão restaurdas.</p>

                    }


                    <div className='flex flex-row w-full justify-center items-center gap-4'>

                        <label className="cursor-pointer text-white inline-flex items-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 h-fit" onClick={fechar}> Cancelar <BsX className='text-2xl ml-2' /></label>

                        <label className="cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit" onClick={statusUsuario}> {usuario.Inativado ? 'Reativar usuário' : 'Inativar Usuário'} <BsArrowRight className='text-2xl ml-2' /></label>

                    </div>


                </div>
            </div>
        </div>
    )
}

export default ConfirmarInativação