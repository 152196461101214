import React, { useState } from 'react';
import { DisplayMiddle } from '../../Utils/types/PortalTypes'; // Certifique-se de ajustar o caminho para onde o enum DisplayMiddle está definido
import ProcessosFerramentas from './ProcessosFerramentas';
import { BsChat, BsPerson } from 'react-icons/bs';
import { CategoriasPadrão, TCategoriaPastas } from '../../Utils/DevUtils/CategoriasFake';

const TopProcessos = ({ processo, filtroMiddle, displayFiltroMiddle }: any) => {

  const [chatNotificações, setChatNotificações] = useState<number>(0);

  // Tailwind --------------------------

  const labelItem = 'flex flex-row justify-center w-full items-center gap-2 py-0.5 px-2 opacity-80 hover:opacity-100 transition-opacity cursor-pointer text-sm';

  const selectedLabelItem = 'flex flex-row justify-center w-full items-center gap-2 py-0.5 px-2 cursor-pointer text-sm scale-110 font-semibold bg-[#ff8027] text-white rounded-md';

  // Tailwind --------------------------



  return (
    <div className='w-full flex flex-row h-fit'>
      
      <ProcessosFerramentas processo={processo} filtroMiddle ={filtroMiddle} displayFiltroMiddle={displayFiltroMiddle}/>

      <label className={displayFiltroMiddle === DisplayMiddle.RESUMO ? `selectedTab max-w-36` : `idleTab max-w-36`} onClick={() => filtroMiddle(DisplayMiddle.RESUMO)}>Resumo </label>
      <label className={displayFiltroMiddle !== DisplayMiddle.RESUMO && displayFiltroMiddle !== DisplayMiddle.CONTATO && displayFiltroMiddle !== DisplayMiddle.CHAT  ? 'selectedTab' : 'idleTab'} >
                

              {displayFiltroMiddle === DisplayMiddle.RESUMO ?  <span onClick={()=> filtroMiddle(DisplayMiddle.DOCUMENTOS)}> Documentos </span> : <span className='capitalize'>{displayFiltroMiddle}</span>} 

                <select  className='tabSelect' onChange={(e) => filtroMiddle(e.target.value)}> 
                    <option value={DisplayMiddle.DOCUMENTOS as string} className='optionInput'>{DisplayMiddle.DOCUMENTOS}</option>
                    {
                        CategoriasPadrão.map((i: TCategoriaPastas)=>(
                            <option className='optionInput' value={i.value} onClick={(e) => filtroMiddle(i.value)}>{i.label}</option>
                        ))
                    }
                </select>
                
            
            </label>
    
    </div>
  );
}

export default TopProcessos;
