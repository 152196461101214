import React, { useState } from 'react'
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import LoadingPage from '../LoadingPage';



const ApagarPastaArquivoProcesso = ({pasta, arquivo, tipo ,fechar}: any) => {

    const token = localStorage.getItem("token");
    const {apagarPasta, apagarArquivosProcessos} = useReactQueryProcessos();
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false)
    const queryClient = useQueryClient();

    const apagarPastaMutation = useMutation({
        mutationFn: apagarPasta,
        onMutate: async(config)=>{
            // console.log("Mutalççao iniciada!");
            setLoading(true);
        },
        onSuccess: async(response)=>{
            // console.log('Mutalçao bem sucedida!', response);

            queryClient.invalidateQueries({queryKey:["rqPastasProcesso"]})
            queryClient.fetchQuery({queryKey:["rqPastasProcesso"]})
            queryClient.refetchQueries({queryKey:["rqPastasProcesso"]})

            if(response.status === 1){
                toast.success(response.msg)
                setLoading(false);
                setSuccess(true)
            }else{
                toast.error(response.msg)
            }
        },
        onError: (error)=>{
            console.error(error.message);
            toast.error("Erro interno")
            
        },
        onSettled: (data)=>{
            // console.log("Mutalçao concluída!");
            setLoading(false);
            

        }
    })



    const apagarArquivoMutation = useMutation({
        mutationFn: apagarArquivosProcessos,
        onMutate: async(config)=>{
            // console.log("Mutalççao iniciada!");
            setLoading(true);
        },
        onSuccess: async(response)=>{
            // console.log('Mutação bem sucedida!', response);

            queryClient.invalidateQueries({queryKey:["rqPastasProcesso"]})
            queryClient.fetchQuery({queryKey:["rqPastasProcesso"]})
            queryClient.refetchQueries({queryKey:["rqPastasProcesso"]})

            if(response.status === 1){
                toast.success(response.msg)
                setLoading(false);
                setSuccess(true)
            }else{
                toast.error(response.msg)
            }
        },
        onError: (error)=>{
            console.error(error.message);
            toast.error("Erro interno")
            
        },
        onSettled: (data)=>{
            // console.log("Mutalçao concluída!");
            setLoading(false);
            

        }
    })


    const handleApagarPastaArquivo = (e: any)=>{
        e.preventDefault();

        apagarPastaMutation.mutate({token: token as string, pastaid: pasta._id });
        
    }


    const handleApagarArquivo = (e: any)=>{
        e.preventDefault();
        apagarArquivoMutation.mutate({token: token as string, arquivoid: arquivo._id})        
    }
   

  return (
    <div>  
    <div id="popup-modal" tabIndex={1} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {
                    loading && <LoadingPage/>
                }
                

                {tipo ==='pasta' && <label className='text-xl ml-2 mt-3 font-semibold'> {success ? 'Pasta apagada: ': 'Apagando a pasta: '} {pasta.nomePasta}</label>}
                {tipo ==='arquivo' && <label className='text-xl ml-2 mt-3 font-semibold'> Apagando o arquivo: {arquivo.nomeDoArquivo}</label>}

                <div className="p-4 md:p-5 text-center">
                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                    {!success ? <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Deseja realizar esta operação?</h3>:  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Patsa/arquivo apagado com sucesso!</h3>}
                    {!success && <p>
                        Todos os arquivos e/ou pastas serão apagados permanentemente.
                    </p>}
                    {!success && <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={tipo === 'pasta' ? handleApagarPastaArquivo : handleApagarArquivo}>
                        Apagar 
                    </button>}
                    <button data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={fechar}> {success ?  'Fechar': 'Cancelar'}</button>
                </div>
            </div>
        </div>
    </div>

    </div>
  )
}

export default ApagarPastaArquivoProcesso