import React, { useEffect } from 'react'
import { AdminTab, SecaoTab } from '../../Utils/types/adminTypes'
import { useUserContext } from '../../Context/useUserContext'
import EmpresaAssessorias from './EmpresaAssessorias'
import RhEstrategico from './RhEstrategico'
import AssessoriaAssessorias from './AssessoriaAssessorias'


const AdminCenter = ({ activeTab, secaoTab, solicitações, empresaId, usuarios, empresaData }: any) => {


  const [userContext] = useUserContext();

  // //~~MANUTENÇÃO~~//
  // useEffect(()=>{
  //     console.log('Context do usuario: ', userContext)
  // },[userContext])

  // //~~MANUTENÇÃO~~//


  return (
    <div className='border-1 border-black border-solid min-h-dvh w-full m-5 mt-5 rounded-lg dark:bg-gray-800  min-w-fit mr-12'>


      {
        (()=>{
          switch(true){
            case secaoTab === SecaoTab.RHESTRATEGICO:
              return <RhEstrategico activeTab={activeTab} solicitações={solicitações} usuarios={usuarios} empresaData={empresaData} empresaId={empresaId} /> 
              break;

            case secaoTab === SecaoTab.ASSESSORIAS && userContext && userContext.User.AcessoAtual === "Assessoria" || userContext.User.AcessoAtual === "Administração_GLC":
              return <AssessoriaAssessorias activeTab={activeTab}/>
              break;

              case secaoTab === SecaoTab.ASSESSORIAS :
                return <div className="flex  w-full">
                <EmpresaAssessorias solicitações={solicitações} empresaId={empresaId} activeTab={activeTab} />
              </div>
              break
          }
        })()
      }


    </div>
  )
}

export default AdminCenter