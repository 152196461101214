import { useQuery } from '@tanstack/react-query';
import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react'
import AdminCenter from '../Components/AdminComponents/AdminCenter';
import SideNavAdmin from '../Components/AdminComponents/SideNavAdmin';
import LoadingComponent from '../Components/LoaadingComponent';
import LoadingPage from '../Components/LoadingPage';
import { useUserContext } from '../Context/useUserContext';
import { useAdminUtils } from '../Utils/ReactQuery/getAdminUtils';
import { AdminTab, SecaoTab } from '../Utils/types/adminTypes';
import SideNavWrapper from '../Components/PortalComponents/SideNavWrapper';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';


const Administrador = ({user}: any) => {

  const [userContext] = useUserContext();
  const empresaPadrão = localStorage.getItem('empresaPadrão')

  useEffect(()=>{
    if(userContext.User.TipoAdmin && !userContext.User.TipoAdmin){
      toast.warn("Usuário não autorizado!")
      window.location.href = '/portal'
      return
    }
  },[userContext])

  const [useParams, setSearchParams] = useSearchParams();
  const pageParam = useParams.get('area');
  const tipoParam = useParams.get('secao');
  const [adminTab, setAdminTab] = useState<AdminTab>(AdminTab.IDLE);
  const [secaoTab, setSecaoTab] = useState<SecaoTab>(SecaoTab.IDLE)
  const [empresaSolicitações, setEmpresaSolicitações] = useState<string>('');
  const {listarEmpresas, listarUsuarios, listarSolicitações} = useAdminUtils();

  const token = localStorage.getItem('token');
  const {data: empresaData, isSuccess, isLoading} = useQuery({queryFn: ()=>listarEmpresas(token as string, empresaPadrão as string), queryKey:['rqEmpresasAdm'] })

  useLayoutEffect(()=>{
    if(empresaData && empresaData.status !== 3){ 
      setEmpresaSolicitações(empresaData.empresas._id)
    }
    else if(empresaData && empresaData.status === 3)
    {
      window.location.href = '/portal'
    }
 
   },[empresaData])

   useLayoutEffect(()=>{


    switch(true){
      case tipoParam === SecaoTab.ASSESSORIAS:
        setSecaoTab(SecaoTab.ASSESSORIAS)
        break;
      case tipoParam === SecaoTab.RHESTRATEGICO:
        setSecaoTab(SecaoTab.RHESTRATEGICO)
        break
        default: setSecaoTab(SecaoTab.IDLE) 

    }



    switch(true){
      case pageParam === AdminTab.SOLICITAÇÕES:
        setAdminTab(AdminTab.SOLICITAÇÕES);
        break
        return;
      case pageParam === AdminTab.FUNCIONÁRIOS:
        setAdminTab(AdminTab.FUNCIONÁRIOS);
        break
        return;
      case pageParam === AdminTab.REJEIÇÕES:
        setAdminTab(AdminTab.REJEIÇÕES);
        break
        return;
      case pageParam === AdminTab.ASSESSORIA:
        setAdminTab(AdminTab.ASSESSORIA);
        break
        return;
      case pageParam === AdminTab.ASSESSORIASOLICITACOES:
        setAdminTab(AdminTab.ASSESSORIASOLICITACOES);
        break
        return;
      case pageParam === AdminTab.ASSESSORIAGERENCIAMENTO:
        setAdminTab(AdminTab.ASSESSORIAGERENCIAMENTO);
        break
        return;
      default:
        setAdminTab(AdminTab.IDLE);
        return;
    }
   })


    const {data: usuariosData, isSuccess: isSuccessUsuarios} = useQuery({queryFn: ()=> listarUsuarios(token as string, empresaPadrão as string), queryKey:["rqListaUsuarios", empresaPadrão]});

    const {data:solicitaçõesData} = useQuery({queryFn: ()=> listarSolicitações({empresaid: empresaPadrão as string, token: token as string}), queryKey:["rqSolicitações", empresaSolicitações]})

  const changeEmpresaSolicitações =(e: any)=>{
    setEmpresaSolicitações(e.target.id);
  
  }





  useEffect(()=>{
    const localTab: any = localStorage.getItem('adminTab')
    setAdminTab(localTab)

    console.log('Seção tab: ', secaoTab)
  }, [secaoTab])




  return (
    <div className='min-h-screen mt-20 w-full '>
        
      {/* <div className='grid grid-cols-[80px_250px_minmax(1000px,_1fr)] w-fit'> */}
      <div className='grid grid-cols-[100px_minmax(1000px,_1fr)]  mr-12'>
          {/* {
            empresaData && empresaSolicitações ?
            <SideNavAdmin changeTab ={changeTab} empresaData = {empresaData && empresaData.empresas } changeEmpresaSolicitações={changeEmpresaSolicitações} empresaid = {empresaSolicitações && empresaSolicitações}/>
          :
          <LoadingComponent/>
          } */}
          
            <SideNavWrapper/>
          
         
          {/* <SideNavAdmin changeTab ={changeTab} empresaData = {empresaData && empresaData.empresas } changeEmpresaSolicitações={changeEmpresaSolicitações} empresaid = {empresaSolicitações && empresaSolicitações}/> */}
          
          
            <AdminCenter activeTab ={adminTab} secaoTab={secaoTab} solicitações = {solicitaçõesData || null} empresaId ={empresaSolicitações} usuarios = {usuariosData && usuariosData.usuarios} empresaData ={empresaData && empresaData.empresas} />
          
          
      </div>

    </div>
  )
}

export default Administrador