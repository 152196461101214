import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react'
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { toast } from 'react-toastify';



const ApagarProcesso = ({processo, fechar}:any) => {


    const [loading, setLoading] = useState<boolean>(false)

    // MUTAÇÃO -------------
    const {apagarProcesso, listarProcessos} = useReactQueryProcessos();
    const token = localStorage.getItem('token');
    const queryClient = useQueryClient();

    const apagarProcessoMutation = useMutation({
        mutationFn: apagarProcesso,
        onMutate: async(config)=>{
            // console.log('Mutação iniciada!');
            setLoading(true);
        },
        onSuccess: async(response)=>{
            
            // console.log('Mutação bem sucedida: ', response);

            queryClient.invalidateQueries({queryKey: ['rqProcessos']});
            queryClient.fetchQuery({queryKey: ['rqProcessos']});
            queryClient.refetchQueries({queryKey: ['rqProcessos']});

            if(response.status = 1){
                toast.success(response.msg);
            }
            else{
                toast.error(response.msg)
            }

        },
        onError: (err)=>{
        console.error(err.message)

        },
        onSettled: async(data)=>{
            // console.log('Mutação finalizada!');
            setLoading(false);
            fechar()
        }

    })

    
    // MUTAÇÃO -------------


    const handleApagarProcesso =(e:any)=>{
        e.preventDefault();

        apagarProcessoMutation.mutate({
            processo: processo,
            token: token as string,
            processoid: processo._id
        })
    }

  return (
        <div>
            <div id="popup-modal" tabIndex={1} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-xl shadow dark:bg-gray-700 p-5">
                    

                    <label className='text-xl ml-2 mt-3 font-semibold'> Deseja apagar o processo de nuemro: {processo.numero}</label>

                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Deseja apagar este processo?</h3>
                        <p>
                            Todas as pastas, documentos e infomações do processo serão apagadas permanentemente.
                        </p>
                        <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={handleApagarProcesso}>
                            Apagar processo
                        </button>
                        <button data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={fechar}> Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
  )
}

export default ApagarProcesso