import { useQuery } from '@tanstack/react-query';
import React, { useLayoutEffect, useState } from 'react';
import { BsFolder, BsFolderPlus, BsPencil, BsTrash } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import LoadingComponent from '../LoaadingComponent';
import styles from '../PastasComponents/Pastas.module.css';
import PastaConteudo from '../PastasComponents/PastaConteudo';
import { DisplayPastas, typePasta } from '../../Utils/types/pastasTypes';
import ApagarPasta from '../PastasComponents/ApagarPasta';
import PastaInfo from '../PastasComponents/PastaInfo';
import { formatarData } from '../../Utils/formatarData';
import NovaPasta from '../PastasComponents/NovaPasta';
import { MdLink, MdLinkOff } from 'react-icons/md';
import { converterValor } from '../../Utils/ConverterValor';

const PastasTable = ({ pastasData }: any) => {
  const [pasta, setPasta] = useState<any>();
  const [empresaSelecionada, setEmpresaSelecionada] = useState<string | null>(
    localStorage.getItem('empresaSelecionada') || null
  );

  useLayoutEffect(() => {
    setEmpresaSelecionada(localStorage.getItem('empresaSelecionada'));
  });

  const token = localStorage.getItem('token');
  const { selecionarEmpresa } = useReactQueryEmpresas();

  const { data: empresaData, isLoading: empresaIsLoading } = useQuery({
    queryKey: ['rqEmpresaSelecionada'],
    queryFn: () =>
      selecionarEmpresa({
        token: token as string,
        empresaid: empresaSelecionada as string,
      }),
  });

  const [editando, setEditando] = useState<boolean>(false);
  const [apagando, setApagando] = useState<boolean>(false);
  const [criarPasta, setCriarPasta] = useState<boolean>(false);

  // Added expanded state for the expandable rows
  const [expandedPastaId, setExpandedPastaId] = useState(null);

  const handleAbrirPasta = (e: any, pasta: any) => {
    e.preventDefault();
    setExpandedPastaId((prevId) => (prevId === pasta._id ? null : pasta._id));
  };

  const handleEditarPasta = (pasta: typePasta) => {
    setPasta(pasta);
    setEditando(true);
  };

  const hanldeApagarPasta = (pasta: typePasta) => {
    setPasta(pasta);
    setApagando(true);
  };

  const fechar = (e: any) => {

    setEditando(false);
    setApagando(false);
    setCriarPasta(false);
    setPasta('');
    return;
  };

  const pastaStatus = (pasta: any) => {
    const dataVencimento = Date.parse(pasta.DataVencimento);
    const dataAtual: any = new Date().getTime();
    const statusNumber: any = (
      (dataVencimento - dataAtual) /
      (1000 * 60 * 60 * 24)
    ).toFixed(0);

    switch (true) {
      case statusNumber > 0 && statusNumber > 7:
        return 'Vigente';
      case statusNumber <= 0:
        return 'Vencido';
      case statusNumber <= 7:
        return `Vence em ${statusNumber} dias`;
      default:
        return 'Vigente';
    }
  };

  // Tailwind CSS classes for status styling
  const statusVigente = 'text-green-500 font-semibold';
  const statusVencido = 'text-red-500 font-semibold';
  const statusVencimentoPróximo = 'text-yellow-500 font-semibold';

  if (!pastasData) {
    return (
      <div
        className={`w-full dark:bg-gray-600 shadow-2xl ${styles.dashboardPastas}`}
      >
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className={`w-full dark:bg-gray-600 shadow-2xl h-fit`}>
      {apagando && <ApagarPasta pasta={pasta} fechar={fechar} />}

      {editando && <PastaInfo pasta={pasta} fechar={fechar} editando={true} />}

      {criarPasta && <NovaPasta fechar={fechar} />}

      <div className={`flex flex-col ${styles.dashboardPastas} h-52`}>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="px-6 cursor-pointer items-center flex"
                  onClick={() => setCriarPasta((current) => !current)}
                >
                  <BsFolderPlus className="mr-1 cursor-pointer" />
                  Pasta
                </th>
                <th scope="col" className="px-6">
                  Opções
                </th>
                <th scope="col" className="px-6">
                  Categoria
                </th>
                <th scope="col" className="px-6">
                  Emissão
                </th>
                <th scope="col" className="px-6">
                  Vencimento
                </th>
                <th scope="col" className="px-6">
                  Valor
                </th>
                <th scope="col" className="px-6">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {pastasData &&
                pastasData.map((pasta: typePasta) => (
                  <React.Fragment key={pasta._id}>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 font-medium text-gray-900 underline underline-offset-2 whitespace-nowrap dark:text-white"
                      >
                        <Link
                          to={`/portal/pastas/${pasta.NomePasta}`}
                          className="cursor-pointer w-fit"
                        >
                          {pasta.NomePasta.replace('_', ' ')}
                        </Link>
                      </th>

                      <td className="px-6 inline-flex text-black dark:text-gray-300  gap-4">
                        {pasta.LinkOEA ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={pasta.LinkOEA}
                            className="cursor-pointer opacity-80 hover:opacity-100 text-xl"
                          >
                            <MdLink />
                          </a>
                        ) : (
                          <span className="cursor-pointer opacity-80 hover:opacity-100 text-xl">
                            <MdLinkOff />
                          </span>
                        )}

                        <BsFolder
                          className={`cursor-pointer opacity-80 hover:opacity-100 ${
                            expandedPastaId === pasta._id ? 'text-blue-500' : ''
                          }`}
                          onClick={(e) => handleAbrirPasta(e, pasta)}
                        />
                        <BsPencil
                          className="cursor-pointer opacity-80 hover:opacity-100"
                          onClick={() => handleEditarPasta(pasta)}
                        />
                        <BsTrash
                          className="cursor-pointer opacity-80 hover:opacity-100"
                          onClick={() => hanldeApagarPasta(pasta)}
                        />
                      </td>

                      <td className="px-6">{pasta.Categoria}</td>

                      <td className="px-6">
                        { pasta.DataEmissao ? formatarData(pasta.DataEmissao as string) : 'Sine. Die'}
                      </td>
                      <td className="px-6">
                        {pasta.DataVencimento ? formatarData(pasta.DataVencimento as string) : 'Sine. Die'}
                      </td>

                      <td className="px-6">{converterValor(pasta.Valor)}</td>

                      <td className="px-6">
                        <span
                          className={
                            pastaStatus(pasta) === 'Vigente'
                              ? `${statusVigente}`
                              : pastaStatus(pasta) === 'Vencido'
                              ? `${statusVencido}`
                              : `${statusVencimentoPróximo}`
                          }
                        >
                          {pastaStatus(pasta)}
                        </span>
                      </td>
                    </tr>
                    {/* Expanded content */}
                    {expandedPastaId === pasta._id && (
                      <tr>
                        <td colSpan={7} className="p-4 bg-gray-50 dark:bg-gray-800">
                          <PastaConteudo
                            pasta={pasta}
                            displayPastas={DisplayPastas.DETAILS}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PastasTable;
