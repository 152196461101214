import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BsPencil, BsPlus, BsX } from 'react-icons/bs'
import { toast } from 'react-toastify'
import { useArquivosUtils } from '../../Utils/ReactQuery/getArquivos'
import { usePastasUtils } from '../../Utils/ReactQuery/getPastasUtils'
import LoadingPage from '../LoadingPage'
import AlertaAssinatura from '../AlertaAssinatura'


const NovoArquivo = ({fechar, pastaid}: any) => {

    const [NomeArquivo, setNomeArquivo] = useState<string>('');
    const [descrição, setDescrição] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [error, setError] =     useState<boolean>(false);

    const [planos, setPlanos] = useState<boolean>(false);


    // Mutação -------------------------------------------
    const {salvarArquivoV2, fileUpload} = useArquivosUtils();
    const queryClient = useQueryClient();
    const empresaid = localStorage.getItem('empresaSelecionada');
    const token = localStorage.getItem('token')


    const salvarArquivoMutation = useMutation({
        mutationFn: salvarArquivoV2,
        onMutate: async(config)=>{
            setLoading(true);
            // console.log('Mutação iniciada!');
        },
        onSuccess: async(response)=>{
            const {status, msg} = await response;
            queryClient.invalidateQueries({queryKey: ["rqPastaArquivos"]});
            queryClient.fetchQuery({queryKey: ["rqPastaArquivos"]});
            queryClient.refetchQueries({queryKey: ["rqPastaArquivos"]});
            // console.log("Mutação bem sucedida!", response.msg);


            if(status === 3){
                toast.error(`${msg} `);
            }
            if(status === 1){
                toast.success(`${msg}`)
                fechar()
            }
            if(status === 4){
                toast.warn(`${msg}`)
                setPlanos(true)
            }
        },
        onError: async(err)=>{
            setLoading(false);
            setError(true);
            toast.error(err.message);
            
        },
        onSettled: async(reesponse)=>{
            // console.log('Mutação bem sucedida');
            setLoading(false);
            
        }

    })
    // ----------------------------------------------------

    const [file, setFile] = useState<any>('');


    const handleNovoArquivo = async(e: any)=>{
        e.preventDefault();
        setError(false);

        const formData = new FormData(e.currentTarget);
        formData.append("file", file);
        formData.append('NomeArquivo', NomeArquivo as string);
        formData.append('Descrição', descrição as string);

        const Size: number = file.size || 0;
        salvarArquivoMutation.mutate({token: token as string, empresaid: empresaid as string, Arquivo: formData, pastaid: pastaid as string, nomeArquivo: file.name as string, descrição: descrição as string, Size: Size })

        return;

    }

    // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('Upload progress: ', file)
    // },[file])
    // //~~MANUTENÇÃO~~//

    const fecharNovoArquivo =(e: any)=>{
        setFile('');
        setNomeArquivo('');
        setDescrição('');
        fechar();
    }

    const fecharAlerta=()=>{
        setPlanos(false)
    }

    if(planos){
        return(
            <>
            <AlertaAssinatura fechar ={fecharAlerta}/>
            </>
        )
    }


    if(loading){
        return(
            <div id="crud-modal" tabIndex ={-1} aria-hidden="true" className="overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 ">
                <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <LoadingPage/>
                </div>
                </div>
            </div>
        )
    }
    
    

  return (
        <div id="crud-modal" tabIndex ={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">

  

            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                
                <span className='w-full inline-flex justify-end cursor-pointer' onClick={fecharNovoArquivo}> <BsX className='text-3xl mr-2 mt-2'/> </span>
                    
                    <form className="p-4 md:p-5" onSubmit={handleNovoArquivo}>


                        <div className='mb-2'>
                            <label htmlFor=""> Enviar arquivo </label>
                            <input type="file" accept="application/pdf" name="file" onChange={(e: any)=> setFile(e.target.files[0])}/>
                        </div>



                        <div className="grid gap-4 mb-4 grid-cols-2">
                            
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome do arquivo</label>
                                <input type="text" name="NomeArquivo" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={file.name ? file.name : 'Nome do arquivo'} onChange={(e)=> setNomeArquivo(e.target.value)}/>
                            </div>


                            <div className="col-span-2">
                                <div className="col-span-2">
                                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Descrição do arquivo</label>
                                <textarea id="description" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Descreva o conteudo do arquivo" name='Descrição'  onChange={(e)=> setDescrição(e.target.value)}></textarea>                    
                                </div>
                            </div>



                        </div>

               
                        <div className='flex flex-row gap-2'>
                            <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <BsPlus className='text-xl'/>
                                Salvar Arquivo
                            </button>
                        </div>
                        


                    </form>
                </div>
            </div>
        </div> 
  )
}

export default NovoArquivo