import React, { useState } from 'react';
import { BsDownload, BsEye, BsFilePlus, BsInfo, BsPencil, BsTrash } from 'react-icons/bs';
import { formatarData } from '../../Utils/formatarData';
import { DisplayPastas, ModoAbrirArquivo } from '../../Utils/types/pastasTypes';
import ArquivoModalPastas from '../ArquivosComponents.tsx/ArquivosModalPastas';
import ApagarArquivo from './ApagarArquivo';
import NovoArquivo from './NovoArquivo';
import PastaInfo from './PastaInfo';
import { useQuery } from '@tanstack/react-query';
import { useArquivosUtils } from '../../Utils/ReactQuery/getArquivos';
import LoadingSmall from '../LoadingSmall';
import EditarArquivo from '../ArquivosComponents.tsx/EditarArquivo';

const PastaConteudo = ({ pasta, displayPastas }: any) => {
  const token = localStorage.getItem('token');
  const { getArquivos } = useArquivosUtils();
  const { data: arquivosData, isLoading: arquivosIsLoading } = useQuery({
    queryFn: () => getArquivos(token as string, pasta._id),
    queryKey: ['rqPastaArquivos', pasta._id], // Include pasta ID in query key
  });

  const [pastaInfo, setPastaInfo] = useState<boolean>(false);
  const [arquivoSelecionado, setArquivoSelecionado] = useState<any>();
  const [apagarArquivo, setApagarArquivo] = useState<boolean>(false);
  const [arquivoAberto, setArquivoAberto] = useState<boolean>(false);
  const [novoArquivo, setNovoArquivo] = useState<boolean>(false);
  const [editarArquivo, setEditarArquivo] = useState<boolean>(false);
  const [displayArquivo, setDisplayArquivo] = useState<ModoAbrirArquivo>(ModoAbrirArquivo.EDITAR);
  const [abrirArquivoDetalhe, setAbrirArquivoDetalhe] = useState<boolean>(false);

  const fecharPastaInfo = (e: any) => {
    e.preventDefault();
    setPastaInfo(false);
  };

  const handleApagarArquivo = (e: any, arquivo: any) => {
    e.preventDefault();
    setApagarArquivo(true);
    setArquivoSelecionado(arquivo);
    return;
  };

  const handleAbrirArquivo = (e: any, arquivo: any) => {
    e.preventDefault();
    setArquivoSelecionado(arquivo);
    setArquivoAberto(true);
  };

  const fecharArquivo = (e: any) => {
  
    setApagarArquivo(false);
    setArquivoAberto(false);
    setNovoArquivo(false);
    setEditarArquivo(false);
    setAbrirArquivoDetalhe(false);
    return;
  };

  const handleEditarArquivo = (e: any, arquivo: any) => {
    e.preventDefault();
    setArquivoSelecionado(arquivo);
    setEditarArquivo(true);
    setDisplayArquivo(ModoAbrirArquivo.EDITAR);
    return;
  };

  const handleAbrirArquivoDetalhe = (e: any, arquivo: any) => {
    e.preventDefault();
    setAbrirArquivoDetalhe(true);
    setArquivoSelecionado(arquivo);
    setDisplayArquivo(ModoAbrirArquivo.VISUALIZAR);
    return;
  };

  const dowloadFile = (e: any, fileUrl: string, fileName: string, arquivoPath: string) => {
    e.preventDefault();
    try {
      let contentUrl = '';
      if (fileUrl) {
        contentUrl = fileUrl;
      }
      if (arquivoPath) {
        contentUrl = `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivoPath}`;
      }
      fetch(contentUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${fileName}`;

          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const findArquivoLink = (arquivo: any) => {
    switch (true) {
      case arquivo.Cnpj === '19001876000180':
        return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/${arquivo.ArquivoPath}`;
      case arquivo.LinkAws !== undefined:
        return arquivo.LinkAws;
      case arquivo.ArquivoPath !== undefined:
        return `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivo.ArquivoPath}`;
      default:
        return '#';
    }
  };

  if (arquivosIsLoading) {
    return <LoadingSmall />;
  }

  return (
    <div className="p-4 max-h-52 overflow-y-auto bg-white rounded-xl shadow-2xl">
     
      {pastaInfo && <PastaInfo fechar={fecharPastaInfo} pasta={pasta} />}

      {apagarArquivo && <ApagarArquivo fechar={fecharArquivo} arquivo={arquivoSelecionado} />}

      {arquivoAberto && (
        <ArquivoModalPastas arquivo={arquivoSelecionado} fechar={fecharArquivo} />
      )}
      {novoArquivo && <NovoArquivo fechar={fecharArquivo} pastaid={pasta._id} />}
      {editarArquivo && (
        <EditarArquivo
          arquivo={arquivoSelecionado}
          fechar={fecharArquivo}
          displayArquivo={ModoAbrirArquivo.EDITAR}
        />
      )}
      {abrirArquivoDetalhe && (
        <EditarArquivo
          arquivo={arquivoSelecionado}
          fechar={fecharArquivo}
          displayArquivo={ModoAbrirArquivo.VISUALIZAR}
        />
      )}

      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => setPastaInfo((current) => !current)}
          className="flex items-center bg-gray-200 rounded-md shadow-lg p-1 hover:bg-gray-250 hover:text-blue-500"
        >
          <BsInfo className="text-lg" /> Informações da pasta
        </button>
        <button
          onClick={() => setNovoArquivo((current) => !current)}
          className="flex items-center gap-2 text-green-500 hover:text-green-700"
        >
          <BsFilePlus className="text-lg" /> Novo arquivo
        </button>
      </div>

      {displayPastas === DisplayPastas.DETAILS && (
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-700 dark:text-gray-400">
            <thead className="bg-gray-200 dark:bg-gray-700">
              <tr>
                <th className="px-4 py-2">Nome do arquivo</th>
                <th className="px-4 py-2">Descrição</th>
                <th className="px-4 py-2">Última modificação</th>
                <th className="px-4 py-2">Criado por</th>
                <th className="px-4 py-2">Opções</th>
              </tr>
            </thead>
            <tbody>
              {arquivosData &&
                arquivosData.arquivos.map((arquivo: any) => (
                  <tr
                    key={arquivo._id}
                    className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <td className="px-4 py-2">
                      <button
                        className="text-blue-500 hover:underline"
                        onClick={(e) => handleAbrirArquivoDetalhe(e, arquivo)}
                      >
                        {arquivo.NomeArquivo}
                      </button>
                    </td>
                    <td className="px-4 py-2">
                      {arquivo.Descrição && arquivo.Descrição.length >= 25
                        ? arquivo.Descrição.slice(0, 25) + '...'
                        : arquivo.Descrição}
                    </td>
                    <td className="px-4 py-2">
                      {formatarData(arquivo.UltimaModificação)}
                    </td>
                    <td className="px-4 py-2">{arquivo.CriadoPor}</td>
                    <td className="px-4 py-2">
                      <div className="flex items-center gap-2">
                        <a
                          href={findArquivoLink(arquivo)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <BsEye className="text-xl" />
                        </a>
                        <button
                          onClick={(e) =>
                            dowloadFile(
                              e,
                              arquivo.LinkAws,
                              arquivo.NomeArquivo,
                              arquivo.ArquivoPath
                            )
                          }
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <BsDownload className="text-xl" />
                        </button>
                        <button
                          onClick={(e) => handleEditarArquivo(e, arquivo)}
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <BsPencil className="text-xl" />
                        </button>
                        <button
                          onClick={(e) => handleApagarArquivo(e, arquivo)}
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <BsTrash className="text-xl" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

    </div>
  );
};

export default PastaConteudo;
