
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Pages/Home';
import Register from './Pages/Register';
import { DarkThemeToggle, Flowbite } from "flowbite-react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Portal from './Pages/Portal';
import PublicRoute from './Components/PublicRoute';
import PrivateRoute from './Components/PrivateRoute';
import RecuperarSenha from './Pages/RecuperarSenha';
import Perfil from './Pages/Perfil';
import { UserContextProvider } from './Context/UserContext';
import BemVindo from './Pages/BemVindo';
import NavComponent from './Components/NavComponent';


function App() {
  const queryClient = new QueryClient();

  return (

    <div className={`${App} *:
     text-black dark:text-white bg-gray-50 dark:bg-gray-900
    `}>


      <QueryClientProvider client={queryClient}> 
       
        <BrowserRouter>
          <UserContextProvider>
            <Flowbite>
              <Routes>

                <Route element={<PublicRoute />}>
                  <Route path='/' element={<Home />} />
                  <Route path='/registrar' element={<Register />} />
                </Route>

               

                <Route element={<PrivateRoute />}>
                  <Route path='/portal/*' element={<Portal />}>
                  </Route>

                </Route>

                <Route element={<PrivateRoute />}>
                  <Route path='/bem-vindo' element={<BemVindo />}>
                  </Route>
                </Route>
                

                <Route path='/auth/recuperar-senha' element={<RecuperarSenha />} />

              </Routes>
            </Flowbite>
          </UserContextProvider>
        </BrowserRouter>

      </QueryClientProvider>

    </div>

  );
}

export default App;