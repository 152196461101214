import { useEffect, useMemo } from 'react';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import { useQuery } from '@tanstack/react-query';
import { OrganizationChart } from 'primereact/organizationchart';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { useUserContext } from '../../Context/useUserContext';

const nivelHierarquicoColors: { [key: number]: string } = {
    1: 'bg-blue-500',
    2: 'bg-green-500',
    3: 'bg-yellow-500',
    4: 'bg-purple-500',
    5: 'bg-indigo-500',
    6: 'bg-teal-500',
    7: 'bg-orange-500',
    8: 'bg-pink-500',
    9: 'bg-gray-500',
    10: 'bg-blue-300',
    11: 'bg-green-300',
    12: 'bg-purple-300',
    13: 'bg-red-500',
    14: 'bg-yellow-300'
};

interface User {
    _id: string;
    CargoFuncao: string;
    Departamento: string;
    Foto: string;
    Função: string;
    NivelHierarquico: {
        tipo: string;
        nivel: number;
        _id: string;
    };
    Nome: string;
    SuperiorImediato?: string;
}

interface OrganizationChartNodeData extends User {
    children?: OrganizationChartNodeData[];
    expanded?: boolean;
}

const Organograma = () => {

    const [userContext] = useUserContext();

    // useEffect(() => {
    //     console.log('User context: ', userContext)
    // }, [userContext])

    const { getUserInfo } = useReactQueryUsers();

    const token = localStorage.getItem("token")
    const { data: organogramaData, isLoading } = useQuery({
        queryFn: () => getUserInfo(token as string),
        queryKey: ['rqOrganogramaInfo', userContext.EmpresaPadrão],
        staleTime: Infinity
    });


    if (organogramaData) {
        console.log('Organogrrama data: ', organogramaData)
    }


    const processOrganogramaData = (data: User[]): OrganizationChartNodeData[] => {
        const map: { [key: string]: OrganizationChartNodeData } = {};

        data.forEach(user => {
            if (user && user._id && user.SuperiorImediato !== "") { // Verifica se SuperiorImediato não está vazio
                map[user._id] = {
                    ...user,
                    children: [],
                    expanded: true // Garante que expanded esteja sempre definido
                };
            }
        });

        const rootNodes: OrganizationChartNodeData[] = [];

        data.forEach(user => {
            if (user.SuperiorImediato && map[user.SuperiorImediato] && user.SuperiorImediato !== user._id) {
                const superior = map[user.SuperiorImediato];

                if (superior) {
                    superior.children = superior.children || [];
                    superior.children.push(map[user._id]);
                }
            } else if (map[user._id] && user.SuperiorImediato !== "") {
                // Adiciona o usuário como raiz apenas se ele não tiver SuperiorImediato vazio
                rootNodes.push(map[user._id]);
            }
        });

        return rootNodes;
    };




    const processedData = useMemo(() => {
        if (organogramaData) {
            return processOrganogramaData(organogramaData.usuarios);
        }
        return [];
    }, [organogramaData]);

    const getColor = (nivel: number) => {
        return nivelHierarquicoColors[nivel] || 'bg-gray-500';
    };

    return (
        <div className="p-6 w-full max-h-full">
            <style>
                {`
                @layer components {
    .p-organizationchart .p-organizationchart-line-down {
        flex-wrap: wrap; /* Permite que os filhos quebrem em várias linhas */
    }
    
    .p-organizationchart .p-organizationchart-node-content {
        background-color: transparent;
        border: none;
        width: auto !important; /* Define a largura do conteúdo de forma automática */
    }

    .p-organizationchart .p-organizationchart-node {
        flex: 1 1 100%; /* Para garantir que o nó ocupe toda a largura disponível */
    }

    @media (max-width: 1200px) {
        .p-organizationchart .p-organizationchart-line-down {
            flex-direction: row;
        }
    }
}

                `}
            </style>
            {isLoading ? (
                <p>Carregando Organograma...</p>
            ) : (
                organogramaData && <OrganizationChart
                    value={processedData}
                    nodeTemplate={(node: OrganizationChartNodeData) => (
                        <div className="flex flex-col items-center">
                            <div className="relative w-28 h-28 mb-4">
                                {node.Foto ? (
                                    <img src={node.Foto} alt={node.Nome} className="w-full h-full rounded-full border-8 border-[#f9fafb] object-cover" />
                                ) : (
                                    <div className={`w-full h-full flex items-center justify-center rounded-full ${getColor(node.NivelHierarquico.nivel)}`}>
                                        <span className="text-white text-2xl">{node.Nome[0]}</span>
                                    </div>
                                )}
                            </div>
                            <div className="bg-white p-4 rounded-lg shadow-md -mt-12 pt-12 w-48 text-center">
                                <div className="font-bold text-lg mb-1">
                                    {(() => {
                                        const names = node.Nome.split(' ');
                                        return `${names[0]} ${names[names.length - 1]}`;
                                    })()}
                                </div>
                                <div className="text-sm">{node.NivelHierarquico.tipo}</div>
                                <div className="text-sm text-gray-500">{node.CargoFuncao}</div>
                            </div>

                        </div>
                    )}
                    className="p-organizationchart"
                />
            )}
        </div>
    );
};

export default Organograma;