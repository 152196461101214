import React, { useEffect, useState } from 'react'
import { BsChat, BsChatFill, BsFolderPlus, BsHandThumbsDown, BsHandThumbsDownFill, BsHandThumbsUp, BsHandThumbsUpFill, BsPencil, BsPerson, BsPersonFill, BsPlus, BsPlusCircleDotted, BsStar, BsStarFill, BsStarHalf, BsTrash } from 'react-icons/bs'

import EditarProcesso from './EditarProcesso';
import ApagarArquivo from '../PastasComponents/ApagarArquivo';
import ApagarProcesso from './ApagarProcesso';
import { TbFilePlus } from 'react-icons/tb';
import NovaPasta from '../PastasComponents/NovaPasta';
import NovaPastaProcesso from './NovaPastaProcesso';
import NovoArquivoProcesso from './NovoArquivoProcesso';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { DisplayMiddle, SituaçãoInterna } from '../../Utils/types/PortalTypes';

type Props = {}

const ProcessosFerramentas = ({ processo, busca, filtroMiddle, displayFiltroMiddle }: any) => {

  const [editarProcesso, setEditarProcesso] = useState<boolean>();
  const [apagarProcesso, setApagarProcesso] = useState<boolean>(false)
  const [novaPasta, setNovaPasta] = useState<boolean>(false);
  const [novoArquivo, setNovoArquivo] = useState<boolean>(false)


  const [iconExpand, setIconExpand] = useState<boolean>(false)

  // console.log(`Busca ----------> `, busca)


  const { editarStatusProcesso } = useReactQueryProcessos();

  const token = localStorage.getItem("token");
  const empresaSelecionada = localStorage.getItem('empresaSelecionada');
  const queryClient = useQueryClient();

  //MUTAÇÃO -----------------------------------------
  const editarStatusMutation = useMutation({
    mutationFn: editarStatusProcesso,
    onMutate: async (config) => {
      // console.log('Mutação iniciada!');

        
    }, onSuccess: async (response) => {
      // console.log('MUtaçãobem sucedida!', response);

        queryClient.invalidateQueries({queryKey:["rqProcessos"]});
        queryClient.fetchQuery({queryKey:["rqProcessos"]});
        queryClient.refetchQueries({queryKey:["rqProcessos"]});
        
        queryClient.invalidateQueries({queryKey:["rqPrcessosInfo"]});
        queryClient.fetchQuery({queryKey:["rqPrcessosInfo"]});
        queryClient.refetchQueries({queryKey:["rqPrcessosInfo"]});

 
        if(busca){
          // console.log(`Busca ativa ---------------->`)
          queryClient.invalidateQueries({ queryKey: ['rqBuscarProcessos'] });
          queryClient.fetchQuery({ queryKey: ['rqBuscarProcessos'] });
        }
        
      

      if (response.status === 1) {
        toast.success(response.msg);
      }
      else {
        toast.error(response.msg);
      }

    }, onError: async (err) => {
      console.error(err.message);
      toast.error(err.message)
    },
    onSettled: async (data) => {
      // console.log('Mutação finalizada!')
    }

  })

  //MUTAÇÃO -----------------------------------------

  const handleStatusProcesso = (e: any, action: string, importante: boolean) => {
    e.preventDefault();


    switch (true) {
      case action === 'Aprovado' && !importante:
        return editarStatusMutation.mutate({
          token: token as string, processoid: processo._id as string, data: {
            situacaoInterna: 'Aprovado',
          }
        });
        break;
        
      case action === 'Desaprovado' && !importante:
        return editarStatusMutation.mutate({
          token: token as string, processoid: processo._id as string, data: {
            situacaoInterna: 'Desaprovado'
          }
        });
        break;

      case action === 'importante' && importante && !processo.importante:
        return editarStatusMutation.mutate({
          token: token as string, processoid: processo._id as string, data: {
            importante: true
          }
        });
        break;
      case action === 'importante' && importante && processo.importante:
        return editarStatusMutation.mutate({
          token: token as string, processoid: processo._id as string, data: {
            importante: false
          }
        });
        break;
    }

  }


  const fechar = (e: any) => {
    e.preventDefault();
    setEditarProcesso(false);
    setApagarProcesso(false);
    setNovaPasta(false)
    setNovoArquivo(false)

  }

  // Tailwind -----------------------------------------
  const activeThumb = '';
  const inactiveThub = '';

  // Tailwind -----------------------------------------


  return (
    <>
      {novaPasta && <NovaPastaProcesso fechar={fechar} processo={processo} />}
      {novoArquivo && <NovoArquivoProcesso fechar={fechar} processo={processo} />}
    


      <div className={iconExpand ? 'w-fit flex flex-row justify-start gap-2 py-1 items-center mr-5 ': 'w-28 flex flex-row justify-start gap-2 py-1 items-center mr-5 '}>

        {editarProcesso && <EditarProcesso fechar={fechar} processo={processo} busca={true} />}
        {apagarProcesso && <ApagarProcesso fechar={fechar} processo={processo} />}

        <div className='flex flex-row gap-2 '>

        <label title='Importante' className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' onClick={(e)=> handleStatusProcesso(e, 'importante', true)}>
            {processo.importante ? <BsStarFill className='text-xl' /> : <BsStar className='text-xl' />}
          </label>

          <label className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' onClick={(e)=> handleStatusProcesso(e, SituaçãoInterna.APROVADO, false)} title='Aprovado'>
            {processo.situacaoInterna === SituaçãoInterna.APROVADO ? <BsHandThumbsUpFill className='text-xl' /> : <BsHandThumbsUp className='text-xl' />}
          </label>

          <label className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' onClick={(e)=> handleStatusProcesso(e, SituaçãoInterna.DESAPROVADO, false)} title='Desaprovado'>
            {processo.situacaoInterna === SituaçãoInterna.DESAPROVADO ? <BsHandThumbsDownFill className='text-xl' /> : <BsHandThumbsDown className='text-xl' />}
          </label>

         
        </div>

        {
          iconExpand &&
          
          <>


        <div className='flex flex-row gap-2'>
          <div onClick={() => setNovaPasta(current => !current)} className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' title='Nova pasta'>
            <BsFolderPlus className='text-xl' />
          </div>
          <div onClick={() => setNovoArquivo(current => !current)} className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' title='Novo arquivo'>
            <TbFilePlus className='text-xl' />
          </div>
        </div>

        <div className='flex flex-row gap-2'>
          <label className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' onClick={() => setEditarProcesso(current => !current)} title='Editar processo'>
            <BsPencil className='text-xl' />
          </label>
          <label className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' onClick={() => setApagarProcesso(current => !current)} title='Apagar processo'>
            <BsTrash className='text-xl' />
          </label>
        </div>

        <label className='text-xl cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center'  onClick={() => filtroMiddle(DisplayMiddle.CONTATO)} title='Contatos do processo'>
          {
            displayFiltroMiddle === DisplayMiddle.CONTATO ? 
            <BsPersonFill/>
            :
            <BsPerson/>
          }
          
          
        </label>

        <label className='text-xl cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center'  onClick={() => filtroMiddle(DisplayMiddle.CHAT)} title='Anotações do processo'>
          
          {
            displayFiltroMiddle === DisplayMiddle.CHAT ?
              <BsChatFill/>
            :
             <BsChat/> 
          }
             
          
         </label>


        </>
        }
        
        
        {
          iconExpand ? 
          <label className='text-xl cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' onClick={()=> setIconExpand(current => !current)} title='Colapsar ferramentas'> <div className='border border-dashed rounded-full w-6 h-6 flex items-center justify-center'> - </div>  </label>
          :
          <label className='text-xl cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' onClick={()=> setIconExpand(current => !current)} title='Expanir ferramentas'><BsPlusCircleDotted/>  </label>
        }
        

      </div>
    </>
  )
}

export default ProcessosFerramentas