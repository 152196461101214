import React, { useEffect, useState } from 'react'
import { BsArrowLeft, BsArrowRight, BsCheck, BsX } from 'react-icons/bs'
import { formatarData } from '../../Utils/formatarData'
import moment from 'moment'
import LoadingSmall from '../LoadingSmall'

const NovoProcessoEtapaTres = ({etapaTresData, loading, cancelar, voltar, editar, processo, alterarParteEditada, dadosPncp}: any) => {


    const ajustarData = (data: any)=>{
        const splitedDate = data.split(/[/-\s]/);
        const rearangedDate = splitedDate[2] + "-" + splitedDate[1]+ "-" + splitedDate[0] + "T" + splitedDate[5];
        const originalDate = data;
        const jsDate = rearangedDate.toString();
        const novaData = moment(rearangedDate);
    
    
    
    }


    // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('Dddos pncp etapa tres: ', dadosPncp)
    // },[dadosPncp])
    // //~~MANUTENÇÃO~~//

    const [dataPublicação, setDataPublicação] = useState<string | Date>('');
    const [dataAbertura, setDataAbertura] = useState<string | Date>('');

    useEffect(()=>{
        if(dadosPncp){

            setDataPublicação(dadosPncp.item.data_publicacao_pncp)
            setDataAbertura(dadosPncp.item.data_fim_vigencia)
        }
    },[dadosPncp])

    const handleEtapaTres =(e: any)=>{
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

        const data ={
            DataHoraPublicacao             : formData.get('DataHoraPublicacao'), 
            DataHoraLimiteEsclarecimento   : formData.get('DataHoraLimiteEsclarecimento'), 
            DataHoraImpugnacao             : formData.get('DataHoraImpugnacao'), 
            DataHoraContraProposta         : formData.get('DataHoraContraProposta'), 
            DataHoraAberturaLicitacao      : formData.get('DataHoraAberturaLicitacao'), 
            DataHoraContinuacaoLicitacao   : formData.get('DataHoraContinuacaoLicitacao'), 
            DataHoraIntencaoRecurso        : formData.get('DataHoraIntencaoRecurso'), 
            DataHoraRecurso                : formData.get('DataHoraRecurso'), 
            DataHoraLimiteContrarrazao     : formData.get('DataHoraLimiteContrarrazao'), 
            DataHoraLimiteJulgamentoRecurso: formData.get('DataHoraLimiteJulgamentoRecurso'), 
            DataHoraHomologacao            : formData.get('DataHoraHomologacao'), 
            DataHoraAdjudicação            : formData.get('DataHoraAdjudicação'), 
            DataHoraDiligencia             : formData.get('DataHoraDiligencia'), 
            DataHoraApresentação           : formData.get('DataHoraApresentação'), 
            DataMandatoS                   : formData.get('DataMandatoS'),
        }


        etapaTresData(e, data)
    }



        //Tailwind ----------------------
        const inputField = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
        const labelField ="block mb-2 text-sm font-medium text-gray-900 dark:text-white";
    
        const button = "cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit"
        const closeButton = "bg-red-500 cursor-pointer text-white inline-flex items-center bg-bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:bg-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit"

        const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
        const optionInput ="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
        
        //Tailwind ----------------------

  return (
    <div>
        <div id="defaultModal" tabIndex={-1} aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full max-h-screen">
       
       <div className="relative p-4 w-fit max-w-2xl h-full md:h-auto">
           
           <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 ">
               <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                

                    <div>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {editar ? `Editar processo` :`Registrar processo`}
                        </h3>
                        <label> Informações das datas e prazos</label>
                        <br />
                        <small>Campos marcados com * são obrigatórios.</small>
                    </div>

                    <div className='flex flex-row gap-2'>
                    <label onClick={editar ? ()=>alterarParteEditada('back') : voltar}><BsArrowLeft className='text-3xl cursor-pointer'/> Voltar </label>

                   {editar && <label onClick={()=> alterarParteEditada('forward')}>
                        <BsArrowRight className='text-3xl cursor-pointer'/> Passar
                    </label>
                    }
                    </div>

               </div>

               <form onSubmit={handleEtapaTres}>
               <div className="grid gap-4 mb-4 sm:grid-cols-2 lg:grid-cols-3">

                    <div>
                        <label className={`${labelField}`}> Publicação *</label>
                        {
                            dataPublicação ?
                            <input name='DataHoraPublicacao' className={`${inputField} text-red-500`} type="text" id="DataHoraPublicacao" required={editar ?  false : true} value={dataPublicação as string} onChange={(e)=> setDataPublicação(e.target.value)}/>
                            :
                             <input name='DataHoraPublicacao' className={`${inputField}`} type="datetime-local" id="DataHoraPublicacao" required={editar ?  false : true}/>
                        }
                        
                       
                    </div>
                  
                    <div>
                        <label className={`${labelField}`}> Esclarecimento </label>
                        <input name='DataHoraLimiteEsclarecimento' className={`${inputField}`} type="datetime-local" id="DataHoraLimiteEsclarecimento" />
                    </div>
                  
                    <div>
                        <label className={`${labelField}`}> Inpugnação </label>
                        <input name='DataHoraImpugnacao' className={`${inputField}`} type="datetime-local" id="DataHoraImpugnacao" />
                    </div>
                    
                    <div>
                        <label className={`${labelField}`}> R.Proposta </label>
                        <input name='DataHoraContraProposta' className={`${inputField}`} type="datetime-local" id="DataHoraContraProposta" />
                    </div>
                   
                    <div>
                        <label className={`${labelField}`}> Abertura *</label>

                        {
                            dataAbertura ? 

                            <input name='DataHoraAberturaLicitacao' className={`${inputField} text-red-500`} type="text" id="DataHoraAberturaLicitacao" required={editar ?  false : true} value={dataAbertura as string} onChange={(e)=> setDataAbertura(e.target.value)}/>
                            
                            :

                            <input name='DataHoraAberturaLicitacao' className={`${inputField}`} type="datetime-local" id="DataHoraAberturaLicitacao" required={editar ?  false : true}/>
                        }

                    </div>
                    
                    <div>
                        <label className={`${labelField}`}> Diligência </label>
                        <input name='DataHoraDiligencia' className={`${inputField}`} type="datetime-local" id="DataHoraDiligencia" />
                    </div>

                    <div>
                        <label className={`${labelField}`}> Continuação </label>
                        <input name='DataHoraContinuacaoLicitacao' className={`${inputField}`} type="datetime-local" id="DataHoraContinuacaoLicitacao" />
                    </div>
                    
                    <div>
                        <label className={`${labelField}`}> Apresentação </label>
                        <input name='DataHoraApresentação' className={`${inputField}`} type="datetime-local" id="DataHoraApresentação" />
                    </div>

                    <div>
                        <label className={`${labelField}`}> I.Recurso </label>
                        <input name='DataHoraIntencaoRecurso' className={`${inputField}`} type="datetime-local" id="DataHoraIntencaoRecurso" />
                    </div>
                    
                    <div>
                        <label className={`${labelField}`}> Recurso </label>
                        <input name='DataHoraRecurso' className={`${inputField}`} type="datetime-local" id="DataHoraRecurso" />
                    </div>
                    
                    <div>
                        <label className={`${labelField}`}> Contrarrazões </label>
                        <input name='DataHoraLimiteContrarrazao' className={`${inputField}`} type="datetime-local" id="DataHoraLimiteContrarrazao" />
                    </div>
                    
                    <div>
                        <label className={`${labelField}`}> Julgamento R. </label>
                        <input name='DataHoraLimiteJulgamentoRecurso' className={`${inputField}`} type="datetime-local" id="DataHoraLimiteJulgamentoRecurso" />
                    </div>
                    
                    <div>
                        <label className={`${labelField}`}> Adjudicação </label>
                        <input name='DataHoraAdjudicação' className={`${inputField}`} type="datetime-local" id="DataHoraAdjudicação" />
                    </div>
                    
                    <div>
                        <label className={`${labelField}`}> Homologação </label>
                        <input name='DataHoraHomologacao' className={`${inputField}`} type="datetime-local" id="DataHoraHomologacao" />
                    </div>
                    
                    <div>
                        <label className={`${labelField}`}> Mandato S. </label>
                        <input name='DataMandatoS' className={`${inputField}`} type="datetime-local" id="DataMandatoS" />
                    </div>
                    
                </div>

                   <div className='flex flex-row w-full justify-between mt-4'>
                      
                        <label className={`${closeButton}`} onClick={cancelar}> Cancelar <BsX className='text-2xl ml-2'/></label>
                        {loading ? <button disabled = {loading}><LoadingSmall message = {"Aguarde"}/></button> : <button type='submit' className={`${button}`}> Salvar informações <BsArrowRight className='text-2xl ml-2'/></button>}
                      
                   </div>
                   
               </form>
           </div>
       </div>
   </div>

    </div>
  )
}

export default NovoProcessoEtapaTres