import React, { useLayoutEffect, useState } from 'react'
import { BsBook, BsCheckSquare, BsClipboardPlus, BsClock, BsFlag, BsHandThumbsDown, BsHandThumbsUp, BsTrophy, BsTruck } from 'react-icons/bs'
import { CgSandClock } from "react-icons/cg";
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { TbCalendarCancel } from 'react-icons/tb';
import { RiSearchEyeLine } from 'react-icons/ri';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { GoGraph } from "react-icons/go";
import { SituaçãoInterna } from '../../Utils/types/PortalTypes';
import { LiaToolsSolid } from "react-icons/lia";
import { useQuery } from '@tanstack/react-query';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';

const Filtros = ({ alterarFiltro, FiltroSelecionado, processos }: any) => {
    const empresaSelecionada = localStorage.getItem("empresaSelecionada");
    const token = localStorage.getItem('token');
    const { getProcessosInfo } = useReactQueryProcessos()

    const { data: processosInfoData, isLoading: proocessosInfoLoading } = useQuery({
        queryFn: () => getProcessosInfo(empresaSelecionada as string, token as string),
        queryKey: ["rqPrcessosInfo"]
    });


    const checkLength = (situacaoInterna: SituaçãoInterna) => {
        if (processosInfoData) {

            if (situacaoInterna === SituaçãoInterna.RESUMO) {
                return processosInfoData.processos.length
            }
            const length = processosInfoData.processos.filter((i: any) => {
                return i.situacaoInterna === situacaoInterna
            })?.length;
            return length;
        }
        return 0;
    }


    let filtroAtual = '';
    

    useLayoutEffect(()=>{
        const filtroSalvo = localStorage.getItem(`${empresaSelecionada}-filtro`);
        if(filtroSalvo){
            filtroAtual = filtroSalvo;
        }else{
            filtroAtual = localStorage.getItem('cnaePrincipal') as string;
        }
    },[])
    
    
    

    const { getCaptacaoAutomatica } = useReactQueryCaptacao()
    const { data: captacaoData, isLoading: captacaoIsLoading, refetch } = useQuery({
        queryFn: () => getCaptacaoAutomatica({ descrição: filtroAtual as string, pagina: 1, tamanhoPagina: 2000 }),
        queryKey: ["rqCaptacao", filtroAtual,]
    })



    // Tailwind Custom CSS
    const filterTab = 'cursor-pointer flex flex-row justify-between items-center rounded-lg px-1 py-1 bg-orange dark:bg-gray-700 shadow hover:shadow-md transition-shadow text-black';
    const ianctiveTab = 'cursor-pointer flex flex-row justify-between items-center rounded-lg px-1 py-1 bg-orange-500 text-white dark:bg-orange-800 shadow hover:shadow-md transition-shadow text-black';
    
    const filterGrid = 'flex flex-col w-full gap-2';
    const activeTab = 'dark:bg-orange-800 text-white font-semibold bg-[#ff8027]';
    const badge = 'inline-flex items-center justify-center w-6 h-6 text-xs font-medium bg-white-200 text-white-800 rounded-full dark:bg-black-900 dark:text-black-300';

    return (
        <div className="w-full h-fit pb-3">
            <div className='flex flex-row w-full justify-around pl-1 pr-1 gap-2'>

                <div className={`${filterGrid}`}>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.CAPTACAO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.CAPTACAO)}>
                        <BsClipboardPlus className='mr-2' />
                        <label className="cursor-pointer">Captação</label>
                        <span className={`${badge}`}> {captacaoData ? captacaoData.total : 0}</span>
                    </div>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.RESUMO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.RESUMO)}>
                        <BsBook className='mr-2' />
                        <label className="cursor-pointer">Análise</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.REGISTRAR)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.DESAPROVADO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.DESAPROVADO)}>
                        <BsHandThumbsDown className='mr-2' />
                        <label className="cursor-pointer">Desaprovados</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.DESAPROVADO)}</span>
                    </div>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.APROVADO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.APROVADO)}>
                        <BsHandThumbsUp className='mr-2' />
                        <label className="cursor-pointer">Aprovados</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.APROVADO)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.FAZENDO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.FAZENDO)}>
                        <CgSandClock className='mr-2' />
                        <label className="cursor-pointer">Fazendo</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.FAZENDO)}</span>
                    </div>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.FEITO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.FEITO)}>
                        <BsCheckSquare className='mr-2' />
                        <label className="cursor-pointer">Feito</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.FEITO)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.REVOGADA ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.REVOGADA)}>
                        <TbCalendarCancel className='mr-2' />
                        <label className="cursor-pointer">Revogado</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.REVOGADA)}</span>
                    </div>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.ORÇAMENTO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.ORÇAMENTO)}>
                        <FaRegMoneyBillAlt className='mr-2' />
                        <label className="cursor-pointer">Orçamento</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.ORÇAMENTO)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.SESSÕES_DO_DIA ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.SESSÕES_DO_DIA)}>
                        <BsClock className='mr-2' />
                        <label className="cursor-pointer">Sessões do dia</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.SESSÕES_DO_DIA)}</span>
                    </div>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.ACOMPANHAMENTO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.ACOMPANHAMENTO)}>
                        <RiSearchEyeLine className='mr-2' />
                        <label className="cursor-pointer">Acompanhamento</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.ACOMPANHAMENTO)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.PERDIDA ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.PERDIDA)}>
                        <IoIosCloseCircleOutline className='mr-2' />
                        <label className="cursor-pointer">Perdida</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.PERDIDA)}</span>
                    </div>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.GANHA ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.GANHA)}>
                        <BsTrophy className='mr-2' />
                        <label className="cursor-pointer">Ganha</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.GANHA)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.ENTREGANDO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.ENTREGANDO)}>
                        <BsTruck className='mr-2' />
                        <label className="cursor-pointer">Entregando</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.ENTREGANDO)}</span>
                    </div>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.OCORRÊNCIA ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.OCORRÊNCIA)}>
                        <LiaToolsSolid className='mr-2' />
                        <label className="cursor-pointer">Ocorrência</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.OCORRÊNCIA)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.EXECUÇÃO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.EXECUÇÃO)}>
                        <GoGraph className='mr-2' />
                        <label className="cursor-pointer">Execução</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.EXECUÇÃO)}</span>
                    </div>
                    <div className={`${filterTab} ${FiltroSelecionado === SituaçãoInterna.ENCERRADO ? activeTab : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.ENCERRADO)}>
                        <BsFlag className='mr-2' />
                        <label className="cursor-pointer">Encerrado</label>
                        <span className={badge}>{checkLength(SituaçãoInterna.ENCERRADO)}</span>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Filtros;
