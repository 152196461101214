import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { usePastasUtils } from '../../Utils/ReactQuery/getPastasUtils';
import PastasTable from './PastasTable';
import { typePasta } from '../../Utils/types/pastasTypes';

const PortalPastas = () => {

    const [empresaSelecionada, setEmpresaSelecionada] = useState<string | null>(localStorage.getItem('empresaSelecionada') || null);
    useLayoutEffect(() => {
        setEmpresaSelecionada(localStorage.getItem('empresaSelecionada'))
    })

    const { getPastas } = usePastasUtils()
    const { data: pastasData, isLoading, isError } = useQuery({ queryKey: ["rqPastas", empresaSelecionada], queryFn: () => getPastas(empresaSelecionada as string) });

    const [pastas, setPastas] = useState<any>()

    if(pastasData){
        console.log(pastasData.pastas)
    }
    
    useEffect(()=>{
        
        if(pastasData){
        
            const tempRepresentante  = pastasData.pastas.filter((pasta: typePasta)=>{ return pasta.Categoria === 'Representante'})
            const tempCredenciamento =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria === 'Credenciamento'})
            const tempMEEPP          =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria === 'ME/EPP+'       })

            const tempDiligências    =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria === 'Diligência(s)' })
        
            const tempDiligencia    =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria === 'Diligência(s)' })

            const tempProposta       =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria === 'Proposta'      })
            const tempHJ             =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria === 'H.J.'          })
            const QT             =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria === 'Q.T.'          })
            const tempRFT           =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria === 'R.F.T.'     })
            const tempQEF            =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria === 'Q.E.F.'        })
            const tempDeclarações    =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria === 'Declarações'   })
            const tempOutros         =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria ===  'Outros'       })
            const tempDocBancário    =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria ===  'Doc. Bancário'})
            const tempRFeT    =  pastasData.pastas.filter((pasta: typePasta)=>{return pasta.Categoria ===  'R.F. e T.'})
      

            const tempfLAT = [tempRepresentante,tempCredenciamento,tempMEEPP,tempDiligências,tempProposta,tempHJ,tempRFeT,QT,tempRFT,tempQEF ,tempDeclarações,tempOutros,tempDocBancário   ]

            setPastas(tempfLAT.flat())

        }


    },[pastasData]);
    



    // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('Pastas representante: ', Representante)
    // },[Representante])
    // //~~MANUTENÇÃO~~//


    
    // Organização as pastas ------------------------



    return (
        <div className={`w-full dark:bg-gray-600 shadow-2xl h-fit`}>

           
            <div className={`flex flex-col `}>
                {
                    pastasData &&
                    <>
                    <PastasTable pastasData ={pastas}/>
                    </>
                }
            </div>

        </div>
    )
}

export default PortalPastas