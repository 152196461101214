import { useState, useEffect } from 'react';
import { TCaptacaoCard } from '../../Utils/DevUtils/FakeCaptacao';
import CaptacaoItem from './CaptacaoItem';
import { BsQuestionCircle, BsPlus, BsArrowRight, BsSearch, BsExclamationCircle, BsX } from 'react-icons/bs';
import CaptacaoArquivos from './CaptacaoArquivos';
import CaptacaoItems from './CaptacaoItems';
import { cnpj } from 'cpf-cnpj-validator';
import NovoProcesso from '../ProcessosComponents/NovoProcesso';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TempresaData } from '../../Utils/types/cadastroTypes';
import { useUserContext } from '../../Context/useUserContext';
import { toast } from 'react-toastify';
import { RegistrarProcesso, typeProcessoData } from '../../Utils/types/PortalTypes';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import LoadingSmall from '../LoadingSmall';

const CaptacaoCard = ({ item }: any) => {
    const [itemSelecionado, setItemSelecionado] = useState<any>('');
    const [abiriItem, setAbrirItem] = useState<boolean>(false);
    const [arquivosUrl, setArquivosUrl] = useState<string>('');
    const [itensUrl, setItensUrl] = useState<string>('');
    const [anexarNoPortal, setAnexarNoPortal] = useState<boolean>(false);
    const [anexarDireto, setAnexarDireto] = useState<boolean>(false)
    const [orgaoInfo, setOrgaoInfo] = useState<any>(null);
    const [mostrarObjetoCompleto, setMostrarObjetoCompleto] = useState<boolean>(false)

    const [empresaResponssavel, setEmpresaResponsavel] = useState<any>('');
    
    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false)
    
    const [userContext] = useUserContext();

    const token = localStorage.getItem('token');
    const {empresasPermitidas} = useReactQueryEmpresas();
    const {data: empresasData} = useQuery({
        queryFn: ()=> empresasPermitidas(token as string),
        queryKey: ['rqEmpresasPermitidas']
    })

    enum SelecionarItem {
        ARQUIVO = 'Arquivos',
        ITEM = 'Items',
        RESUMO = 'resumo',
        ANEXAR = 'anexar',
        ANEXARDIRETO = 'anexardireto',
        ANEXARDIRETOEMPRESA= 'anexardiretoempresa'
    }

    const [captacaoArea, setCaptacaoArea] = useState<SelecionarItem>(SelecionarItem.RESUMO);

    const fecharItem = () => {
        setAbrirItem(false);
        setItemSelecionado('');
        setAnexarNoPortal(false);
        setAnexarDireto(false)
    };

    

    const selecionarItem = (itemAtivo: TCaptacaoCard, action: SelecionarItem) => {
        const arquivosUrl = `https://pncp.gov.br/api/pncp/v1/orgaos/${itemAtivo.orgao_cnpj}/compras/${itemAtivo.ano}/${itemAtivo.numero_sequencial}/arquivos`;
        setArquivosUrl(arquivosUrl);
        const itensUrl = `https://pncp.gov.br/api/pncp/v1/orgaos/${itemAtivo.orgao_cnpj}/compras/${itemAtivo.ano}/${itemAtivo.numero_sequencial}/itens`;

        const data = {
            item: itemAtivo,
            LinkArquivos: arquivosUrl,
            LinkItens: itensUrl
        };
        setItensUrl(itensUrl);
        setItemSelecionado(data);

        const empresaPadrão = localStorage.getItem('empresaPadrão');

        switch (action) {
            case SelecionarItem.ARQUIVO:
                return setCaptacaoArea(SelecionarItem.ARQUIVO);
            case SelecionarItem.ITEM:
                return setCaptacaoArea(SelecionarItem.ITEM);
            case SelecionarItem.RESUMO:
                return setCaptacaoArea(SelecionarItem.RESUMO);
            case SelecionarItem.ANEXAR:
                return setAnexarNoPortal(true);
            case SelecionarItem.ANEXARDIRETO:
                setAnexarDireto(true)
                return
            case SelecionarItem.ANEXARDIRETOEMPRESA:
                handleEnviarProcesso(empresaPadrão as string);
                return
        }
    };

    const resgatarValorTotal =async()=>{
        
        try {
         const res = await fetch(itensUrl);
         const response = await res.json();
 
         const valorTotal = response.flatMap((i: any)=>{
             if(!isNaN(i.valorTotal)){
                 return i.valorTotal
             }
             
         }).reduce((sum: any, i: any)=>{
             return sum + i
         }).toFixed(2)
 
 
         if(!valorTotal || valorTotal === 0){
             setVte('Sigiloso!');
             return;
         }
 

         setVte(`R$: ${valorTotal}`)
         return
         
        } catch (error) {
         return 
        }
         
     }

    useEffect(() => {
        if (item.orgao_cnpj) {
            fetch(`https://brasilapi.com.br/api/cnpj/v1/${item.orgao_cnpj}`)
                .then(response => response.json())
                .then(data => {
                    setOrgaoInfo(data);
                })
                .catch(error => console.error('Erro ao buscar informações do órgão:', error));
        }

        resgatarValorTotal()

    }, [item.orgao_cnpj]);

    const formatCep = (cep: string) => {
        return cep ? cep.replace(/(\d{5})(\d{3})/, "$1-$2") : '';
    };

    const formatTelefone = (telefone: string) => {
        return telefone ? telefone.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3") : '';
    };

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        const date = new Date(dateString);
        return date.toLocaleDateString('pt-BR', options);
    };

    // Função para alternar a exibição do objeto completo
    const toggleObjetoCompleto = () => {
        setMostrarObjetoCompleto(!mostrarObjetoCompleto);
    };


    const pncpLink = (linkPncp: any) => {
        const pncpUrl: string = linkPncp.replace('/compras', 'https://pncp.gov.br/app/editais');
        return pncpUrl;
    }

    // Envio diretoo mutation --------------------------------

    const queryClient = useQueryClient();
    const { registrarProcesso } = useReactQueryProcessos();
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');

    const registrarProcessoMutation = useMutation({
        mutationFn: registrarProcesso,
        onMutate: async (config) => {
            // console.log('Mutação inicida!');
            setLoading(true)
        },
        onSuccess: async (response) => {
            // console.log('Mutação bem sucedida!', response);

            queryClient.invalidateQueries({ queryKey: ['rqProcessos'] })
            queryClient.fetchQuery({ queryKey: ['rqProcessos'] })
            queryClient.refetchQueries({ queryKey: ['rqProcessos'] })

            queryClient.invalidateQueries({ queryKey: ['rqPrcessosInfo'] })
            queryClient.fetchQuery({ queryKey: ['rqPrcessosInfo'] })
            queryClient.refetchQueries({ queryKey: ['rqPrcessosInfo'] })

            if (response.status === 1) {
                toast.success(response.msg);
                setSucesso(true);
            }
            if (response.status === 4) {
                toast.warn(response.msg)
     
            }
            if (response.status === 3) {
                toast.error(response.msg);
            }
        },
        onError: async (error, variables, context) => {
            console.error(error.message);
            toast.error(error.message);

        },
        onSettled: async (data) => {
            // console.log('Mutação concluída!');
            setLoading(false)
        }
    })


    // Envio diretoo mutation --------------------------------

    const [Vte, setVte] = useState('')

    const numeroDoProcesso =()=>{
        const numeroDoProcesso = `${item.numero_sequencial.toString()}/${item.ano.toString()}`
        return numeroDoProcesso;
    }

    const findNumero = ()=>{
        const numeroApenas = item.title.replaceAll(/[^\d.-/]/g, '');
        return numeroApenas
    }

    

    const handleEnviarProcesso = (empresaSelecionada: string)=>{
  

        const data: typeProcessoData ={
            id_pncp: item.id,
            orgaopublicoLicitante: item.orgao_nome,
            CnpjLicitante: item.orgao_cnpj,
            empresaSelecionada: empresaSelecionada,
            enderecoLicitante: orgaoInfo.logradouro,
            cidadeLicitante: orgaoInfo.municipio,
            cepLicitante: orgaoInfo.cep,
            foneLicitante: orgaoInfo.ddd_telefone_1,
            emailLicitante: orgaoInfo.email,
            modalidade: item.modalidade_licitacao_nome,
            numero  : findNumero(),
            processo: numeroDoProcesso(),
            tipo: item.tipo_nome,
            objeto: item.description,
            valorestimado: Vte ,
            situacaoInterna: 'Registrar',
            DataHoraPublicacao:        item.data_publicacao_pncp,
            DataHoraAberturaLicitacao: item.data_fim_vigencia,
            LinkArquivosPncp: arquivosUrl,
            LinkItensPncp: itensUrl ,
            importante: false,
            DataHoraAdjucacao: '',
            DataHoraLimiteEsclarecimento    : '',
            DataHoraImpugnacao              : '',
            DataHoraContraProposta          : '',
            DataHoraContinuacaoLicitacao    : '',
            DataHoraIntencaoRecurso         : '',
            DataHoraRecurso                 : '',
            DataHoraLimiteContrarrazao      : '',
            DataHoraLimiteJulgamentoRecurso : '',
            DataHoraHomologacao             : '',
            DataHoraAdjudicação             : '',
            DataHoraDiligencia              : '',
            DataHoraApresentação            : '',
            DataMandatoS                    : '',
            exclusividade: '',
            LocalsessaoPublica: '',
            logoLicitante: '',
            portalSelecionado: '',
            usuarioSelecionado: '',
            srp: '',
            siteLicitante: '',
            file: ''
            
        }
    
        console.log('Data: ', data)
        registrarProcessoMutation.mutate({
            token: token as string,
            data: data,
            empresaSelecionada: empresaSelecionada,
            contatos: {},
            empresaid: empresaSelecionada,
            File: false,
            Arquivo: '',
            tipo: 'direto'
        
        });
     
        fecharItem();
        
    }



    // TAILWIND --------------------------------------------------------------------
    const button = 'cursor-pointer text-white inline-flex items-center bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 h-fit';
    const buttonInfo = 'cursor-pointer text-white inline-flex items-center bg-[#ff8027] hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800 h-fit';
    const activeTab = 'border-b-2 border-[#ff8027] text-[#ff8027] font-semibold inline-flex p-2 transition ease-in-out duration-300';
    const inactiveTab = 'text-gray-600 hover:text-[#ff8027] cursor-pointer transition ease-in-out duration-300 inline-flex p-2';
    const tooltip = 'absolute bg-gray-700 text-white text-xs rounded-lg py-2 px-3 right-0 bottom-full mb-2';
    const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
    const optionInput ="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";

    // TAILWIND --------------------------------------------------------------------




    return (
        <div className='flex'>

            {
                anexarDireto &&
                <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-5  flex flex-col gap-5">

                            <div className='w-full flex justify-end'>
                                <BsX className='text-3xl' onClick={fecharItem}/>
                            </div>
                            
                            <div className='text-5xl w-full flex justify-center items-center'>
                                <BsExclamationCircle/>
                            </div>

                                <h3> Selecione a empresa responssável pelo processo: </h3>

                                <select className={selectInput} onChange={(e)=> setEmpresaResponsavel(e.target.value)} value={empresaResponssavel}>
                                    {
                                        empresasData && empresasData.empresas.map((empresa: TempresaData)=>(
                                            <option className={optionInput} value={empresa._id}>{empresa.NomeEmpresa} - ({empresa.CnpjEmpresa})</option>
                                        ))
                                    }

                                </select>


                            <div className='w-full flex' onClick={() => handleEnviarProcesso(empresaResponssavel)}>

                                {
                                    empresaResponssavel ?
                                    <button className={button} disabled={loading}>
                                {loading ?<LoadingSmall message ={'Salvando processo!'}/> :<>Enviar para análise
                                <BsArrowRight className='ml-5 text-xl' /></>}
                                </button>
                                :
                                <label htmlFor=""> Selecione a empresa responssável pelo processo para enviar para a análse</label>
                                }

                            </div>

                            
                        </div>
                    </div>
                </div>
            }


            <div className='w-full h-fit bg-white rounded-lg shadow-md flex flex-col p-1 relative overflow-hidden'>
                {abiriItem &&
                    <div className='fadeIn'>
                        <CaptacaoItem item={itemSelecionado} fechar={fecharItem} />
                    </div>}
                {anexarNoPortal &&
                    <NovoProcesso fechar={fecharItem} captacaoPncp={itemSelecionado} />
                }

                <div className='flex flex-row justify-between items-center'>
                    <div>
                        <label className={captacaoArea === SelecionarItem.RESUMO ? `${activeTab}` : `${inactiveTab}`} onClick={() => selecionarItem(item, SelecionarItem.RESUMO)}>Resumo</label>
                        <label className={captacaoArea === SelecionarItem.ARQUIVO ? `${activeTab}` : `${inactiveTab}`} onClick={() => selecionarItem(item, SelecionarItem.ARQUIVO)}>Documentos</label>
                        <label className={captacaoArea === SelecionarItem.ITEM ? `${activeTab}` : `${inactiveTab}`} onClick={() => selecionarItem(item, SelecionarItem.ITEM)}>Itens</label>
                    </div>
                    <div>
                        <label className='text-gray-500'>Fonte: <a target='_blank' href={pncpLink(item.item_url)} className='font-semibold cursor-pointer'>PNCP</a></label>
                        <div className='text-sm text-gray-500 mt-1'>Última Atualização: <span className='font-semibold'>{formatDate(item.data_atualizacao_pncp)}</span></div>
                    </div>
                </div>

                {captacaoArea === SelecionarItem.ARQUIVO && (
                    <div className='overflow-y-auto chart fadeIn'>
                        <CaptacaoArquivos item={item} url={arquivosUrl} />
                    </div>
                )}
                {captacaoArea === SelecionarItem.ITEM && (
                    <div className='overflow-y-auto chart fadeIn'>
                        <CaptacaoItems item={item} url={itensUrl} />
                    </div>
                )}
                {captacaoArea === SelecionarItem.RESUMO && (
                    <div className="grid grid-cols-2 gap-4 text-gray-700 dark:text-gray-300 fadeIn">
                        {/* Primeira Coluna */}
                        <div className="flex flex-col gap-1">
                            <div className="font-bold text-xl text-green-600">{item.orgao_nome}</div>
                            <div className="text-sm text-gray-500 italic">{item.unidade_nome}</div>
                            <div><span className='font-semibold'>CNPJ:</span> {cnpj.format(item.orgao_cnpj)}</div>
                            {orgaoInfo && (
                                <>
                                    <div><span className='font-semibold'>Endereço:</span> {orgaoInfo.descricao_tipo_de_logradouro} {orgaoInfo.logradouro}, N° {orgaoInfo.numero}, {orgaoInfo.complemento} - {orgaoInfo.bairro}</div>
                                    <div><span className='font-semibold'>Cidade/UF:</span> {orgaoInfo.municipio}/{orgaoInfo.uf}</div>
                                    <div><span className='font-semibold'>Cep:</span> {formatCep(orgaoInfo.cep)}</div>
                                    <div><span className='font-semibold'>Telefone:</span> {formatTelefone(orgaoInfo.ddd_telefone_1)}</div>
                                </>
                            )}
                        </div>

                        {/* Segunda Coluna */}
                        <div className="flex flex-col gap-1">
                            <div><span className='font-semibold'>Número:</span> {item.title}</div>
                            <div><span className='font-semibold'>Modalidade Licitação:</span> {item.modalidade_licitacao_nome}</div>
                            <div><span className='font-semibold'>Esfera:</span> {item.esfera_nome}</div>
                            <div><span className='font-semibold'>Data:</span> {formatDate(item.data_fim_vigencia)}</div>


                            <div className="flex items-center">
                                <span className='font-semibold'>Objeto:</span>
                                <span className='ml-2'>
                                    {item.description.length > 50 && !mostrarObjetoCompleto
                                        ? `${item.description.slice(0, 50)}...`
                                        : item.description}
                                    {item.description.length > 50 && (
                                        <button onClick={toggleObjetoCompleto} className="ml-2">
                                            <BsPlus className="inline" />
                                        </button>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                <div className='w-full flex flex-row justify-between items-center'>

                    <div className='max-w-md flex flex-row justify-between gap-5'>

                        <button className={buttonInfo} onClick={() => selecionarItem(item, SelecionarItem.ANEXAR)}>
                            Verificar infrmações
                            <BsSearch className='ml-5 text-xl' />
                        </button>

                        {
                           userContext && userContext.User.AcessoAtual === 'Empresa'?
                            <button className={button} onClick={() => selecionarItem(item, SelecionarItem.ANEXARDIRETOEMPRESA)}>
                            Enviar para análise
                            <BsArrowRight className='ml-5 text-xl' />
                        </button>
                            :
                        <button className={button} onClick={() => selecionarItem(item, SelecionarItem.ANEXARDIRETO)}>
                            Enviar para análise
                            <BsArrowRight className='ml-5 text-xl' />
                        </button>}
                    </div>

                    <div className='relative group'>
                        <span className='text-gray-500 hover:text-gray-700 dark:text-gray-300 cursor-pointer transition ease-in-out duration-300'>
                            <BsQuestionCircle />
                        </span>
                        <div className={`${tooltip} hidden group-hover:block`}>
                            Esses são dados de captação automática. Para dar andamento no processo licitatório, envie para análise.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaptacaoCard;
