import React, { useLayoutEffect, useState } from 'react'
import { BsCaretRight, BsDash, BsDownload, BsPlus, BsTrash } from 'react-icons/bs'
import CaptacaoItens from '../CaptacaoAutomatica/CaptacaoItems'
import BottonProcessosExtrasArquivos from './BottonProcessosExtraArquivos'
import { TArquivosExtra, fakeArquivo } from '../../Utils/DevUtils/ArquivosFake'

import { ArquivosProcessoExtra } from '../../Utils/types/PortalTypes'
import { useReactQueryTimeline } from '../../Utils/ReactQuery/getArquivosTimeline'
import { useQuery } from '@tanstack/react-query'

type Props = {}

const BottonProcessoExtras = ({processo}: any) => {

    // Fake arquivos ------------------------
    const [arquivosProposta, setArquivosProposta] = useState<TArquivosExtra | any>([]);
    const [arquivosCredenciamento, setArquivosCredenciamento] = useState<TArquivosExtra | any>([]);
    const [habilitaçãoJuridica, setHabilitaçãoJuridica] = useState<TArquivosExtra | any>([]);
    const [seguridadeFeJ, setSeguridadeFeJ] = useState<TArquivosExtra | any>([]);
    const [qualificaçãoTecnica, setQualificaçãoTecnica] = useState<TArquivosExtra | any>([]);
    const [qualificaçãoEF, setQualificaçãoEF] = useState<TArquivosExtra | any>([]);
    const [declarações, setDeclarações] = useState<TArquivosExtra | any>([]);
    const [outros, setOutros] = useState<TArquivosExtra | any>([]);
    //--------------------------------




    const token = localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');
    const {getArquivosTimeline} = useReactQueryTimeline();

    const {data: timelineData, isLoading} = useQuery({
        queryFn:() => getArquivosTimeline(token as string, processo._id as string),
        queryKey: ["rqTimeline", processo],
        refetchOnMount: false,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    })


    const countArquivosTimeline = ( modalidade: string): number=>{
      
        try {
            if(timelineData)
                { 
                     const arquivosTemp =  timelineData.arquivos.filter((item: any)=>{return item.Modalidade === modalidade}).length;
                     
                     return arquivosTemp
                }else{
                    return 0
                }
        
        } catch (error) {
            console.error(error);
            return 0
            
        }
        
    }
    
    const [extraTab, setExtraTab] = useState<ArquivosProcessoExtra>(ArquivosProcessoExtra.IDLE)
    //--------------------------------

    useLayoutEffect(()=>{

        if(timelineData){
            const arquivosCredTemp =  timelineData.arquivos.filter((item: any)=>{return item.Modalidade === 'Credenciamento'})  
            setArquivosCredenciamento(arquivosCredTemp)}
        return () =>{}

    },[timelineData, processo])
    // Fake arquivos ------------------------


    const [itens, setItens] = useState<number>(0)

    const changeItemNumber =(itens: any)=>{
        try {
            const itemLength: number = itens.length;
            setItens(itemLength)
        } catch (error) {
            console.error(error);
            return;
        }
    }

    //Tailwind ------------------------------------------------------------
    const badge = 'inline-flex items-center justify-center w-4 h-4 text-xs font-medium bg-blue-200 text-white-800 rounded-full dark:bg-black-900 dark:text-black-300 p-0 mt-1 ml-2 border border-opacity-100';
    const cphTabs = '';
    //Tailwind ------------------------------------------------------------

  return (
    <div className=''>



        <div className='flex flex-col'> 
            
            <input type="checkbox" id={`credenciamento-${processo._id}`} className='propostaCb hidden' checked={extraTab === ArquivosProcessoExtra.CREDENCIAMENTO ? true : false}/>
            <label htmlFor={`credenciamento-${processo._id}`} className='w-full bg-gray-200 dark:bg-gray-600 cursor-pointer opacity-80 hover:opacity-100 inline-flex' onClick={()=> extraTab === ArquivosProcessoExtra.CREDENCIAMENTO ? setExtraTab(ArquivosProcessoExtra.IDLE) :setExtraTab(ArquivosProcessoExtra.CREDENCIAMENTO)}> 
                <BsPlus className='propostaIcon1'/> 
                <BsDash className='propostaIcon2'/> 
            Credenciamento 
            <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mt-1">
                {countArquivosTimeline(ArquivosProcessoExtra.CREDENCIAMENTO)}
            </span>
            </label>

        
            <div className='propostaContainer'>
                <div className='propostaWrapper'>
                    {
                        extraTab === ArquivosProcessoExtra.CREDENCIAMENTO && arquivosCredenciamento ?
                        <div className=''><BottonProcessosExtrasArquivos processo ={processo} arquivos ={timelineData} modalidade ={'Credenciamento'}/>
                        </div>
                        :
                        <label> Nenhum arquivo encontrado.</label>

                    }
                </div>
            </div>

        </div>


        <div className='flex flex-col'> 
            
            <input type="checkbox" id={`propostacb-${processo._id}`} className='propostaCb hidden' checked={extraTab === ArquivosProcessoExtra.PROPOSTA ? true : false} />
            <label htmlFor={`propostacb-${processo._id}`} className='w-full bg-gray-200 dark:bg-gray-600 cursor-pointer opacity-80 hover:opacity-100 inline-flex' onClick={()=> extraTab === ArquivosProcessoExtra.PROPOSTA ? setExtraTab(ArquivosProcessoExtra.IDLE) :setExtraTab(ArquivosProcessoExtra.PROPOSTA)}> 
                <BsPlus className='propostaIcon1'/> 
                <BsDash className='propostaIcon2'/> 
            Proposta 
      
            <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mt-1">
                {countArquivosTimeline(ArquivosProcessoExtra.PROPOSTA) + itens}
            </span>
              
            
            </label>

            

            <div className='propostaContainer'>
                
                <div className='propostaWrapper overflow-y-scroll max-h-52'>

                    <input className='extraArquivoCb hidden' type="radio" name="propostaRadio" id={`propostaArquivoRadio-${processo._id}`} defaultChecked={true}/>
                    <input className='extraItemCb hidden' type="radio" name="propostaRadio" id={`propostaItemRadio-${processo._id}`} />
                    
                    <div className='w-full ml-2 flex flex-row gap-4 '>
                        <label className='font-semibold cursor-pointer opacity-85 hover:opacity-100' htmlFor={`propostaItemRadio-${processo._id}`}> Itens <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mt-1"> {itens}</span> </label>

                        <label className='font-semibold cursor-pointer opacity-85 hover:opacity-100' htmlFor={`propostaArquivoRadio-${processo._id}`}> Arquivos  <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mt-1">{countArquivosTimeline(ArquivosProcessoExtra.PROPOSTA)}</span> </label>
                        
                    </div>

                    <div className='extraItem  '>
                        <CaptacaoItens url ={processo.LinkItensPncp} total ={changeItemNumber}/>
                    </div>
                    <div className='extraArquivo '>
                        {
                            extraTab === ArquivosProcessoExtra.PROPOSTA &&<BottonProcessosExtrasArquivos processo ={processo} arquivos ={timelineData} modalidade = {'Proposta'}/>
                        }
                        
                    </div>


                </div>
            </div>

        </div>

        <div className='flex flex-col'> 
            
            <input type="checkbox" id={`habilitacaojcb-${processo._id}`} className='propostaCb hidden' checked={extraTab === ArquivosProcessoExtra.HABILITACAOJURIDICA ? true : false}/>
            <label htmlFor={`habilitacaojcb-${processo._id}`} className='w-full bg-gray-200 dark:bg-gray-600 cursor-pointer opacity-80 hover:opacity-100 inline-flex' onClick={()=> extraTab === ArquivosProcessoExtra.HABILITACAOJURIDICA ? setExtraTab(ArquivosProcessoExtra.IDLE) :setExtraTab(ArquivosProcessoExtra.HABILITACAOJURIDICA)}> 
                <BsPlus className='propostaIcon1'/> 
                <BsDash className='propostaIcon2'/> 
            Habilitação Jurídica
            <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mt-1">
                {countArquivosTimeline(ArquivosProcessoExtra.HABILITACAOJURIDICA)}
            </span>
            </label>

        
            <div className='propostaContainer'>
                <div className='propostaWrapper'>
                    <div className=''>
                        { extraTab === ArquivosProcessoExtra.HABILITACAOJURIDICA &&
                        <BottonProcessosExtrasArquivos processo ={processo} arquivos ={timelineData} modalidade = {'Habilitação jurídica'}/>
                        }
                    </div>
                </div>
            </div>

        </div>

        <div className='flex flex-col'> 
            
            <input type="checkbox" id={`seguridadeFeT-${processo._id}`} className='propostaCb hidden' checked={extraTab === ArquivosProcessoExtra.SEGURIDADEFET ? true : false}/>
            <label htmlFor={`seguridadeFeT-${processo._id}`} className='w-full bg-gray-200 dark:bg-gray-600 cursor-pointer opacity-80 hover:opacity-100 inline-flex' onClick={()=> extraTab === ArquivosProcessoExtra.SEGURIDADEFET ? setExtraTab(ArquivosProcessoExtra.IDLE) :setExtraTab(ArquivosProcessoExtra.SEGURIDADEFET)}> 
                <BsPlus className='propostaIcon1'/> 
                <BsDash className='propostaIcon2'/> 
                Regularidade Fiscal e Trabalhista
                <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mt-1">
                {countArquivosTimeline(ArquivosProcessoExtra.SEGURIDADEFET)}
                </span>
            </label>

        
            <div className='propostaContainer'>
                <div className='propostaWrapper'>
                    <div className=''>
                        {
                            extraTab === ArquivosProcessoExtra.SEGURIDADEFET &&
                            <BottonProcessosExtrasArquivos processo ={processo} arquivos ={timelineData} modalidade ={'Seguridade Fiscal e Trabalhista'}/>
                        }
                    </div>

                </div>
            </div>

        </div>
        
        <div className='flex flex-col'> 
            
            <input type="checkbox" id={`qualificaçãoT-${processo._id}`} className='propostaCb hidden' checked={extraTab === ArquivosProcessoExtra.QUALIFICACAOT ? true : false}/>
            <label htmlFor={`qualificaçãoT-${processo._id}`} className='w-full bg-gray-200 dark:bg-gray-600 cursor-pointer opacity-80 hover:opacity-100 inline-flex' onClick={()=> extraTab === ArquivosProcessoExtra.QUALIFICACAOT ? setExtraTab(ArquivosProcessoExtra.IDLE) :setExtraTab(ArquivosProcessoExtra.QUALIFICACAOT)}> 
                <BsPlus className='propostaIcon1'/> 
                <BsDash className='propostaIcon2'/> 
                Qualificação Técnica
                <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mt-1">
                {countArquivosTimeline(ArquivosProcessoExtra.QUALIFICACAOT)}
                </span>
            </label>

        
            <div className='propostaContainer'>
                <div className='propostaWrapper'>
                    <div className=''>
                        {
                            extraTab === ArquivosProcessoExtra.QUALIFICACAOT &&
                            <BottonProcessosExtrasArquivos processo ={processo} arquivos ={timelineData} modalidade = {'Qualficação Técnica'}/>
                        }
                    </div>
                </div>
            </div>

        </div>

       
        <div className='flex flex-col'> 
            
            <input type="checkbox" id={`qef-${processo._id}`} className='propostaCb hidden' checked={extraTab === ArquivosProcessoExtra.QUALIFICACAOEF ? true : false}/>
            <label htmlFor={`qef-${processo._id}`} className='w-full bg-gray-200 dark:bg-gray-600 cursor-pointer opacity-80 hover:opacity-100 inline-flex' onClick={()=> extraTab === ArquivosProcessoExtra.QUALIFICACAOEF ? setExtraTab(ArquivosProcessoExtra.IDLE) :setExtraTab(ArquivosProcessoExtra.QUALIFICACAOEF)}> 
                <BsPlus className='propostaIcon1'/> 
                <BsDash className='propostaIcon2'/> 
                Qualificação Econômica Financeira
                <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mt-1">
                {countArquivosTimeline(ArquivosProcessoExtra.QUALIFICACAOEF)}
                </span>
            </label>

        
            <div className='propostaContainer'>
                <div className='propostaWrapper'>
                    <div className=''>
                        {
                            extraTab === ArquivosProcessoExtra.QUALIFICACAOEF &&
                            <BottonProcessosExtrasArquivos processo ={processo} arquivos ={timelineData} modalidade={'Qualificação econômica financeira'}/>
                        }

                    </div>

                </div>
            </div>

        </div>
        
        <div className='flex flex-col'> 
            
            <input type="checkbox" id={`declaracoes-${processo._id}`} className='propostaCb hidden' checked={extraTab === ArquivosProcessoExtra.DECLARACAO ? true : false}/>
            <label htmlFor={`declaracoes-${processo._id}`} className='w-full bg-gray-200 dark:bg-gray-600 cursor-pointer opacity-80 hover:opacity-100 inline-flex' onClick={()=> extraTab === ArquivosProcessoExtra.DECLARACAO ? setExtraTab(ArquivosProcessoExtra.IDLE) :setExtraTab(ArquivosProcessoExtra.DECLARACAO)}> 
                <BsPlus className='propostaIcon1'/> 
                <BsDash className='propostaIcon2'/> 
                Declarações
                <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mt-1">
                {countArquivosTimeline(ArquivosProcessoExtra.DECLARACAO)}
                </span>
            </label>

        
            <div className='propostaContainer'>
                <div className='propostaWrapper'>
                    <div className=''>
                        {
                            extraTab === ArquivosProcessoExtra.DECLARACAO &&
                            <BottonProcessosExtrasArquivos processo ={processo} arquivos ={timelineData} modalidade={'Declaração' }/>
                        }
                    </div>
                </div>
            </div>
        </div>
        
        <div className='flex flex-col'> 
            
            <input type="checkbox" id={`outros-${processo._id}`} className='propostaCb hidden' checked={extraTab === ArquivosProcessoExtra.OUTROS ? true : false}/>
            <label htmlFor={`outros-${processo._id}`} className='w-full bg-gray-200 dark:bg-gray-600 cursor-pointer opacity-80 hover:opacity-100 inline-flex' onClick={()=> extraTab === ArquivosProcessoExtra.OUTROS ? setExtraTab(ArquivosProcessoExtra.IDLE) :setExtraTab(ArquivosProcessoExtra.OUTROS)}> 
                <BsPlus className='propostaIcon1'/> 
                <BsDash className='propostaIcon2'/> 
                Outros
                <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mt-1">
                {countArquivosTimeline(ArquivosProcessoExtra.OUTROS)}
                </span>
            </label>

        
            <div className='propostaContainer'>
                <div className='propostaWrapper'>
                    <div className=''>
                    {  
                        extraTab === ArquivosProcessoExtra.OUTROS &&
                        <BottonProcessosExtrasArquivos processo ={processo} arquivos ={timelineData} modalidade={'Outros'}/>
                    }
                    </div>
                </div>
            </div>

        </div>
        
    


    </div>
  )
}

export default BottonProcessoExtras