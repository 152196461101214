import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef } from 'react';
import { useReactQueryEmpresas } from '../Utils/ReactQuery/getEmpresas';
import LoadingComponent from './LoaadingComponent';
import { useReactQueryProcessos } from '../Utils/ReactQuery/getProcessosUtils';
import { SituaçãoInterna } from '../Utils/types/PortalTypes';

const SelecionarEmpresa = () => {
    const scrollRef = useRef<HTMLElement | any>(null);
    const { empresasPermitidas } = useReactQueryEmpresas();
    const token = localStorage.getItem('token');
    const { data: empresasDisponiveis, isLoading: empresasDisponiveisLoading } = useQuery({
        queryFn: () => empresasPermitidas(token as string),
        queryKey: ['rqEmpresasPermitidas'],
        staleTime: 1000 * 60 * 2,
    });

    const queryClient = useQueryClient();
    const {listarProcessos} = useReactQueryProcessos()

    const empresaSelecionada: any = localStorage.getItem('empresaSelecionada');

    const selecionarEmpresaMutation = useMutation({
        mutationFn: listarProcessos,
        onMutate: async(data)=>{

        },
        onSuccess: async(response) => {
            
            queryClient.invalidateQueries({ queryKey: ['rqProcessos']});
            queryClient.refetchQueries({ queryKey: ['rqProcessos']});
            
            queryClient.invalidateQueries({ queryKey: ['rqPrcessosInfo']});
            queryClient.refetchQueries({ queryKey:    ['rqPrcessosInfo']});
 
        },
        onSettled: () => {
             window.location.reload();
        },
    });

    const dispararAlterarEmpresa = async(e: any, empresaid: string, cnaePrincipal: any) => {
        e.preventDefault();

        if (empresaid) {
            localStorage.setItem('empresaSelecionada', empresaid);
            localStorage.setItem('cnaePrincipal', cnaePrincipal.descricao )
            selecionarEmpresaMutation.mutate({empresaid, pageParam: 1, filtro: SituaçãoInterna.REGISTRAR});
        }

    };

    if (empresasDisponiveisLoading) {
        return <LoadingComponent />;
    }

    return (
        <div className="w-full pb-1 pl-1">
            <div className="w-full flex flex-row items-center">
                <div
                    className="flex flex-row justify-between items-center w-full max-w-4xl overflow-x-auto h-full snap-x no-scrollbar"
                    ref={scrollRef}
                >
                    <div className="flex flex-row pt-1 pb-1 pl-1 gap-4 justify-around items-center w-fit">
                        {empresasDisponiveis.empresas &&
                            empresasDisponiveis.empresas.map((empresa: any) => {
                                const isSelected = empresaSelecionada === empresa._id;
                                return (
                                    <div
                                        key={empresa._id}
                                        id={empresa._id}
                                        onClick={(e) => dispararAlterarEmpresa(e, empresa._id, empresa.AtividadeEconômicaP)}
                                        className="cursor-pointer flex flex-col justify-center items-center"
                                    >
                                        <div className={isSelected ? 'scale-110 opacity-100' : 'opacity-50 hover:opacity-100'}>
                                            {empresa.LogoEmpresa ? (
                                                <img src={`${empresa.LogoEmpresa}`} alt="Empresa" className="rounded-full w-10 h-10 object-contain" />
                                            ) : (
                                                <div className="rounded-full w-10 h-10 flex justify-center items-center p-1 bg-gray-100 dark:bg-gray-700">
                                                    <span className="text-xs font-semibold text-gray-900 dark:text-white">{empresa.NomeEmpresa.slice(0, 2)}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelecionarEmpresa;
