import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { BsSearch, BsQuestionCircle } from 'react-icons/bs';
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
import { toast } from 'react-toastify';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';

const CaptacaoFiltro = ({ alterarFiltro }: any) => {

    const [filtroModalidade, setFiltroModalidade] = useState<string[]>([]);
    const [filtroUf, setFiltroUf] = useState<string[]>([]);
    const [instrumentoConvocatorio, setInstrumentoConvocatorio] = useState<string[]>([]);
    const [filtroEsfera, setFiltroEsfera] = useState<string[]>([]);
    const [filtroMontado, setFiltroMontado] = useState<string>('');
    const [isHovering, setIsHovering] = useState(true);
    const [palavraChave, setPalavraChave] = useState<string>('');


    enum Filtro {
        MODALIDADE = 'modalidade',
        UF = 'uf',
        INSTRUMENTO = 'instrumento',
        ESFERA = 'esfera',
        IDLE = 'idle'
    }

    const nomeModalidade = (data: string) => {
        switch (data) {
            case '6':
                return 'Pregão eletrônico';
            case '8':
                return 'Dispensa';
            case '9':
                return 'Dispensa de licitação';
            case '7':
                return 'Pregão presencial';
            case '12':
                return 'Credenciamento';
            case '4':
                return 'Concorrência eletrônica';
            case '5':
                return 'Concorrência presencial';
            default:
                return 'Selecione sua modalidade';
        }
    };

    const nomeEsfera = (data: string) => {
        switch (data) {
            case 'M':
                return 'Municipal';
            case 'E':
                return 'Estadual';
            case 'F':
                return 'Federal';
            case 'D':
                return 'Distrital';

            default:
                return 'Selecione as categorias';
        }
    };


    const nomeInstrumento = (data: string) => {
        switch (data) {
            case '1':
                return 'Edital';
            case '2':
                return 'Aviso de contratação direta';
            case '4':
                return 'Edital de chamamento público';
            default:
                return 'Selecione as categorias';
        }
    };

    const [filtroAtual, setFiltroAtual] = useState<Filtro>(Filtro.IDLE);
    const [helpFiltro, setHelpFiltro] = useState<boolean>(false)

    const modalidadeFiltro = (id: string) => {
        if (filtroModalidade.includes(id)) {
            setFiltroModalidade(filtroModalidade.filter((item) => item !== id));
        } else {
            setFiltroModalidade([...filtroModalidade, id]);
        }
    };

    const ufFiltro = (id: string) => {
        if (filtroUf.includes(id)) {
            setFiltroUf(filtroUf.filter((item) => item !== id));
        } else {
            setFiltroUf([...filtroUf, id]);
        }
    };

    const instrumentoConvFiltro = (id: string) => {
        if (instrumentoConvocatorio.includes(id)) {
            setInstrumentoConvocatorio(instrumentoConvocatorio.filter((item) => item !== id));
        } else {
            setInstrumentoConvocatorio([...instrumentoConvocatorio, id]);
        }
    };

    const handleEsferaFiltro = (id: string) => {
        if (filtroEsfera.includes(id)) {
            setFiltroEsfera(filtroEsfera.filter((item) => item !== id));
        } else {
            setFiltroEsfera([...filtroEsfera, id]);
        }
    };


    const { getCaptacaoAutomatica } = useReactQueryCaptacao();

    const queryClent = useQueryClient();
    const alterarFiltroMutaton = useMutation({
        mutationFn: getCaptacaoAutomatica,
        onMutate: (config) => {

        },
        onSuccess: async (response) => {
            console.log('Response da atualizaçãoo do filtro: ', response);
            queryClent.invalidateQueries({ queryKey: ['rqCaptacao'] });
            queryClent.refetchQueries({ queryKey: ['rqCaptacao'] });
        },
        onError: (error) => {
            console.error(error.message)
        },
        onSettled: (data) => {

        }
    })


    const empresaSelecionada = localStorage.getItem('empresaSelecionada');
    //--
    const limparFiltro = (e: any) => {
        e.preventDefault();
        setFiltroModalidade([]);
        setFiltroUf([]);
        setInstrumentoConvocatorio([]);
        setFiltroEsfera([]);
        alterarFiltro('');


        const cnaePrincipal = localStorage.getItem('cnaePrincipal')
        alterarFiltroMutaton.mutate({ descrição: cnaePrincipal as string, pagina: 1, tamanhoPagina: 2000 })
        localStorage.removeItem(`${empresaSelecionada}-filtro`);
    }

    const handleFitrarProcesso = (save: boolean) => {

        if (save) {
            toast.success('Filtro salvo para busca automática!')
        }


        let filtroUfString = '';
        if (filtroUf && filtroUf.length >= 1) {
            filtroUfString = filtroUf.reduce((sum: any, atual: any) => {
                return atual + "%7C" + sum
            });
            setFiltroMontado(`&ufs=${filtroUfString}`);
        }

        let filtroModalidadeString = '';
        if (filtroModalidade && filtroModalidade.length >= 1) {
            filtroModalidadeString = filtroModalidade.reduce((sum: any, atual: any) => {
                return atual + "%7C" + sum
            });
        }

        let filtroInstConvString = '';
        if (instrumentoConvocatorio && instrumentoConvocatorio.length >= 1) {
            filtroInstConvString = instrumentoConvocatorio.reduce((sum: any, atual: any) => {
                return atual + "%7C" + sum
            });
        }

        let filtroEsferaConvString = '';
        if (filtroEsfera && filtroEsfera.length >= 1) {
            filtroEsferaConvString = filtroEsfera.reduce((sum: any, atual: any) => {
                return atual + "%7C" + sum
            });
        }

        alterarFiltro(`${palavraChave}&ufs=${filtroUfString}&modalidades=${filtroModalidadeString}&tipos=${filtroInstConvString}&esferas=${filtroEsferaConvString}`, save);

    }

    const inputField = 'bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#ff8027] focus:border-[#ff8027] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-orange-500 dark:focus:border-orange-500';
    const labelField = 'block mb-2 text-sm font-medium text-gray-900 dark:text-white';
    const button = 'cursor-pointer text-white inline-flex items-center bg-[#ff8027] hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800 h-fit';
    const closeButton = 'cursor-pointer text-white inline-flex items-center bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit';

    return (
        <div className='w-full min-h-screen h-fit px-4 flex'>
            <div className='bg-white dark:bg-gray-700 rounded-md flex flex-col gap-4 h-full justify-between p-4'>
                <div className={`flex items-center ${inputField}`}>
                    <BsSearch className='text-xl text-gray-500 dark:text-gray-400 mr-2' />
                    <input
                        className={`w-full ${inputField}`}
                        type='text'
                        placeholder='Palavra-chave'
                        value={palavraChave}
                        onChange={(e) => setPalavraChave(e.target.value)}
                    />
                </div>

                {/* Modalidade de contratação */}
                <div className="relative shadow-sm hover:shadow-md transition-shadow duration-200 rounded-lg p-2 bg-white dark:bg-gray-700">
                    <label className="text-md font-semibold text-gray-900 dark:text-white">
                        Modalidade da contratação
                    </label>
                    <div className="relative mt-2">
                        <div
                            className="inline-flex w-full text-md cursor-pointer justify-between items-center text-gray-700 dark:text-gray-300 transition-opacity hover:opacity-90 p-2 rounded-lg bg-gray-100 dark:bg-gray-600 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-500"
                            onClick={() =>
                                filtroAtual === Filtro.MODALIDADE
                                    ? setFiltroAtual(Filtro.IDLE)
                                    : setFiltroAtual(Filtro.MODALIDADE)
                            }
                        >
                            {filtroModalidade.length <= 0
                                ? 'Selecione as modalidades'
                                : filtroModalidade.length === 1
                                    ? nomeModalidade(filtroModalidade[0])
                                    : `${nomeModalidade(filtroModalidade[0])} + ${filtroModalidade.length - 1}`}
                        </div>

                        {filtroAtual === Filtro.MODALIDADE && (
                            <div className="absolute top-full left-0 mt-2 w-full bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 transition-all duration-300">
                                <div className="max-h-52 overflow-y-auto p-2">
                                    {['6', '8', '9', '7', '12', '4', '5'].map((id) => (
                                        <div
                                            key={id}
                                            onClick={() => modalidadeFiltro(id)}
                                            className="flex items-center cursor-pointer text-gray-700 dark:text-gray-300 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors mb-1"
                                        >
                                            {filtroModalidade.includes(id) ? (
                                                <MdOutlineCheckBox className="mr-2" />
                                            ) : (
                                                <MdOutlineCheckBoxOutlineBlank className="mr-2" />
                                            )}
                                            {nomeModalidade(id)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* UFs */}
                <div className="relative shadow-sm hover:shadow-md transition-shadow duration-200 rounded-lg p-2 bg-white dark:bg-gray-700">
                    <label className="text-md font-semibold text-gray-900 dark:text-white">UFs</label>
                    <div className="relative mt-2">
                        <div
                            className="inline-flex w-full text-md cursor-pointer justify-between items-center text-gray-700 dark:text-gray-300 transition-opacity hover:opacity-90 p-2 rounded-lg bg-gray-100 dark:bg-gray-600 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-500"
                            onClick={() =>
                                filtroAtual === Filtro.UF ? setFiltroAtual(Filtro.IDLE) : setFiltroAtual(Filtro.UF)
                            }
                        >
                            {filtroUf.length <= 0
                                ? 'Selecione as UFs'
                                : filtroUf.length === 1
                                    ? filtroUf[0]
                                    : `${filtroUf[0]} + ${filtroUf.length - 1}`}
                        </div>

                        {filtroAtual === Filtro.UF && (
                            <div className="absolute top-full left-0 mt-2 w-full bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 transition-all duration-300">
                                <div className="max-h-52 overflow-y-auto p-2">
                                    {[
                                        'MG',
                                        'SP',
                                        'CE',
                                        'BA',
                                        'RS',
                                        'GO',
                                        'PR',
                                        'PB',
                                        'SC',
                                        'PE',
                                        'RJ',
                                        'RN',
                                        'PA',
                                        'PI',
                                        'MA',
                                        'ES',
                                        'MT',
                                        'TO',
                                        'AM',
                                        'DF',
                                        'SE',
                                        'MF',
                                        'AL',
                                        'RO',
                                        'RR',
                                        'AP',
                                        'AC',
                                    ].map((uf) => (
                                        <div
                                            key={uf}
                                            onClick={() => ufFiltro(uf)}
                                            className="flex items-center cursor-pointer text-gray-700 dark:text-gray-300 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors mb-1"
                                        >
                                            {filtroUf.includes(uf) ? (
                                                <MdOutlineCheckBox className="mr-2" />
                                            ) : (
                                                <MdOutlineCheckBoxOutlineBlank className="mr-2" />
                                            )}
                                            {uf}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Instrumento convocatório */}
                <div className="relative shadow-sm hover:shadow-md transition-shadow duration-200 rounded-lg p-2 bg-white dark:bg-gray-700">
                    <label className="text-md font-semibold text-gray-900 dark:text-white">
                        Instrumento Convocatório
                    </label>
                    <div className="relative mt-2">
                        <div
                            className="inline-flex w-full text-md cursor-pointer justify-between items-center text-gray-700 dark:text-gray-300 transition-opacity hover:opacity-90 p-2 rounded-lg bg-gray-100 dark:bg-gray-600 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-500"
                            onClick={() =>
                                filtroAtual === Filtro.INSTRUMENTO
                                    ? setFiltroAtual(Filtro.IDLE)
                                    : setFiltroAtual(Filtro.INSTRUMENTO)
                            }
                        >
                            {instrumentoConvocatorio.length <= 0
                                ? 'Selecione as categorias'
                                : instrumentoConvocatorio.length === 1
                                    ? nomeInstrumento(instrumentoConvocatorio[0])
                                    : `${nomeInstrumento(instrumentoConvocatorio[0])} + ${instrumentoConvocatorio.length - 1}`}

                        </div>

                        {filtroAtual === Filtro.INSTRUMENTO && (
                            <div className="absolute top-full left-0 mt-2 w-full bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 transition-all duration-300">
                                <div className="max-h-52 overflow-y-auto p-2">
                                    {[
                                        { id: '1', name: 'Edital' },
                                        { id: '2', name: 'Aviso de contratação direta' },
                                        { id: '4', name: 'Edital de chamamento público' },
                                    ].map((item) => (
                                        <div
                                            key={item.id}
                                            onClick={() => instrumentoConvFiltro(item.id)}
                                            className="flex items-center cursor-pointer text-gray-700 dark:text-gray-300 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors mb-1"
                                        >
                                            {instrumentoConvocatorio.includes(item.id) ? (
                                                <MdOutlineCheckBox className="mr-2" />
                                            ) : (
                                                <MdOutlineCheckBoxOutlineBlank className="mr-2" />
                                            )}
                                            {item.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Esfera */}
                <div className="relative shadow-sm hover:shadow-md transition-shadow duration-200 rounded-lg p-2 bg-white dark:bg-gray-700">
                    <label className="text-md font-semibold text-gray-900 dark:text-white">Esfera</label>
                    <div className="relative mt-2">
                        <div

                            className="inline-flex w-full text-md cursor-pointer justify-between items-center text-gray-700 dark:text-gray-300 transition-opacity hover:opacity-90 p-2 rounded-lg bg-gray-100 dark:bg-gray-600 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-500"
                            onClick={() =>
                                filtroAtual === Filtro.ESFERA ? setFiltroAtual(Filtro.IDLE) : setFiltroAtual(Filtro.ESFERA)
                            }
                        >
                            {filtroEsfera.length <= 0
                                ? 'Selecione a esfera'
                                : filtroEsfera.length === 1
                                    ? nomeEsfera(filtroEsfera[0])
                                    : `${nomeEsfera(filtroEsfera[0])} + ${filtroEsfera.length - 1}`}
                        </div>

                        {filtroAtual === Filtro.ESFERA && (
                            <div className="absolute top-full left-0 mt-2 w-full bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 transition-all duration-300">
                                <div className="max-h-52 overflow-y-auto p-2">
                                    {[
                                        { id: 'M', name: 'Municipal' },
                                        { id: 'E', name: 'Estadual' },
                                        { id: 'F', name: 'Federal' },
                                        { id: 'D', name: 'Distrital' },
                                    ].map((item) => (
                                        <div
                                            key={item.id}
                                            onClick={() => handleEsferaFiltro(item.id)}
                                            className="flex items-center cursor-pointer text-gray-700 dark:text-gray-300 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors mb-1"
                                        >
                                            {filtroEsfera.includes(item.id) ? (
                                                <MdOutlineCheckBox className="mr-2" />
                                            ) : (
                                                <MdOutlineCheckBoxOutlineBlank className="mr-2" />
                                            )}
                                            {item.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <BsQuestionCircle
                    className='w-5 h-5cursor-pointer text-gray-700 dark:text-gray-300 ml-2'
                    onClick={() => setHelpFiltro(current => !current)}
                />

                {helpFiltro && (
                    <div className='absolute -bottom-96 right-3/4 mt-2 z-50 max-w-xs w-48'>
                        <div className='bg-gray-100 dark:bg-gray-800 rounded-lg p-2 shadow-lg'>
                            <p className='text-[0.7rem] font-semibold text-gray-700 dark:text-gray-300'>
                                Ao clicar no botão "Aplicar e salvar filtro", o filtro desta pesquisa será utilizado nas buscas automáticas de editais.
                            </p>
                        </div>
                    </div>
                )}
                <div className='flex flex-col items-center w-full px-2 space-y-4'>

                    <div className='flex flex-row items-center w-full space-x-2'>
                        <button
                            className="cursor-pointer text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 flex-1"
                            onClick={limparFiltro}
                        >
                            Limpar
                        </button>

                        <button
                            className="cursor-pointer text-white bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-500 dark:hover:bg-orange-600 dark:focus:ring-orange-800 flex-1"
                            onClick={() => handleFitrarProcesso(false)}
                        >
                            Buscar
                        </button>
                    </div>

                    <div className='flex flex-row items-center w-full relative'>
                        <button
                            className="cursor-pointer text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-6 py-2.5 text-center w-full"
                            onClick={() => handleFitrarProcesso(true)}
                        >
                            Aplicar e salvar filtro
                        </button>

                        <div className='absolute right-4'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CaptacaoFiltro;
