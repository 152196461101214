
import React, { useState } from 'react'
import BottonProcessoExtras from './BottonProcessosExtra'
import { BsCaretDown } from 'react-icons/bs'


const DisplayBottonProcessosExtra = ({ processo }: any) => {


    const [exibirProcessosExtra, setExibirProcessosExtra] = useState<boolean>(false);

    return (
        <div className='w-full flex flex-col text-xs'>

            <label
                htmlFor={`processos-extra-${processo._id}`}
                className='inline-flex items-center justify-between bg-gray-500 text-white font-semibold my-2 w-fit px-2 py-1 rounded-md cursor-pointer  hover:opacity-100 transition duration-200'
                onClick={() => setExibirProcessosExtra(current => !current)}
            >
                C.P.H
                <BsCaretDown className={`ml-2 transition-transform duration-200 ${exibirProcessosExtra ? 'rotate-180' : ''}`} />
            </label>

            <input type="checkbox" id={`processos-extra-${processo._id}`} className='hidden' />

            <div className={`transition-all duration-300 ${exibirProcessosExtra ? 'max-h-fit' : 'max-h-0 overflow-hidden'}`}>
                <div className='p-2 bg-gray-200 dark:bg-gray-700 rounded-md'>
                    {exibirProcessosExtra && <BottonProcessoExtras processo={processo} />}
                </div>
            </div>

        </div>
    )


}

export default DisplayBottonProcessosExtra