import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import MiddleProcessos from './MiddleProcessos'
import BottonProcessos from './BottonProcessos'
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import { useQuery } from '@tanstack/react-query'
import { usePortaisUtils } from '../../Utils/ReactQuery/getPortais'

import ProcessosFerramentas from './ProcessosFerramentas'
import DisplayBottonProcessosExtra from './DisplayBottonProcessosExtra'
import FiltroGlobal from './FiltroGlobal'




const DisplayProcessos = ({ selecionarPortal, processos: ProcessosFake, globalFilter, usuarios, busca }: any) => {


    const [processos, setProcessos] = useState<any>('');


    // // //^^MANUTNÇÃO~~//
    // useEffect(()=>{
    //     console.log('Usuarios -------->: ', usuarios)
    // },[usuarios])

    // //^^MANUTNÇÃO~~//

    
    useEffect(() => {
        setProcessos(ProcessosFake)
    }, [ProcessosFake])

    const [empresaSelecionada, setEmpresaSelecionada] = useState<any>('');
    const [idEmpresaSelecionada, ssetIdEmpresaSelecionada] = useState<string | null>(localStorage.getItem('empresaSelecionada') || null);
    const [portais, setPortais] = useState<any>('')
    const { selecionarEmpresa } = useReactQueryEmpresas();
    const { getPortais } = usePortaisUtils()
    const token = localStorage.getItem('token');

    useLayoutEffect(() => {
        ssetIdEmpresaSelecionada(localStorage.getItem('empresaSelecionada'))
    })

    const { data: empresaData, isLoading: isLoadingEmpresa } = useQuery({ queryFn: () => selecionarEmpresa({ token: token as string, empresaid: idEmpresaSelecionada as string }), queryKey: ['rqEmpresa', idEmpresaSelecionada] });


      // Empresas permitidas---------------------
        const {empresasPermitidas} = useReactQueryEmpresas();
        const {data: empresasData} = useQuery({
            queryFn: ()=> empresasPermitidas(token as string),
            queryKey: ['rqEmpresasPermitidas']
        })



    // Empresas permitidas---------------------

    const { data: portaisData, isLoading: portaisIsLoading } = useQuery({ queryFn: () => getPortais({empresaid: idEmpresaSelecionada as string}), queryKey: ['rqPortais'] })



    useEffect(() => {
        if (empresaData) {
            setEmpresaSelecionada(empresaData.empresa);
        }
        if (portaisData) {
            setPortais(portaisData.portais)
        }
    }, [empresaData, portaisData])

   


    return (
        <div >
            <div className='w-full min-h-60 h-fit flex flex-col gap-5' >
                {
                    processos && processos.map((processo: any) => (
                        <div key={processo._id} className='w-full py-1 dark:bg-gray-700 bg-white shadow-md rounded-md px-4'>
                            
                            <MiddleProcessos processo={processo} empresa={empresaSelecionada} portais={portais} selecionarPortal={selecionarPortal} globalFilter={globalFilter} usuarios ={usuarios} empresas={empresasData}/>
                
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default DisplayProcessos