import { url } from "inspector";


export const useReactQueryCaptacao =()=>{

    const pncpBaseUrl = 'https://pncp.gov.br/api/search';
    const pncpBaseApiUrl = 'https://pncp.gov.br/api/pncp/v1/orgaos';


    type TCaptacaoFiltros ={

    }

    type TCaptacaooAutomatica={
        descrição: string,
        url?: string,
        filros?: TCaptacaoFiltros,
        pagina: number,
        tamanhoPagina: number
    }

    const getCaptacaoAutomatica =async(data: TCaptacaooAutomatica)=>{

        try {
            if(data.url){
                const res = await fetch(data.url);
                const response = await res.json();
                return response;
            }

            const url = `${pncpBaseUrl}/?q=${data.descrição}&tipos_documento=edital&ordenacao=-data&pagina=${data.pagina}&tam_pagina=${data.tamanhoPagina}&status=recebendo_proposta`
  
            const res = await fetch(url);
            const response = await res.json();

            return response;



        } catch (error) {
            console.error(error);
            return null;
        }

    }

    const getCaptacaoArquivos =async(url: string)=>{
        try {
            const res = await fetch(url);
            const response =  await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const getCaptacaoItens =async(url: string)=>{
        
        try {
            const res = await fetch(url);
            const response =  await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }


    }

    return{
        getCaptacaoAutomatica,
        getCaptacaoArquivos,
        getCaptacaoItens
    }

}