import React, { useEffect, useLayoutEffect, useState } from 'react'
import CaptacaoFiltro from './CaptacaoFiltro'
import CaptacaoDisplay from './CaptacaoDisplay'
import NovoProcesso from '../ProcessosComponents/NovoProcesso'
import { BsPencil, BsQuestionCircle } from 'react-icons/bs'
import { useUserContext } from '../../Context/useUserContext'
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import LoadingPage from '../LoadingPage'
import { useInView } from 'react-intersection-observer'
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import { toast } from 'react-toastify'
import CaptaçãoAcessoAviso from './CaptaçãoAcessoAviso'
import LoadingSmall from '../LoadingSmall'
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";



const Captacao = () => {
  const [captacaoManual, setCaptacaoManual] = useState<boolean>(false);
  const [pagina, setPagina] = useState<number>(1);
  const { ref, inView } = useInView();
  const [itensPorPagina, setItensPorPagina] = useState<number>(20);
  const [totalItens, setTotalItens] = useState<number>(0);

  const [paginationUpLimit, setPaginationUpLimit] = useState<number>(20);
  const [paginationDnLimit, setPaginationDnLimit] = useState<number>(0);
  const [totalPaginas, setTotalPaginas] = useState<number>(0)

  const token = localStorage.getItem('token');
  const empresaSelecionada = localStorage.getItem('empresaSelecionada');
  const { getArmazenamentoEmpresa, alterarEmpresa, selecionarEmpresa } = useReactQueryEmpresas()

  const { data: armazenamentoData, isLoading } = useQuery({
    queryFn: () => getArmazenamentoEmpresa(token as string, empresaSelecionada as string),
    queryKey: ['rqArmazenamentoEmpresa'],
    refetchOnWindowFocus: false
  })


  // useEffect(() => {
  //   if (inView) {
  //     setPagina(pagina + 10);
  //   }
  // }, [inView]);

  const fechar = () => {
    setCaptacaoManual(false);
  }

  const [userContext] = useUserContext();
  const cnaePrincipal = localStorage.getItem('cnaePrincipal');
  const [filtroAtual, setFiltroAtual] = useState<string>(encodeURI(cnaePrincipal as string) || '');


  const { getCaptacaoAutomatica } = useReactQueryCaptacao();
  const { data: captacaoData, isLoading: captacaoIsLoading, refetch } = useQuery({
    queryFn: () => getCaptacaoAutomatica({ descrição: filtroAtual as string, pagina: pagina, tamanhoPagina: itensPorPagina }),
    queryKey: ["rqCaptacao", filtroAtual, pagina],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10
  });

  const { data: filtroData, isLoading: filtroisLoading } = useQuery({ queryFn: () => selecionarEmpresa({ empresaid: empresaSelecionada as string, token: token as string }), queryKey: ['rqFiltroCaptacao'] });

  if (filtroData) {
    console.log('Filtro data:', filtroData)
  }

  useEffect(() => {
    if (captacaoData) {
      setTotalItens(captacaoData.total)
      setTotalPaginas(captacaoData.total / itensPorPagina)

    }

  }, [captacaoData])

  useLayoutEffect(() => {


    const filtroEmpresa = localStorage.getItem(`${empresaSelecionada}-filtro`);

    if (filtroEmpresa) {
      setFiltroAtual(filtroEmpresa)
    } else {
      setFiltroAtual(cnaePrincipal as string)
    }


  }, []);

  // // //~MANUUTENÇÃO~~//
  // useEffect(()=>{
  //   console.log('Filtro atual:    ', filtroAtual)
  // },[filtroAtual])
  // // //~MANUUTENÇÃO~~//

  // Mutação ----------------------
  //---------------------------   
  // Alterar filtro mutação
  const queryClent = useQueryClient();
  const alterarFiltroMutaton = useMutation({
    mutationFn: getCaptacaoAutomatica,
    onMutate: (config) => {
    },
    onSuccess: async (response) => {
      // console.log('Response da atualizaçãoo do filtro: ', response);
      queryClent.invalidateQueries({ queryKey: ['rqCaptacao'] });
      queryClent.refetchQueries({ queryKey: ['rqCaptacao'] });
    },
    onError: (error) => {
      console.error(error.message)
    },
    onSettled: (data) => {

    }
  })
  //---------------------------
  // Mutação ----------------------

  const alterarFiltro = (filtro: string, save: false) => {

    console.log('Save: ', save)
    //alterarFiltroMutaton.mutate({descrição: filtro, pagina: 1})


    if (save) {
      //localStorage.setItem('filtroSalvo', filtro)
      localStorage.setItem(`${empresaSelecionada}-filtro`, filtro)
    }


    if (filtro && filtro.length > 1) {
      setFiltroAtual(filtro);
    } else {
      setFiltroAtual(cnaePrincipal as string);
    }
  }

  //------------------------------
  // Carregar filtro customizado da empresa

  //----------------------------

  // Estilizações Tailwind ajustadas
  const activePage = 'w-full min-h-screen grid grid-cols-[300px_minmax(400px,_1fr)] gap-6';
  const inactivePage = 'w-full min-h-screen grid grid-cols-[300px_minmax(400px,_1fr)] gap-6 blur-sm';

  if (armazenamentoData && armazenamentoData.planoDescrição === "inativo") {
    return (
      <CaptaçãoAcessoAviso />
    )
  }
  if (armazenamentoData && armazenamentoData.planoDescrição === "visitante") {
    return (
      <CaptaçãoAcessoAviso />
    )
  }

  const handleTotalItens = () => {

    if (totalItens < 0) {
      return <p> Nenhum item a ser exibido!</p>
    }

    const paginas = (totalItens / itensPorPagina);

    if (paginas < 1) {
      return <></>
    }

    let tempPagination = [];

    for (let index = 0; index < paginas; index++) {
      tempPagination.push(index);
    }

    return <div className='flex flex-row justify-between w-full max-w-6xl overflow-x-auto bg-gray-100 rounded-md'>
      {totalPaginas > 40 && (
        <label
          className='flex items-center cursor-pointer opacity-75 hover:opacity-100'
          onClick={() => handlePagination('dn')}
        >
          <MdKeyboardArrowLeft className='mr-1' />
          Voltar
        </label>
      )}

      {tempPagination
        .filter(i => i > paginationDnLimit && i <= paginationUpLimit)
        .map(i => (
          <p
            key={i}
            className={
              pagina === i
                ? 'cursor-pointer w-fit font-semibold'
                : 'cursor-pointer w-fit opacity-75 hover:opacity-100'
            }
            onClick={() => setPagina(i)}
          >
            {i}
          </p>
        ))
      }
      {totalPaginas > 20 && (
        <label
          className='flex items-center cursor-pointer opacity-75 hover:opacity-100'
          onClick={() => handlePagination('upp')}
        >
          Avançar
          <MdKeyboardArrowRight className='ml-1 mt-0.5' />
        </label>
      )}
    </div>
  }

  const handlePagination = (action: 'upp' | 'dn') => {

    if (action === 'upp' && paginationUpLimit <= (totalPaginas - 1)) {
      setPaginationUpLimit(paginationUpLimit + itensPorPagina);
      setPaginationDnLimit(paginationDnLimit + itensPorPagina)
    }
    if (action === 'dn' && paginationDnLimit > 0 && paginationUpLimit >= itensPorPagina) {
      setPaginationUpLimit(paginationUpLimit - itensPorPagina);
      setPaginationDnLimit(paginationDnLimit - itensPorPagina)
    }

  }

  return (
    <div className='w-full mt-4 bg-white rounded-lg shadow-lg'>
      {captacaoManual && <NovoProcesso fechar={fechar} />}

      <div className='w-full flex flex-col pl-6 pr-4 mb-1 py-4'>
        <div className='flex flex-col'>
          <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Captação automática</h3>
          <p className='text-base text-gray-700 dark:text-gray-400 pt-2'>Processos encontrados no PNCP de acordo com a atividade econômica primária da empresa (CNAE). Total de editais encontrados: {totalItens} / {Math.floor(totalPaginas + 1)}</p>
        </div>
      </div>

      <div className={captacaoManual ? inactivePage : activePage}>
        <div className='flex flex-col gap-2 py-4'>
          <div className='flex flex-col items-start ml-10 justify-center '>
            <label
              className="hover:opacity-100 cursor-pointer inline-flex items-center text-sm text-white bg-[#ff8027] hover:bg-orange-700 rounded-lg px-4 py-2 transition-colors duration-200"
              onClick={() => setCaptacaoManual(true)}
            >
              <BsPencil className="mr-2" />
              Registro Manual
            </label>
          </div>
          <CaptacaoFiltro alterarFiltro={alterarFiltro} />
        </div>

        <div className='flex flex-col gap-2 py-7'>
          <div className='overflow-y-auto flex flex-col relative chart min-h-screen h-screen'>

            {captacaoData && !isLoading ? <div className='flex flex-col justify-between '>
              <CaptacaoDisplay editais={captacaoData} />
            </div> : <div className="text-center text-sm text-gray-500 ">Carregando mais processos...</div>}

            {
              captacaoData && captacaoData.items.length <= 1 &&
              <h3> Nenhum edital encontado na busca!</h3>
            }

          </div>
          {<div className='w-full bg-white'>{handleTotalItens()}</div>}
        </div>
      </div>
    </div>
  );
}
export default Captacao;
