import { useEffect,  useRef,  useState } from 'react'
import { formatarData } from '../../Utils/formatarData'
import { PiNavigationArrow } from "react-icons/pi";
import { useUserContext } from '../../Context/useUserContext';
import { TchatMessage, TchatUsuario } from '../../Utils/types/comentariosTypes';
import { socket } from '../../Utils/useSocketClient';
import { useQuery } from '@tanstack/react-query';
import { useReactQueryComentariosUtils } from '../../Utils/ReactQuery/getComentariosUtils';

const DisplayChat = ({processo, noArquivo}: any) => {


    const [comentariosProcesso, setComentariosProcesso] = useState<any>([]);
    const [text, setText] = useState<string>('');
    const [userContext] = useUserContext();
    const token = localStorage.getItem('token')



    const [userData, setUserData] = useState<TchatUsuario>({
        nome: userContext.User.Nome as string,
        cargoFuncao: `-${userContext.User.CargoFuncao}`,
        foto: userContext.User.Foto,
        id: userContext.User.id
    });

    const [message, setMessage] = useState<TchatMessage>(({
        processoId: processo._id,
        texto: '',
        token: token as string,
        usuario: userData
    }));



    const {getMessages} = useReactQueryComentariosUtils()
    const {data: comentariosData, isLoading: comentariosIsLoading, refetch} = useQuery({
        queryFn: ()=> getMessages(token as string, processo._id),
        queryKey: [`rqComentarios-${processo._id}`, processo]
    })

    useEffect(()=>{
        if(comentariosData){
            setComentariosProcesso(comentariosData.comentarios)
        }
    },[comentariosData])


    // //~~ MANUTENÇÃO ~~//


    // //~~ MANUTENÇÃO ~~//



    

    useEffect(()=>{


        const updateComentarios = (data: any)=>{
            setComentariosProcesso((comentariosProcesso: any)=> {
                return [...comentariosProcesso, data.data]
            })

            refetch()
        }

        socket.on('comentarioAtualizado', updateComentarios)
        setText('')

        return ()=>{
            socket.off('comentarioAtualizado',updateComentarios)
        }
    },[socket])


    const inputRef = useRef<HTMLInputElement | any>(null)
    
    const enviarComentario =async(e: any)=>{

        e.preventDefault();
     
        socket.emit('enviarComentario', {data: {
            processoId: message.processoId,
            texto: text,
            token: message.token,
            usuario: message.usuario
        }})

        inputRef.current.value = '';
        setText('')
    
    }


    //Tailwind ---------------------------------------
    const displayArquivo = 'w-full h-full dark:bg-gray-600 bg-gray-200 flex flex-col gap-6 pl-2 mt-4 pt-4 overflow-auto mx-2'
    const displayProcessos = 'w-full h-52 dark:bg-gray-700 bg-gray-200 flex flex-col gap-6 pl-2 rouded-t-xl pt-4 overflow-auto'
    
    //Tailwind ---------------------------------------



    const findUserAvatar = (user: any)=>{
       
        try {
            switch(true){
                case user.foto.includes("https") :
                    return user.foto
                case user.oldDescartMed && !user.foto.includes("amazonaws"): 
                return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/avatarUsers/${user.foto}`
                case user.foto && user.foto.includes('amazonaws'):
                    return user.foto;
                case user.foto && !user.foto.includes("amazonaws"):
                    return `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/avatarUsers/${user.foto}`
                default:
                     return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/avatar2.png`
            }
        } catch (error) {
            console.error(error);
            return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/avatar2.png`

        }
      
    }

  return (
    <div className='h-full flex flex-col justify-end px-4'>
        <div className={noArquivo ?`${displayArquivo}` : `${displayProcessos}`}>
            <div className='h-screen overflow-y-auto'>
                {
                    comentariosProcesso && comentariosProcesso.filter((i:any)=>{
                        return i.processoId === processo._id}).map((comentario: any)=>(
                        <div className="flex items-start gap-2.5 ">
                            <div className='w-12 h-12 origin-center object-contain rounded-full'>
                                {
                                    comentario.usuario !== undefined && comentario.usuario.foto ?
                                    <img className="w-full h-full rounded-full" src={findUserAvatar(comentario.usuario)} alt="Jese image"/>
                                    :
                                    <div className='w-8 h-8 rounded-full flex justify-center items-center bg-green-500'>
                                        <span>{comentario.usuario.nome.slice(0, 1)}</span>
                                    </div>
                                }
                            </div>
                                <div className="flex flex-col w-full max-w-[320px] leading-1.5  border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-800 p-2 m-0 my-2">
                                    <div className="flex items-center space-x-2 rtl:space-x-reverse">
                                        <span className="text-sm font-semibold text-gray-900 dark:text-white">{comentario.usuario.nome} <span> {comentario.usuario.cargoFuncao}</span></span>
        
                                    </div>
                                    
                                    <p className="text-sm font-normal py-1.5 text-gray-900 dark:text-white">{comentario.texto} </p>
                                    
                                    <div className='w-full  justify-end items-end text-right text-sm p-0 m-0'>
                                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400 chatDate">{formatarData(comentario.createdAt)}</span>
                                    </div>

                                </div>
        
                        </div>
                    ))
                }
            </div>
        
            
        </div>
        <div className='fiexd bottom-2 left-0 mb-2'>
                <div className='grid grid-cols-[95%_auto]' >
                <input type="text" name='texto' className='bg:gray-200 dark:bg-gray-600 rounded-2xl ' onChange={(e)=> setText(e.target.value)} value={text} ref={inputRef}/>
                <button type='submit'><PiNavigationArrow className='rotate-diagonal text-4xl cursor-pointer opacity-80 hover:opacity-100 transition-opacity' onClick={enviarComentario}/></button></div>
        </div>
    </div>
  )
}

export default DisplayChat