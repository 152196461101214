import React from 'react'
import AssessoriaGerenciarAssessorias from './AssessoriaGerenciarAssessorias'
import { AdminTab } from '../../Utils/types/adminTypes'
import { Link } from 'react-router-dom'
import AssesssoriaSolicitaçõesAssessoria from './AssessoriaSolicitaçõesAssessoria'

type Props = {}

const AssessoriaAssessorias = ({activeTab}: any) => {
    return (
        <div className='grid justify-start grid-cols-[250px_auto]'>

            <div className='w-full'>
                <div className='fixed'>
                    <label htmlFor="">Assessorias / {activeTab === AdminTab.ASSESSORIASOLICITACOES ? 'Solicitações' : 'Gerenciamento'}</label>
                    <div className='flex flex-col pt-5 gap-1'>
                        <Link to={'/portal/admin?area=assessoriasolicitacoes&secao=assessorias'} className={activeTab === AdminTab.ASSESSORIASOLICITACOES ? 'opacity-80 hover:opacity-100 cursor-pointer font-semibold' : 'opacity-80 hover:opacity-100 cursor-pointer' }>Solicitações</Link>
                        <Link to={'/portal/admin?area=assessoriagerenciamento&secao=assessorias'} className={'hover:opacity-100 cursor-pointer font-bold' }>Gerenciar assessorias</Link>
                    </div>
                </div>
            </div>


            <div className='w-full'>
                {
                    activeTab === AdminTab.ASSESSORIASOLICITACOES &&
                
                        <AssesssoriaSolicitaçõesAssessoria  />
                    
                }
                {
                    activeTab === AdminTab.ASSESSORIAGERENCIAMENTO &&
                    <div>
                        <AssessoriaGerenciarAssessorias />
                    </div>
                }
                
            </div>
        </div>
    )
}

export default AssessoriaAssessorias