import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { BsEye, BsEyeSlash, BsFolder, BsPencil, BsPlus, BsTrash, BsX } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatarData } from '../../Utils/formatarData';
import { usePortaisUtils } from '../../Utils/ReactQuery/getPortais';
import { subTypeUpdatePortal } from '../../Utils/types/portaisTypes';
import LoadingPage from '../LoadingPage';
import PortaisExcluir from './PortaisExcluir';
import EditarLogoPortais from './EditarLogoPortais';
import PortaisPastas from './PortaisPastas';
import { RiInboxArchiveLine, RiInboxUnarchiveLine } from 'react-icons/ri';




const PortalObj = ({ portalSelecionado, criarPortal, fechar }: any) => {

  const [showSenhaPortal, setShowSenhaPortal] = useState<boolean>(false);

  const [editar, setEditar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [arquivo, setArquivo] = useState<string>('');
  const [confirmarExcluir, setConfirmarExcluir] = useState<boolean>(false);
  const [editarLogoPortal, setEditarLogoPortal] = useState<boolean>(false);

  enum PortalTab{
    RESUMO = 'Resumo',
    PASTA = 'Pasta'
  }

  const [portalTab, setPortalTab] = useState<PortalTab>(PortalTab.RESUMO);

  const [empresaPortal, setEmpresaPortal] = useState<string>('');
  const [NomePortal, setNomePortal] = useState<string>('');
  const [linkPortal, setLinkPortal,] = useState<string>('')
  const [loginPortal, setLoginPortal] = useState<string>('')
  const [senhaPortal, setSenhaPortal] = useState<string>('')
  const [dataCadastroPortal, setDataCadastroPortal] = useState<string>('')
  const [dataVencimentoPortal, setDataVencimentoPortal] = useState<string>('')
  const [valorPortal, setValorPortal] = useState<string>('');
  const [categoria, setCategoria] = useState<string>('');


  // Tailwind ----------------------------------- 
  const inputField = "bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";

  const portalAtivo = " overflow-y-auto overflow-x-hidden  justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full";
  const portalInativo = " overflow-y-auto overflow-x-hidden  justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full opacity-70 blur-sm"
  // Tailwind ----------------------------------- 


  //Mutação --------------------------------------
  const queryClient = useQueryClient();
  const { updatePortal } = usePortaisUtils();

  const updatePortalMutation = useMutation({
    mutationFn: updatePortal,
    onMutate: async (config) => {
      setLoading(true);
  // console.log('Mutação dos portais iniciada!');

    },
    onSuccess: async (response) => {
      
      // console.log('Mutação dos portais iniciada!');
      queryClient.invalidateQueries({ queryKey: ["rqPortais"] });
      queryClient.fetchQuery({ queryKey: ["rqPortais"] });
      queryClient.refetchQueries({ queryKey: ["rqPortais"] });

      if (response.status === 1) {
        toast.success('Portal atualizado com sucesso!');
        setEditar(false);
      } else {
        toast.error(response.msg);
        setLoading(false);
      }

    },
    onError: (error) => {
      console.error(error.message);
      toast.error(error.message)
    },
    onSettled: async (data) => {
      // console.log('Mutação cononcluída!');
      setLoading(false);
    }
  })


  //-----------------------------------------------
  const handleUpdatePortal = (e: any) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const LogoPortal: any = arquivo;
    const EmpresaPortal: any = formData.get('EmpresaPortal');
    const NomePortal: any = formData.get('NomePortal');
    const LinkPortal: any = formData.get('LinkPortal');
    const LoginPortal: any = formData.get('LoginPortal');
    const SenhaPortal: any = formData.get('SenhaPortal');
    const DataCadastroPortal: any = formData.get('DataCadastroPortal');
    const DataVencimentoPortal: any = formData.get('DataVencimentoPortal');
    const ValorPortal: any = formData.get('ValorPortal');


    const data: subTypeUpdatePortal = {
      LogoPortal: arquivo,
      EmpresaPortal,
      NomePortal,
      LinkPortal,
      LoginPortal,
      SenhaPortal,
      DataCadastroPortal,
      DataVencimentoPortal,
      ValorPortal,
    }

    const arrData = Object.entries(data);
    const filteredKeys = arrData.filter(([key, value]) => { return value !== "" });
    const filteredData: subTypeUpdatePortal = Object.fromEntries(filteredKeys)

    updatePortalMutation.mutate({ portalid: portalSelecionado._id || portalSelecionado._id as string, data: filteredData })

    return;
  }

  const handleFilePreview = (e: any) => {
    setArquivo(URL.createObjectURL(e.target.files[0]));
  }

  const fecharLogoPortal = (e: any) => {
    e.preventDefault();
    setEditarLogoPortal(false)
  }

  const close = (e: any) => {
    e.preventDefault();
    setConfirmarExcluir(false);

  }

  const findLogoPortal = (portal: any) => {
   try {
    switch (true) {
      case portal && portal.LogoPortal.length <= 28:
        return `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/logoPortais/${portal.LogoPortal}`
        break;
      case portal && portal.LogoPortal.length > 20:
        return portal.LogoPortal;
        break;
    }
   } catch (error) {
      console.error(error);
      return;
   }
  }


  if (editarLogoPortal) {
    return (


      <EditarLogoPortais fechar={fecharLogoPortal} portal={portalSelecionado} />

    )
  }

  return (
    <div className='flex flex-col gap-5'>

      {
        loading && < LoadingPage />
      }

      {
        confirmarExcluir && <PortaisExcluir close={close} portalid={portalSelecionado._id} />
      }

      {
        portalSelecionado &&

        <div id="crud-modal" tabIndex={1} aria-hidden={true} className={criarPortal || confirmarExcluir ? `${portalInativo}` : `${portalAtivo}`}>
          <div className="relative p-4 w-full max-h-full ">
            <div className="relative bg-white rounded-lg  dark:bg-gray-700">
              <div className="p-4 md:p-5">

                <div className='flex flex-row justify-between w-full'>

                  <div className='flex flex-col gap-4'>

                    {
                      editar ?
                        <div className='mb-5 flex flex-col'>
                          <div className='w-16 h-16 object-contain rounded-full mb-4'>
                            {
                              portalSelecionado.LogoPortal ?
                                <img src={portalSelecionado.LogoPortal} alt="portal" className='w-16 h-16 rounded-full ' />
                                :
                                <div className='flex justify-center items-center w-16 h-16 rounded-full object-contain origin-center border-2 border-dashed' onClick={() => setEditarLogoPortal(current => !current)}><BsPlus className='text-3xl ' /></div>
                            }
                          </div>



                          <label className='text-xl opacity-80 hover:opacity-100 cursor-pointer' onClick={() => setEditarLogoPortal(current => !current)}> Alterar logo do portal licitatório!</label>

                        </div>
                        :
                        <div className=' rounded-full mb-4'>
                          <img src={arquivo ? arquivo : findLogoPortal(portalSelecionado)} alt="portal" className='w-16 h-16 rounded-full ' />

                          { portalTab === PortalTab.PASTA &&
                            <label htmlFor="">{portalSelecionado.NomePortal}</label>}

                        </div>
                    }

                  </div>

                  <div className='pl-5 flex flex-row gap-5 w-fit '>
                    {
                      fechar && !editar && <div className='cursor-pointer opacity-80 hover:opacity-100 text-lg' onClick={fechar}><span><BsX className='text-3xl' /></span></div>
                    }

                    <span onClick={() => setEditar(current => !current)} className='cursor-pointer opacity-80 hover:opacity-100 text-lg' title='Editar portal'> {editar ? <BsX className='text-3xl' /> : <BsPencil />} </span>
                    <span onClick={() => setConfirmarExcluir(current => !current)} className='cursor-pointer opacity-80 hover:opacity-100 text-lg' title='Apagar portal'> <BsTrash className='text-2xl' /> </span>
                    
                    {
                      portalTab === PortalTab.RESUMO &&
                      <span onClick={() => setPortalTab(PortalTab.PASTA)} className='cursor-pointer opacity-80 hover:opacity-100 text-lg h-fit w-fit' title='Pastas do portal'> 
                        <RiInboxArchiveLine className="text-2xl" />
                      </span>
                    }

                    {
                      portalTab === PortalTab.PASTA &&
                      <span onClick={() => setPortalTab(PortalTab.RESUMO)} className='cursor-pointer opacity-80 hover:opacity-100 text-lg h-fit w-fit' title='Pastas do portal'> 
                        <RiInboxUnarchiveLine className="text-2xl" />
                      </span>
                    }

                  </div>

                </div>


                {
                  portalTab === PortalTab.PASTA &&
                    <PortaisPastas portal ={portalSelecionado}/>           
                }


                {
                  portalTab === PortalTab.RESUMO &&
                  <form onSubmit={handleUpdatePortal} className="grid gap-4 mb-4 grid-cols-2 fadeIn">

                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="NomePortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome do portal</label>
                    <input id="NomePortal" type ='text' name ='NomePortal' className={inputField} placeholder ={portalSelecionado.NomePortal} readOnly={editar ? false : true} value ={editar ? NomePortal : portalSelecionado.NomePortal } onChange ={(e)=> setNomePortal(e.target.value)}/>
                  </div>


                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="LinkPortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Link do portal</label>

                    {
                      editar ?
                      <input id="LinkPortal" name='LinkPortal' className={inputField} placeholder ={portalSelecionado.LinkPortal.replace('www.', 'h')}  onChange = {(e)=> setLinkPortal(e.target.value)}/>
                      :
                      <a href={portalSelecionado.LinkPortal.replace('www.', 'https://www.')} target='_blank' className='w-full p-2 bg-gray-100 shadow-lg rounded-md dark:bg-gray-600'>
                        {portalSelecionado.LinkPortal.length >60 ?`${portalSelecionado.LinkPortal.slice(0, 60)}...` :`${portalSelecionado.LinkPortal}`}
                      </a>
                    }


                  </div>



                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="price" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Login do portal</label>
                    <input type = 'text' name='LoginPortal' placeholder= {portalSelecionado.LoginPortal} readOnly ={editar ? false : true} value = {editar ? loginPortal:portalSelecionado.LoginPortal } className={inputField} onChange = {(e)=> setLoginPortal(e.target.value)}/>

                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="SenhaPortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Senha do portal</label>
                    <input id="SenhaPortal" type={showSenhaPortal ? "text":"password" } className={inputField} name='SenhaPortal' placeholder='*****' readOnly={editar ? false : true} value={editar ? senhaPortal :  portalSelecionado.SenhaPortal} onChange ={(e)=> setSenhaPortal(e.target.value)} />
                    <p className='pt-2 w-4 m-0 pb-0 text-lg cursor-pointer' onClick={()=> setShowSenhaPortal(current => !current)}>  {showSenhaPortal ? <BsEye/> : <BsEyeSlash/>} </p>
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="ValorPortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Valor do portal</label>
                    <input id="VlaorPortal" name='ValorPortal' className={inputField} readOnly ={editar ? false : true} value ={editar? valorPortal: portalSelecionado.ValorPortal} onChange ={(e)=> setValorPortal(e.target.value)} placeholder={portalSelecionado.ValorPortal}/>
                  </div>


                  <div className="col-span-2 sm:col-span-1">
                      <label htmlFor="Categoria" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Categoria   </label>
                    
                      <select name='Categoria' className={`${inputField}`} disabled={editar ? false : true}>
                          <option selected ={!portalSelecionado.Categoria ? true: false} className={`${inputField}`} value="">Nenhuma</option>
                          <option selected ={portalSelecionado.Categoria === 'Portal de compras'? true: false} className={`${inputField}`} value="Portal de compras">Portal De Compras</option>
                          <option selected ={portalSelecionado.Categoria === 'Rede Social'? true: false} className={`${inputField}`} value="Rede Social">Rede Social</option>
                          <option selected ={portalSelecionado.Categoria === 'Email'? true: false} className={`${inputField}`} value="Email">Email</option>
                          <option selected ={portalSelecionado.Categoria === 'Banco'? true: false} className={`${inputField}`} value="Banco">Banco</option>                        
                      </select>
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="DataCadastroPortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Abertura do portal</label>

                    
                    <input type ={editar ? 'datetime-local':'text'} name='DataCadastroPortal' id="DataCadastroPortal" className={inputField} readOnly ={editar ? false : true } value ={editar ? dataCadastroPortal : formatarData(portalSelecionado.DataCadastroPortal)} placeholder ={ formatarData(portalSelecionado.DataCadastroPortal)} onChange={(e)=> setDataCadastroPortal(e.target.value)}/>

                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="price" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Vencimento do portal</label>
                    <input type = {editar ? 'datetime-local':'text'} id="DataVencimentoPortal" name ='DataVencimentoPortal' className={inputField} readOnly ={editar ? false:true} value ={editar ? dataVencimentoPortal : formatarData(portalSelecionado.DataVencimentoPortal)} onChange={(e)=> setDataVencimentoPortal(e.target.value)}/>
                  </div>


                  {
                    editar &&
                    <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 max-w-48">
                    Editar portal
                    </button>
                  }

                </form>

                }

              </div>
            </div>
          </div>
        </div>}

    </div>
  )
}

export default PortalObj