import React from 'react'
import DisplayChat from './DisplayChat'

type Props = {}

const DocumentoChat = ({processo}: any) => {
  return (
    <div className='max-w-md w-full h-screen py-6 bg-gray-200 dark:bg-gray-700 p-4 relative'>
        <div className=' w-full h-full min-h-screen bg-gray-200 dark:bg-gray-600 rounded-xl'>
            <DisplayChat processo ={processo} noArquivo ={true}/>
        </div>
    </div>
  )
}

export default DocumentoChat