import React, { useEffect, useState } from 'react'
import { BsDownload, BsEye, BsPencil, BsTrash } from 'react-icons/bs';
import { SlShareAlt } from 'react-icons/sl';
import { formatarData } from '../../Utils/formatarData';

import { useQuery } from '@tanstack/react-query';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';
import ArquivoPncpModal from '../ArquivosComponents.tsx/ArquivoModalPncp';


const CaptacaoArquivosProcesso= ({item, url, processo}: any) => {


    const [arquivos, setArquivos] = useState<any>(['']);

    const {getCaptacaoArquivos} = useReactQueryCaptacao()
    const {data: captacaoArquivosData, isLoading} = useQuery({
        queryFn: ()=> getCaptacaoArquivos(url as string),
        queryKey: ['rqCaptcaoArquiivos']
    })


    const [abrirArquivo, setAbrirArquivo] = useState<boolean>(false);
    const [arquivoSelecionado, setArquivoSelecionado] = useState<any>('');


    const fechar = ()=>{
        setAbrirArquivo(false);
        setArquivoSelecionado('');
    }
    
    const abrirArquivoPncp = (arquivo: any)=>{
        setArquivoSelecionado(arquivo);
        setAbrirArquivo(true);
    }


    
    
    

  return (
        <>

        {
            abrirArquivo &&
            <ArquivoPncpModal arquivo ={arquivoSelecionado} processo={processo} fechar={fechar}/>
        }
           

            {
               captacaoArquivosData && captacaoArquivosData.map((arquivo: any)=>(

                

                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            
                <td className=" ">
                    {arquivo.titulo}
                </td>
                <td className="  inline-flex text-black dark:text-gray-300  gap-4">
                    <label onClick={()=> abrirArquivoPncp(arquivo)}>
                        <BsEye  className='cursor-pointer opacity-80 hover:opacity-100'/>
                    </label>                   
                    <a href={arquivo.url} target='_blank'>
                        <BsDownload  className='cursor-pointer opacity-80 hover:opacity-100'/>
                    </a>                   
                </td>
                <td className=" ">
                    {arquivo.tipoDocumentoDescricao}
                </td>
                <td className=" ">
                    {formatarData(arquivo.dataPublicacaoPncp)}
                </td>
                
                <td className=" ">
                    ---
                </td>
                <td className=" ">
                    ---
                </td>
                <td className=" ">
                    ---
                </td>
               
               
            </tr>
                ))
            }
            
        </>
  )
}

export default CaptacaoArquivosProcesso